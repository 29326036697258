import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import axios from 'axios'

import { useStyles } from './account.dialogs.style'
import { DialogLoading } from '../../../components/loading/loading.component'
import { IValues } from '../../../utils/types'
import { formatter } from '../../../utils/format'
import { Moment } from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { AuthContext } from '../../../providers/auth/auth.provider'

export const RedeemDialog = ({
  dialogOpen,
  closeDialog,
  cancelDialog,
  handleDialogSubmit,
  dialogSubmit,
  dialogErrors,
  date,
  handleDate,
}: {
  dialogOpen: boolean
  closeDialog: (type: string) => void
  cancelDialog: (type: string) => void
  handleDialogText: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDialogSubmit: (type: string) => void
  dialogSubmit: boolean
  handleDialogBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  dialogErrors: IValues
  date: Moment | null
  handleDate: (dateObj: Moment | null) => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.redeem }}
        open={dialogOpen}
        onClose={() => closeDialog('redeem')}
        aria-labelledby='transaction-title'
      >
        <DialogTitle style={{ textAlign: 'center' }} id='transaction-title'>
          Renew
        </DialogTitle>
        <DialogContent dividers>
          {dialogSubmit ? (
            'Sending Request...'
          ) : (
            <>
              <Grid container direction='row'>
                <Grid item xs={3} />
                <Grid item xs container direction='column'>
                  <Typography variant='body2'>Date</Typography>
                  <DatePicker
                    clearable
                    format='YYYY-MM-DD'
                    value={date}
                    onChange={(dateObj) => handleDate(dateObj)}
                    inputVariant='outlined'
                    margin='dense'
                    error={!!dialogErrors.date}
                    helperText={dialogErrors.date}
                  />
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </>
          )}
        </DialogContent>
        {dialogSubmit ? (
          ''
        ) : (
          <>
            <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button
                className={classes.dialogCancelButton}
                onClick={() => cancelDialog('redeem')}
              >
                Cancel
              </Button>
              <Button
                className={classes.dialogSubmitButton}
                onClick={() => handleDialogSubmit('redeem')}
              >
                Submit
              </Button>
            </DialogActions>{' '}
          </>
        )}
      </Dialog>
    </>
  )
}

export const PartialRedeemDialog = ({
  dialogOpen,
  closeDialog,
  cancelDialog,
  handleDialogText,
  handleDialogSubmit,
  dialogSubmit,
  handleDialogBlur,
  dialogErrors,
  date,
  handleDate,
}: {
  dialogOpen: boolean
  closeDialog: (type: string) => void
  cancelDialog: (type: string) => void
  handleDialogText: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDialogSubmit: (type: string) => void
  dialogSubmit: boolean
  handleDialogBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  dialogErrors: IValues
  date: Moment | null
  handleDate: (dateObj: Moment | null) => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.partial }}
        fullWidth
        open={dialogOpen}
        onClose={() => closeDialog('partial')}
        aria-labelledby='partial-dialog-title'
      >
        <DialogTitle style={{ textAlign: 'center' }} id='partial-dialog-title'>
          Partial Redeem
        </DialogTitle>
        <DialogContent dividers>
          {dialogSubmit ? (
            <DialogLoading />
          ) : (
            <>
              <Grid
                container
                direction='row'
                justify='space-around'
                spacing={1}
              >
                <Grid item xs={5} container direction='column'>
                  <Typography variant='body2'>Date</Typography>
                  <DatePicker
                    clearable
                    format='YYYY-MM-DD'
                    value={date}
                    onChange={(dateObj) => handleDate(dateObj)}
                    inputVariant='outlined'
                    margin='dense'
                    error={!!dialogErrors.date}
                    helperText={dialogErrors.date}
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5} container direction='column'>
                  <Typography variant='body2'>Redeem Amount</Typography>
                  <TextField
                    error={!!dialogErrors.redeemAmount}
                    helperText={dialogErrors.redeemAmount}
                    margin='dense'
                    variant='outlined'
                    id='redeemAmount'
                    onChange={handleDialogText}
                    onBlur={handleDialogBlur}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            className={classes.dialogCancelButton}
            onClick={() => cancelDialog('partialRedeem')}
          >
            Cancel
          </Button>
          <Button
            disabled={dialogSubmit}
            className={classes.dialogSubmitButton}
            onClick={() => handleDialogSubmit('partialRedeem')}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const TransferLPDialog = ({
  dialogOpen,
  closeDialog,
  cancelDialog,
  handleDialogText,
  handleDialogSubmit,
  banks,
  bank,
  handleDialogBank,
  investors,
  investorId,
  handleDialogInvestor,
  dialogSubmit,
  dialogErrors,
  date,
  handleDate,
  loadedInvestor,
}: {
  dialogOpen: boolean
  closeDialog: (type: string) => void
  cancelDialog: (type: string) => void
  handleDialogText: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDialogSubmit: (type: string) => void
  banks: any[]
  bank: any
  handleDialogBank: (event: React.ChangeEvent<{ value: unknown }>) => void
  investors: any[]
  investorId: string
  handleDialogInvestor: (event: React.ChangeEvent<{ value: unknown }>) => void
  dialogSubmit: boolean
  handleDialogBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  dialogErrors: IValues
  date: Moment | null
  handleDate: (dateObj: Moment | null) => void
  loadedInvestor: any
}) => {
  const classes = useStyles()
  const [selectedUserBank, $selectedUserBank] = React.useState<any>([])
  const [bankLoading, $bankLoading] = React.useState({
    loading: false,
    count: 0,
  })
  const { token } = React.useContext(AuthContext)

  React.useEffect(() => {
    const loadBanks = async () => {
      $bankLoading({ ...bankLoading, loading: true })
      const res = await axios.get(`${PROD_OPS}/investor/info/${investorId}`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        $selectedUserBank(res.data.investor.banks)
        $bankLoading({ loading: false, count: bankLoading.count + 1 })
      }
    }
    loadBanks()
  }, [investorId])

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullWidth
        open={dialogOpen}
        onClose={() => closeDialog('transfer')}
        aria-labelledby='transfer-dialog-title'
      >
        <DialogTitle style={{ textAlign: 'center' }} id='transfer-dialog-title'>
          Transfer LP Unit
        </DialogTitle>
        <DialogContent dividers>
          {dialogSubmit ? (
            <DialogLoading />
          ) : (
            <>
              <Grid
                container
                direction='row'
                justify='space-around'
                spacing={1}
              >
                <Grid item xs={5} container direction='column'>
                  <Typography variant='body2'>Date</Typography>
                  <DatePicker
                    clearable
                    format='YYYY-MM-DD'
                    value={date}
                    onChange={(dateObj) => handleDate(dateObj)}
                    inputVariant='outlined'
                    margin='dense'
                    error={!!dialogErrors.date}
                    helperText={dialogErrors.date}
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5} container direction='column'>
                  <Typography variant='body2'>New Certificate No</Typography>
                  <TextField
                    margin='dense'
                    variant='outlined'
                    id='newCertificateNo'
                    onChange={handleDialogText}
                  />
                </Grid>
                <Grid item xs={5} container direction='column'>
                  <Typography variant='body2'>Transferee</Typography>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ minWidth: 150 }}
                  >
                    <Select
                      name='transfereeId'
                      required
                      value={investorId}
                      onChange={handleDialogInvestor}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {investors.map((b: any, idx: number) =>
                        loadedInvestor.id === b.id ? (
                          ''
                        ) : (
                          <MenuItem key={idx} value={b.id}>
                            {b.investorType === 'individual'
                              ? `${b.firstName} ${b.sin}`
                              : b.companyName}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} />
                <Grid item xs={12} container direction='column'>
                  <Typography variant='body2'>Transferee Bank</Typography>
                  {bankLoading.count === 0 ? (
                    <Typography
                      variant='body2'
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: 16,
                      }}
                    >
                      Select Transferee
                    </Typography>
                  ) : bankLoading.loading ? (
                    <Typography
                      variant='body2'
                      style={{
                        color: 'navy',
                        textAlign: 'center',
                        marginTop: 16,
                      }}
                    >
                      Loading Bank List...
                    </Typography>
                  ) : (
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      style={{ minWidth: 150 }}
                    >
                      <Select
                        name='bank'
                        required
                        value={bank}
                        onChange={handleDialogBank}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        {selectedUserBank.map((b: any, idx: number) => (
                          <MenuItem
                            key={idx}
                            value={`${b.bankName}#${b.accountNo}`}
                          >
                            {b.bankName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            className={classes.dialogCancelButton}
            onClick={() => cancelDialog('transfer')}
          >
            Cancel
          </Button>
          <Button
            disabled={dialogSubmit}
            className={classes.dialogSubmitButton}
            onClick={() => handleDialogSubmit('transfer')}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const RedeemSuccessDialog = ({
  dialogOpen,
  cancelDialog,
  redeemedData,
}: {
  dialogOpen: boolean
  cancelDialog: (type: string) => void
  redeemedData: any
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={dialogOpen}
        onClose={() => cancelDialog('redeemSuccess')}
        aria-labelledby='redeemSuccess-title'
      >
        <DialogTitle style={{ textAlign: 'center' }} id='redeemSuccess-title'>
          Redeem Successful
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='row' justify='space-around' spacing={1}>
            <Grid item xs={5} container direction='column'>
              <Typography variant='body1'>Date</Typography>
              <Typography variant='body2'>{redeemedData.date}</Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5} container direction='column'>
              <Typography variant='body1'>Principal</Typography>
              <Typography variant='body2'>{redeemedData.principle}</Typography>
            </Grid>
            <Grid item xs={5} container direction='column'>
              <Typography variant='body1'>Interest</Typography>
              <Typography variant='body2'>
                {formatter.format(redeemedData.interest)}
              </Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5} container direction='column'>
              <Typography variant='body1'>Total</Typography>
              <Typography variant='body2'>
                {formatter.format(redeemedData.total)}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            className={classes.dialogSubmitButton}
            onClick={() => cancelDialog('redeemSuccess')}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
