import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  NAVY,
  WHITE,
  LIGHT_GRAY,
  GRAY_600,
  SKY_BLUE,
  BLACK,
  GRAY_200
} from '../../../styles/color'
import Toolbar from '../../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    root: {
      width: '100%',
      height: 'calc(100% - 72px)',
      padding: theme.spacing(4)
    },
    box: {
      backgroundColor: GRAY_200,
      padding: theme.spacing(3)
    },
    fields: {
      height: '60%',
      backgroundColor: LIGHT_GRAY,
      padding: theme.spacing(2)
    },
    fieldValue: {
      color: NAVY,
      fontSize: 'medium',
      marginLeft: 8
    },
    editIcon: {
      display: 'flex',
      alignItems: 'center',
      color: NAVY,
      cursor: 'pointer'
    },
    spaceAround: {
      justifyContent: 'space-around'
    },
    indicator: {
      backgroundColor: NAVY
    },
    bankButton: {
      boxShadow: 'unset'
    },
    bankIcon: {
      marginRight: 8,
      backgroundColor: NAVY,
      color: WHITE,
      borderRadius: '50%'
    },
    bankDelete: {
      color: GRAY_600
    },
    bankItem: {
      width: '70%',
      boxShadow: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: SKY_BLUE
    },

    dropContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    dropZone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundolor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      width: '25%',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    bankGrid: {
      padding: theme.spacing(0, 4, 4, 0)
    },
    boxShadow: {
      boxShadow: 'unset'
    },
    background: {
      backgroundColor: GRAY_600
    },
    table: {
      minWidth: '100%'
    },
    textWhite: {
      color: WHITE
    },
    textNavy: {
      color: NAVY
    },
    dialog: {
      height: '60%'
    },
    dialogCancelButton: {
      height: theme.spacing(6),
      backgroundColor: LIGHT_GRAY,
      width: '25%',
      borderRadius: 14,
      color: NAVY,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    dialogSubmitButton: {
      height: theme.spacing(6),
      backgroundColor: NAVY,
      width: '25%',
      borderRadius: 14,
      color: WHITE,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    button: {
      boxShadow: 'unset',
      borderRadius: 0,
      backgroundColor: SKY_BLUE,
      color: BLACK,
      width: 160,

      '&:hover': {
        backgroundColor: SKY_BLUE
      }
    },
    stepper: {
      width: '100%',
      justifyContent: 'space-between'
    },
    addButton: {
      boxShadow: 'unset',
      borderRadius: 0,
      backgroundColor: NAVY,
      color: WHITE,

      '&:hover': {
        backgroundColor: NAVY
      }
    },
    buttonField: {
      position: 'absolute',
      height: theme.spacing(8),
      width: 'calc(50% - 64px)',
      marginLeft: 'calc(50% - 64px)',
      bottom: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  })
)
