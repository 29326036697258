export const MORTGAGES = 'mortgages'
export const GUARANTORS = 'guarantors'
export const COLLATERALS = 'collaterals'
export const TERMS = 'terms'
export const NEWACCOUNT = 'newAccount'
export const TRANSACTION = 'transaction'
export const PAYOUT = 'payout'

export const DEFAULT_NEW_ACCOUNT_FIELDS = {
  fileNo: null,
  mortgageType: null,
  startDate: null,
  mortgageAmount: 0,
  interestType: null,
  interestAdjustment: 0,
  ltv: 0,
  lenderFee: 0,
  adminFee: 0,
  brokerFee: 0,
  coBrokerName: null,
  coBrokerFee: 0,
  brokerNotes: null,
  title: false,
  legalDocs: false,
  lenderPackage: false,
}

export const DEFAULT_MORTGAGES_FIELD = {
  mortgageType: null,
  firstName: null,
  lastName: null,
  companyName: null,
  contactName: null,
  email: null,
}
export const DEFAULT_MORTGAGES_FIELDS = [
  {
    mortgageType: null,
    firstName: null,
    lastName: null,
    companyName: null,
    contactName: null,
    email: null,
  },
]

export const DEFAULT_GUARANTORS_FIELD = {
  name: null,
  address: null,
  city: null,
  province: null,
  postCode: null,
}

export const DEFAULT_GUARANTORS_FIELDS = [
  {
    name: null,
    address: null,
    city: null,
    province: null,
    postCode: null,
  },
]

export const DEFAULT_COLLATERALS_FIELD = {
  type: null,
  city: null,
  province: null,
  age: 0,
  value: 0,
  valueType: null,
  appraisalCompany: null,
  notes: null,
}

export const DEFAULT_COLLATERALS_FIELDS = [
  {
    type: null,
    city: null,
    province: null,
    age: 0,
    value: 0,
    valueType: null,
    appraisalCompany: null,
    notes: null,
  },
]

export const DEFAULT_TERMS_FIELD = {
  sequenceNo: 0,
  month: 0,
  minInterest: 0,
  floatInterest: 0,
}

export const DEFAULT_MORTGAGE_FORM = {
  operation: 'create',
  newAccount: {
    fileNo: null,
    mortgageType: null,
    startDate: null,
    mortgageAmount: 0,
    interestType: null,
    interestAdjustment: 0,
    interestRate1H: 0,
    variableRate1H: 0,
    interestRate2H: 0,
    variableRate2H: 0,
    ltv: 0,
    lenderFee: 0,
    adminFee: 0,
    brokerFee: 0,
    coBrokerName: null,
    coBrokerFee: 0,
    brokerNotes: null,
    title: false,
    legalDocs: false,
    lenderPackage: false,
  },
  mortgages: [
    {
      mortgageType: null,
      firstName: null,
      lastName: null,
      companyName: null,
      contactName: null,
      email: null,
    },
  ],
  guarantors: [
    {
      name: null,
      address: null,
      city: null,
      province: null,
      postCode: null,
    },
  ],
  collaterals: [
    {
      type: null,
      city: null,
      province: null,
      age: 0,
      value: 0,
      valueType: null,
      appraisalCompany: null,
      notes: null,
    },
  ],
}

export const DEFAULT_MORTGAGE_INFO = {
  id: null,
  fileNo: null,
  mortgageName: null,
  mortgageType: null,
  startDate: null,
  matureDate: null,
  endDate: null,
  status: null,
  mortgageAmount: 0,
  interestType: null,
  interestAdjustment: 0,
  interestRate1H: 0,
  variableRate1H: 0,
  interestRate2H: 0,
  variableRate2H: 0,
  ltv: 0,
  lenderFee: 0,
  adminFee: 0,
  brokerFee: 0,
  brokerFeePercentage: 0,
  coBrokerName: null,
  coBrokerFee: 0,
  brokerNotes: null,
  managementFee: -0,
  title: false,
  legalDocs: false,
  lenderPackage: false,
  mortgages: [],
  guarantors: [],
  collaterals: [],
  mortgageTransactions: [],
}

export const DEFAULT_TRANSACTION = {
  id: '',
  type: '',
  transDate: '',
  amount: 0,
  nfs: 0,
  chequeNo: '',
  notes: '',
}

export const DEFAULT_TRANSACTION_EDIT = {
  dialog: false,
  loading: false,
  selected: DEFAULT_TRANSACTION,
  values: {},
  finished: false,
  text: 'Successful',
}

export const DEFAULT_PRIME_RATE = {
  dialog: false,
  loading: false,
  finished: false,
  text: 'Successful',
  value: {
    date: null,
    rate: null,
    bank: null,
  },
  errors: {},
}

export const DEFAULT_PAYOUT = {
  dialog: false,
  loading: false,
  finished: false,
  text: 'Successful',
  value: {
    date: null,
  },
  errors: {},
}
