import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldValue: {
      color: NAVY,
      fontSize: 'medium',
      marginLeft: 8
    }
  })
)
