import React, { useState, useEffect } from 'react'

import { useStyles } from './index.style'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import InformationView from './information.view'
import AccountView from './account.view'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { DEFAULT_INVESTOR_INFO } from '../helper/investor.helper'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { IValues } from '../../../utils/types'
import { FiberManualRecord } from '@material-ui/icons'
import { AuthContext } from '../../../providers/auth/auth.provider'

const InvestorView = () => {
  const classes = useStyles()
  const { userId } = useParams()
  const [activeStep, $activeStep] = useState(0)
  const [loadedInvestor, $loadedInvestor] = useState<any>(DEFAULT_INVESTOR_INFO)
  const [loadedMapping, $loadedMapping] = useState<any>([])
  const [investor, $investor] = useState<IValues>({})
  const [account, $account] = useState<IValues>({})
  const [loading, $loading] = useState<boolean>(false)
  const [edit, $edit] = useState<boolean>(false)
  const [form, $form] = useState<string>('investor')
  const [banks, $banks] = useState<any>([])
  const [investors, $investors] = useState<any>([])
  const [dialogSubmit, $dialogSubmit] = useState<boolean>(false)
  const { token } = React.useContext(AuthContext)

  const steps = ['Investor Information', 'Account Information']

  const setForm = (name: string) => {
    $form(name)
  }

  const setEdit = () => {
    $edit(!edit)
  }

  const handleNext = () => {
    $activeStep(activeStep + 1)
  }

  const handleBack = () => {
    $activeStep(activeStep - 1)
  }

  const setValues = (value: IValues, formType: string) => {
    if (formType === 'investor') {
      $investor({ ...investor, ...value })
    } else {
      $account({ ...account, ...value })
    }
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <InformationView
            loading={loading}
            loadedInvestor={loadedInvestor}
            investor={investor}
            handleNext={handleNext}
            setValues={setValues}
            setForm={setForm}
            edit={edit}
            setEdit={setEdit}
          />
        )
      case 1:
        return (
          <AccountView
            loading={loading}
            loadedInvestor={loadedInvestor}
            loadedMapping={loadedMapping}
            account={account}
            edit={edit}
            setEdit={setEdit}
            setValues={setValues}
            setForm={setForm}
            handleBack={handleBack}
            banks={banks}
            investors={investors}
            dialogSubmit={dialogSubmit}
            setDialogSubmit={$dialogSubmit}
          />
        )
      default:
        throw new Error('Unknown Step')
    }
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    form: string
  ): Promise<void> => {
    e.preventDefault()
    $loading(true)
    if (form === 'investor') {
      const body = {
        operation: 'update',
        investorId: loadedInvestor.id,
        updatedInvestor: {
          info: investor.info || {},
          banks: investor.banks || [],
        },
      }

      axios
        .put(`${PROD_OPS}/investor/info`, body, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        .then((res) => {
          if (res.status === 200) {
            $loading(false)
            $edit(!edit)
            return true
          } else {
          }
        })
    } else {
      const body = {
        operation: 'update',
        ...account,
      }
      axios.put(`${PROD_OPS}/investment/info`, body).then((res) => {
        if (res.status === 200) {
          $loading(false)
          $edit(!edit)
          return true
        } else {
        }
      })
    }
  }

  const handleStepper = () => {
    if (activeStep === 0) {
      handleNext()
    } else {
      handleBack()
    }
  }

  React.useEffect(() => {
    const loadInvestors = async () => {
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      $investors(res.data.investors)
    }
    loadInvestors()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      $loading(true)
      try {
        const res = await axios.get(`${PROD_OPS}/investor/info/${userId}`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        if (res.status === 200) {
          $loadedInvestor(res.data.investor)
          $banks(res.data.investor.banks)
          var mappings = res.data.investor.investorMappings
          const order = ['Active', 'Closed']
          mappings = mappings.sort(
            (a: any, b: any) =>
              order.indexOf(a.investmentStatus) -
              order.indexOf(b.investmentStatus)
          )
          $loadedMapping(mappings)

          $loading(false)
        }
      } catch (e) {}
    }
    loadData()
  }, [edit, dialogSubmit, userId])

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Stepper
          activeStep={activeStep}
          connector={<StepConnector classes={{ line: classes.transparent }} />}
          classes={{ root: classes.stepper }}
        >
          {steps.map((label, index: number) => (
            <Step key={label}>
              <StepLabel
                icon={
                  index === activeStep ? (
                    <FiberManualRecord fontSize='small' />
                  ) : (
                    ''
                  )
                }
                onClick={handleStepper}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <>
        <form
          style={{
            width: '100%',
            minHeight: 'calc(100% - 72px)',
            position: 'relative',
            padding: 32,
          }}
          onSubmit={(e) => handleSubmit(e, form)}
        >
          {activeStep === steps.length ? (
            <>
              <div>redirect</div>
            </>
          ) : (
            <>{getStepContent(activeStep)}</>
          )}
        </form>
      </>
    </div>
  )
}

export default InvestorView
