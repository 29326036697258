import React, { useEffect, useState } from 'react'

import {
  Grid,
  Divider,
  Box,
  Paper,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@material-ui/core'
import { TrendingUp } from '@material-ui/icons'

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts'

import { useStyles } from './home.chart.style'
import { IDashboard } from '../../pages/homepage/helper/homepage.helper'
import moment from 'moment'
import { LIGHT_GRAY } from '../../styles/color'
import { formatter } from '../../utils/format'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

interface IChart {
  month: string
  paid: number
  received: number
  differences: number
}

const HomeChart = ({ dashboard }: { dashboard: IDashboard }) => {
  const classes = useStyles()
  const today = moment().format('YYYY.MMM')
  const [data, $data] = useState<IChart[]>([])

  useEffect(() => {
    let chart: IChart[] = []
    const months = dashboard.months
    const paidInterests = dashboard.paidInterests
    const receivedInterests = dashboard.receivedInterests
    const differences = dashboard.differences
    if (months.length !== 0) {
      months.map((m: string, idx: number) => {
        chart.push({
          month: m,
          paid: paidInterests[idx],
          received: receivedInterests[idx],
          differences: differences[idx]
        })
      })
      $data(chart)
    }
  }, [dashboard])

  const [value, setValue] = React.useState(0)

  const renderLineChart = (
    <LineChart
      width={920}
      height={420}
      data={data}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
    >
      <Line type='monotone' dataKey='paid' stroke='#21C800' />
      <Line type='monotone' dataKey='received' stroke='#8884d7' />
      <Line type='monotone' dataKey='differences' stroke='#C80043' />
      <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
      <Tooltip />
      <Legend />
      <XAxis dataKey='month' />
      <YAxis />
    </LineChart>
  )

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={classes.bottom}>
      <div className={classes.stats}>
        <Grid container direction='column'>
          <Grid className={classes.paper} item xs container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Investors Return</Typography>
              <Divider />
              <Typography className={classes.iconTypo} variant='h6'>
                <TrendingUp className={classes.bigIcon} />{' '}
                <span className={classes.yellow}>
                  {dashboard.investorReturn}%
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.paper} item xs container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Mortgage Rate</Typography>
              <Divider />
              <Typography className={classes.iconTypo} variant='h6'>
                <TrendingUp className={classes.bigIcon} />{' '}
                <span className={classes.yellow}>
                  {dashboard.mortgageRate}%
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.paper} item xs container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Management Fee</Typography>
              <Divider />
              <Typography
                className={classes.iconTypo}
                style={{ justifyContent: 'space-between' }}
                variant='h6'
              >
                <Typography variant='body2'>{today}</Typography>
                <span className={classes.yellow}>
                  {formatter.format(dashboard.managementFee)}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.paper2}
            style={{ minHeight: '50%' }}
            item
            xs
            container
            justify='center'
            direction='column'
          >
            <Typography variant='body2'>Paid Investors Interest</Typography>
            <Typography
              className={classes.yellow}
              style={{ textAlign: 'right' }}
              variant='h6'
            >
              {formatter.format(dashboard.paidInterest)}
            </Typography>
            <Typography variant='body2'>Unpaid Investors Interest</Typography>
            <Typography
              className={classes.yellow}
              style={{ textAlign: 'right' }}
              variant='h6'
            >
              {formatter.format(dashboard.unpaidInterest)}
            </Typography>
            <Typography variant='body2'>Mortgage Interest Recieved</Typography>
            <Typography
              className={classes.yellow}
              style={{ textAlign: 'right' }}
              variant='h6'
            >
              {formatter.format(dashboard.receivedInterest)}
            </Typography>
            <Typography variant='body2'>
              Mortgage Interest Unrecieved
            </Typography>
            <Typography
              className={classes.yellow}
              style={{ textAlign: 'right' }}
              variant='h6'
            >
              {formatter.format(dashboard.unreceivedInterest)}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.chart}>
        <Paper className={classes.paper} style={{ minHeight: '94%' }}>
          <AppBar
            style={{
              backgroundColor: 'inherit',
              boxShadow: 'unset',
              color: 'black'
            }}
            position='static'
          >
            <Tabs
              centered
              classes={{
                centered: classes.spaceAround,
                indicator: classes.indicator
              }}
              value={value}
              onChange={handleChange}
              aria-label='chart and table'
            >
              <Tab label='chart' {...a11yProps(0)} />
              <Tab label='table' {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {renderLineChart}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer>
              <Table className={classes.table} size='small'>
                <TableHead style={{ backgroundColor: LIGHT_GRAY }}>
                  <TableRow>
                    <TableCell></TableCell>
                    {dashboard.months.map((m: string, idx: number) => (
                      <TableCell key={idx}>{m}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Paid Investors Interest</TableCell>
                    {dashboard.paidInterests.map((p: number, idx: number) => (
                      <TableCell key={idx}>{formatter.format(p)}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Interest Received</TableCell>
                    {dashboard.receivedInterests.map(
                      (p: number, idx: number) => (
                        <TableCell key={idx}>{formatter.format(p)}</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Differences</TableCell>
                    {dashboard.differences.map((p: number, idx: number) => (
                      <TableCell key={idx}>{formatter.format(p)}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Paper>
      </div>
    </div>
  )
}

export default HomeChart
