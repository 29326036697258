import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { GRAY_500, RED, NAVY, WHITE } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gray: {
      color: GRAY_500
    },
    red: {
      color: RED
    },
    addButton: {
      boxShadow: 'unset',
      backgroundColor: 'transparent',
      color: NAVY,

      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    addIcon: {
      marginRight: 8,
      backgroundColor: NAVY,
      color: WHITE,
      borderRadius: '50%'
    },
    marginTop40: {
      marginTop: theme.spacing(5)
    }
  })
)
