import { IMortgageTable, IPrimeTable } from './mortgage.interface'

export const createData = ({
  fileNo,
  mortgageName,
  startDate,
  mortgageAmount,
  ltv,
  mortgageType,
  status,
  brokerFee,
  brokerFeePercentage,
  totalCollateralNumber,
  totalCollateralValue,
  coBrokerName,
  mortgageInterest,
  collaterals,
  id,
}: IMortgageTable) => {
  return {
    fileNo,
    mortgageName,
    startDate,
    mortgageAmount,
    ltv,
    mortgageType,
    status,
    brokerFee,
    brokerFeePercentage,
    totalCollateralNumber,
    totalCollateralValue,
    coBrokerName,
    mortgageInterest,
    collaterals,
    id,
  }
}

export const primeTable = ({ date, bank, rate }: IPrimeTable) => {
  return {
    date,
    bank,
    rate,
  }
}
