import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, WHITE, LIGHT_GRAY } from '../../styles/color'
import Toolbar from '../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%'
    },
    toolbarBg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      ...theme.mixins.toolbar
    },
    settingPanel: {
      height: '40%',
      width: '95%',
      margin: 'auto',
      padding: theme.spacing(1)
    },
    paper: {
      background: LIGHT_GRAY,
      height: '100%',
      boxShadow: 'unset',
      borderRadius: 'unset',
      padding: theme.spacing(3)
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      marginLeft: theme.spacing(3),
      height: theme.spacing(7),
      width: theme.spacing(22),
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      width: theme.spacing(22),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    classPaper: {
      backgroundColor: WHITE,
      boxShadow: 'unset',
      borderRadius: 'unset'
    },
    grid: {
      display: 'flex',
      alignItems: 'center'
    },
    textfield: {
      borderRadius: 'unset',
      marginLeft: 8,
      marginRight: 16,
      backgroundColor: WHITE
    },
    white: {
      backgroundColor: WHITE
    }
  })
)
