import * as React from 'react'
import axios from 'axios'
import { IValues } from '../../../utils/types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { PROD_AUTH, AUTH_KEY } from '../../../utils/constants'
import { AuthContext } from '../../../providers/auth/auth.provider'

export const NewUserDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const { token } = React.useContext(AuthContext)
  const [confirm, $confirm] = React.useState<string | null>(null)
  const [values, $values] = React.useState<IValues>({
    operation: 'create',
    name: null,
    role: 'admin-general',
    department: null,
    email: null,
    password: null,
  })
  const [errors, $errors] = React.useState<IValues>({
    name: null,
    role: null,
    department: null,
    email: null,
    password: null,
    repassword: null,
  })

  const handleSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    $values({ ...values, role: e.target.value })
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.id as string
    const value = e.target.value as string
    if (name === 'repassword') {
      $confirm(value)
    } else {
      $values({ ...values, [name]: value })
    }
  }

  const onBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.id as string
    const value = e.target.value as string

    if (name === 'repassword') {
      if (values.password !== confirm) {
        $errors({
          ...errors,
          password: 'password must match',
          repassword: 'password must match',
        })
      } else {
        $errors({ ...errors, password: null, repassword: null })
      }
    } else {
      if (value === '' || !value) {
        $errors({ ...errors, [name]: `${name} is required` })
      } else {
        $errors({ ...errors, [name]: null })
      }
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const submitForm = async () => {
      const res = await axios.put(`${PROD_AUTH}/user/info`, values, {
        headers: { 'x-api-key': AUTH_KEY, Authorization: token },
      })
      if (res.status === 201) {
        onClose()
      } else {
        $errors({ ...errors, name: 'Please Check the Form' })
      }
    }
    submitForm()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                onBlur={onBlur}
                onChange={handleTextField}
                label='Name'
                id='name'
                fullWidth
                variant='outlined'
                size='small'
                margin='dense'
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant='outlined'
                margin='dense'
                size='small'
              >
                <InputLabel id='select-role'>Role</InputLabel>
                <Select
                  label='Role'
                  labelId='select-role'
                  id='role'
                  onChange={handleSelect}
                  value={values.role}
                >
                  <MenuItem value={''}>None</MenuItem>
                  <MenuItem value={'admin-general'}>Admin-General</MenuItem>
                  <MenuItem value={'admin-view'}>Admin-View</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onBlur={onBlur}
                onChange={handleTextField}
                id='department'
                label='Department'
                variant='outlined'
                size='small'
                margin='dense'
                error={!!errors.department}
                helperText={errors.department}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onBlur={onBlur}
                onChange={handleTextField}
                id='email'
                label='Email'
                variant='outlined'
                size='small'
                margin='dense'
                type='email'
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onBlur={onBlur}
                onChange={handleTextField}
                id='password'
                type='password'
                label='Password'
                variant='outlined'
                size='small'
                margin='dense'
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={!values.password}
                onBlur={onBlur}
                onChange={handleTextField}
                id='repassword'
                type='password'
                label='Confirm Password'
                variant='outlined'
                size='small'
                margin='dense'
                error={!!errors.repassword}
                helperText={errors.repassword}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant='contained'
            style={{
              boxShadow: 'unset',
              borderRadius: 24,
              height: 40,
              width: 160,
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            style={{
              boxShadow: 'unset',
              borderRadius: 24,
              height: 40,
              width: 160,
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
