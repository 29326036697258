import React, { Fragment } from 'react'
import InputMask from 'react-input-mask'
import {
  Grid,
  Typography,
  TextField,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText
} from '@material-ui/core'

import { DeleteForeverOutlined, Add } from '@material-ui/icons'
import { COLLATERALS } from '../helper/mortgage.constants'
import { useStyles } from './collaterals.form.fields.style'
import { IValues } from '../../../utils/types'

const CollateralsFormFields = ({
  collaterals,
  handleTextField,
  handleSelectField,
  handleAddFields,
  handleRemoveFields,
  errors
}: {
  collaterals: IValues[]
  handleTextField: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectField: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void
  handleAddFields: (field: string) => void
  handleRemoveFields: (idx: number, field: string) => void
  errors: IValues
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12} className={classes.marginTop40}>
        <Typography variant='body1'>
          Collateral(s){' '}
          <span className={classes.gray}>
            (You can have multiple collaterals for the same account)
          </span>
        </Typography>
      </Grid>
      {collaterals.map((field, idx: number) => (
        <Fragment key={`${field}-${idx}`}>
          <Grid className={classes.margin24} item xs={12}>
            <Typography variant='body2'>Collateral</Typography>
          </Grid>
          <Grid item xs={12} container direction='row' spacing={2}>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>
                Type <span className={classes.red}>*</span>
              </Typography>
              <FormControl
                variant='outlined'
                margin='dense'
                error={!!errors[`type#${idx}`]}
              >
                <Select
                  name={`${COLLATERALS}#type#${idx}`}
                  value={
                    collaterals[idx].type === null ? '' : collaterals[idx].type
                  }
                  onChange={handleSelectField}
                >
                  <MenuItem value=''>{<em>None</em>}</MenuItem>
                  <MenuItem value='Land'>Land</MenuItem>
                  <MenuItem value='Condo'>Condo</MenuItem>
                  <MenuItem value='Townhouse'>Townhouse</MenuItem>
                  <MenuItem value='SFD'>SFD</MenuItem>
                  <MenuItem value='Commercial'>Commercial</MenuItem>
                  <MenuItem value='Others'>Others</MenuItem>
                </Select>
                <FormHelperText>{errors[`type#${idx}`]}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>City</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                value={
                  collaterals[idx].city === null ? '' : collaterals[idx].city
                }
                id={`${COLLATERALS}#city#${idx}`}
              />
            </Grid>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>Province</Typography>
              <InputMask
                mask='aa'
                maskChar=' '
                formatChars={{ a: '[A-Z]' }}
                onChange={handleTextField}
                value={
                  collaterals[idx].province === null
                    ? ''
                    : collaterals[idx].province
                }
              >
                {() => (
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    placeholder='BC, AB, ON'
                    value={
                      collaterals[idx].province === null
                        ? ''
                        : collaterals[idx].province
                    }
                    id={`${COLLATERALS}#province#${idx}`}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>Age</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                type='number'
                inputProps={{
                  min: 0
                }}
                onChange={handleTextField}
                value={collaterals[idx].age === 0 ? '' : collaterals[idx].age}
                id={`${COLLATERALS}#age#${idx}`}
              />
            </Grid>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>Value</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                type='number'
                inputProps={{
                  min: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  )
                }}
                value={
                  collaterals[idx].value === 0 ? '' : collaterals[idx].value
                }
                id={`${COLLATERALS}#value#${idx}`}
              />
            </Grid>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>Value Type</Typography>
              <FormControl variant='outlined' margin='dense'>
                <Select
                  name={`${COLLATERALS}#valueType#${idx}`}
                  value={
                    collaterals[idx].valueType === null
                      ? ''
                      : collaterals[idx].valueType
                  }
                  onChange={handleSelectField}
                >
                  <MenuItem value=''>{<em>None</em>}</MenuItem>
                  <MenuItem value='Assessment'>Assessment</MenuItem>
                  <MenuItem value='Appraisal'>Appraisal</MenuItem>
                  <MenuItem value='Purchase'>Purchase</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} container direction='column'>
              <Typography variant='body2'>Appraisal Company</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                value={
                  collaterals[idx].appraisalCompany === null
                    ? ''
                    : collaterals[idx].appraisalCompany
                }
                id={`${COLLATERALS}#appraisalCompany#${idx}`}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={6} container direction='column'>
              <Typography variant='body2'>Notes</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                value={
                  collaterals[idx].notes === null ? '' : collaterals[idx].notes
                }
                id={`${COLLATERALS}#notes#${idx}`}
              />
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={1} container direction='column'>
              <Grid item xs />
              <Grid item xs>
                <Fab
                  classes={{ root: classes.addButton }}
                  onClick={() => handleRemoveFields(idx, COLLATERALS)}
                  variant='extended'
                >
                  <DeleteForeverOutlined className={classes.red} />
                  <span style={{ marginLeft: 4 }} className={classes.red}>
                    Remove
                  </span>
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Fab
          classes={{ root: classes.addButton }}
          onClick={() => handleAddFields(COLLATERALS)}
          variant='extended'
        >
          <Add classes={{ root: classes.addIcon }} />
          Add Other Collaterals
        </Fab>
      </Grid>
    </>
  )
}

export default CollateralsFormFields
