import React, { useState, useEffect, useContext } from 'react'

import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment, { Moment } from 'moment'
import { Box, Grid, Button, Typography, Divider } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { IValues } from '../../../utils/types'
import { useStyles } from './mortgage.view.style'
import {
  ITransaction,
  ITransactionEdit,
  IPayout,
} from '../helper/mortgage.interface'
import {
  DEFAULT_MORTGAGE_INFO,
  DEFAULT_TRANSACTION_EDIT,
  DEFAULT_PAYOUT,
  TRANSACTION,
  PAYOUT,
} from '../helper/mortgage.constants'
import {
  FixedHeightLoading,
  DialogLoading,
} from '../../../components/loading/loading.component'
import MortgageEditFormFields from '../components/edit.form.fields'
import MortgageViewFields from '../components/view.fields'
import { MortgageTabs } from '../components/tab.panels'
import { TransactionTable } from '../components/table.component'
import { TransactionDialog, PayoutDialog } from '../components/dialog.component'
import { ViewButtonFields } from '../components/button.fields'
import { PayoutContext } from '../../../providers/payout/payout.provider'
import { AuthContext } from '../../../providers/auth/auth.provider'
import { IPayoutInfo } from '../../../providers/payout/helper/types'

const MortgageView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { mortgageId } = useParams()
  const [mortgageLoaded, $mortgageLoaded] = useState<any>(DEFAULT_MORTGAGE_INFO)
  const [account, $account] = useState<IValues>({})
  const [loading, $loading] = useState<boolean>(false)
  const [edit, $edit] = useState<boolean>(false)
  const [sDate, $sDate] = useState<Moment | null>(null)
  const [rows, $rows] = useState<ITransaction[]>([])
  const [selects, $selects] = useState({
    mortgageType: null,
    interestType: null,
  })
  const [checkbox, $checkbox] = useState({
    title: false,
    legalDocs: false,
    lenderPackage: false,
  })
  const [tab, $tab] = useState<number>(0)
  const [transEdit, $transEdit] = useState<ITransactionEdit>(
    DEFAULT_TRANSACTION_EDIT
  )
  const [payout, $payout] = useState<IPayout>(DEFAULT_PAYOUT)
  const { setPayout } = useContext(PayoutContext)
  const { token } = useContext(AuthContext)
  const [payoutSummary, $payoutSummary] = useState<string>('')

  const handleTransactionText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    let newTrans = transEdit
    newTrans = { ...transEdit, values: { ...transEdit.values, [name]: value } }
    $transEdit(newTrans)
  }

  const handlePayoutDate = (dateObj: Moment | null, name: string) => {
    let newPayout = payout
    newPayout = {
      ...newPayout,
      value: { [name]: dateObj?.format('YYYY-MM-DD') },
    }
    $payout(newPayout)
  }

  const openDialog = (type: string, transaction: ITransaction | undefined) => {
    switch (type) {
      case TRANSACTION:
        if (transaction) {
          let newTrans = transEdit
          newTrans = { ...newTrans, selected: transaction, dialog: true }
          $transEdit(newTrans)
        }
        return
      case PAYOUT:
        let newPayout = payout
        newPayout = { ...newPayout, dialog: true }
        $payout(newPayout)
        return
      default:
        return
    }
  }

  const cancelDialog = (type: string) => {
    switch (type) {
      case TRANSACTION:
        let newTrans = transEdit
        newTrans = DEFAULT_TRANSACTION_EDIT
        $transEdit(newTrans)
        return
      case PAYOUT:
        let newPayout = payout
        newPayout = DEFAULT_PAYOUT
        $payout(newPayout)
        return
      default:
        return
    }
  }

  const payoutDetail = () => {
    const newInfo: IPayoutInfo = {
      date: mortgageLoaded.endDate,
      accountId: mortgageLoaded.id,
      accruedInterest: 0,
      fileNo: mortgageLoaded.fileNo,
      summary: payoutSummary.split('#&'),
      amount: mortgageLoaded.mortgageAmount,
      name: mortgageLoaded.mortgageName,
    }
    setPayout(newInfo)
    history.push(`/payout/${mortgageId}/view`)
  }

  const payoutSubmit = () => {
    if (!validate()) {
      let newPayout = payout
      newPayout = { ...newPayout, loading: true }
      $payout(newPayout)
      const body = {
        accountId: mortgageId,
        date: payout.value.date,
      }

      axios
        .post(`${PROD_OPS}/mortgage/payout`, body, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        .then((res) => {
          if (res.status === 200) {
            const newInfo: IPayoutInfo = {
              date: payout.value.date,
              accountId: mortgageId ? mortgageId : '',
              accruedInterest: res.data.accruedInterest,
              fileNo: mortgageLoaded.fileNo,
              summary: [],
              amount: mortgageLoaded.mortgageAmount,
              name: mortgageLoaded.mortgageName,
            }
            setPayout(newInfo)
            $payout(DEFAULT_PAYOUT)
            history.push(`/mortgages/${mortgageId}/payout`)
          } else {
            newPayout = {
              ...newPayout,
              dialog: true,
              finished: true,
              loading: false,
              text: 'Something went wrong, please try it again',
            }
            $payout(newPayout)
          }
        })
    }
  }

  const validate = () => {
    let newErrors: IValues = {}
    let haveError: boolean = false

    Object.keys(payout.value).map((fieldName: string) => {
      if (
        payout.value[fieldName] === null ||
        payout.value[fieldName] === '' ||
        payout.value[fieldName] === 0
      ) {
        newErrors[fieldName] = 'Required'
      } else {
        newErrors[fieldName] = ''
      }
    })

    let newValues = payout
    newValues = { ...newValues, errors: newErrors }
    $payout(newValues)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== '') {
        haveError = true
      }
    })
    return haveError
  }

  const transactionSubmit = () => {
    let newTrans = transEdit
    newTrans = { ...transEdit, loading: true }
    $transEdit(newTrans)
    const body = {
      transactionId: transEdit.selected.id,
      transaction: transEdit.values,
    }

    axios
      .put(`${PROD_OPS}/mortgage/transaction`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      .then((res) => {
        if (res.status === 200) {
          newTrans = {
            ...DEFAULT_TRANSACTION_EDIT,
            dialog: false,
            finished: true,
          }
          $transEdit(newTrans)
          cancelDialog(TRANSACTION)
        } else {
          newTrans = {
            ...newTrans,
            dialog: true,
            finished: true,
            loading: false,
            text: 'Something went wrong, please try it again',
          }
          $transEdit(newTrans)
        }
      })
  }

  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    $tab(newValue)
  }

  const setValues = (value: IValues) => {
    $account({ ...account, ...value })
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues({
      ...account,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    $sDate(dateObj)
    setValues({ ...account, [name]: dateObj?.format('YYYY-MM-DD') })
  }

  const handleSelectField = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $selects({ ...selects, [name]: event.target.value as string })
    setValues({ ...account, [name]: event.target.value as string })
  }

  const handleNewAccountCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name: string = event.target.id
    const checked: boolean = event.target.checked
    $checkbox({ ...checkbox, [name]: checked })
    setValues({ ...account, [name]: checked })
  }

  useEffect(() => {
    const loadData = async () => {
      $loading(true)
      let list: ITransaction[] = []
      try {
        const res = await axios.get(`${PROD_OPS}/mortgage/info/${mortgageId}`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        if (res.status === 200) {
          $mortgageLoaded(res.data.account)
          $payoutSummary(res.data.account.payoutSummary)
          $sDate(
            res.data.account.startDate
              ? moment(res.data.account.startDate, 'YYYY-MM-DD')
              : null
          )
          $selects({
            mortgageType: res.data.account.mortgageType,
            interestType: res.data.account.interestType,
          })
          $checkbox({
            title: res.data.account.title,
            legalDocs: res.data.account.legalDocs,
            lenderPackage: res.data.account.lenderPackage,
          })
          const transactions: ITransaction[] =
            res.data.account.mortgageTransactions
          transactions &&
            transactions.map((t) => {
              const generate: ITransaction = createData(t)
              list.push(generate)
            })
          $rows(list)
          $loading(false)
        }
      } catch (e) {}
    }
    loadData()
  }, [edit, mortgageId, transEdit.finished])

  const createData = ({
    id,
    type,
    transDate,
    amount,
    nfs,
    chequeNo,
    notes,
  }: ITransaction) => {
    return { id, type, transDate, amount, nfs, chequeNo, notes }
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    const body = {
      operation: 'update',
      accountId: mortgageLoaded.id,
      updatedAccount: account,
    }

    $loading(true)
    axios
      .put(`${PROD_OPS}/mortgage/info`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      .then((res) => {
        if (res.status === 200) {
          $loading(false)
          $edit(!edit)
        }
      })
  }

  const setLoading = (bool: boolean) => {
    $loading(bool)
  }

  return (
    <Box component='div' className={classes.root}>
      <Box component='div' className={classes.toolbar}>
        Mortgage Account
      </Box>

      <form className={classes.form} onSubmit={handleSubmit} noValidate={true}>
        {loading ? (
          <FixedHeightLoading />
        ) : (
          <Box component='div' className={classes.sectionRoot}>
            <Box component='div' className={classes.sectionFields}>
              <Grid container direction='column' spacing={4}>
                <Grid item xs={12} container justify='flex-end'>
                  {edit ? (
                    <Button
                      classes={{ root: classes.submit }}
                      style={{ height: 40 }}
                      variant='outlined'
                      type='submit'
                      disabled={loading}
                    >
                      Save Changes
                    </Button>
                  ) : (
                    <Button
                      disabled={mortgageLoaded.status === 'Closed'}
                      onClick={() => $edit(!edit)}
                      className={classes.editIcon}
                    >
                      <EditOutlined /> Edit
                    </Button>
                  )}
                </Grid>
                {edit ? (
                  loading ? (
                    <DialogLoading />
                  ) : (
                    <MortgageEditFormFields
                      mortgageLoaded={mortgageLoaded}
                      sDate={sDate}
                      selects={selects}
                      checkbox={checkbox}
                      handleTextField={handleTextField}
                      handleDate={handleDate}
                      handleSelectField={handleSelectField}
                      handleNewAccountCheckBox={handleNewAccountCheckBox}
                    />
                  )
                ) : (
                  <>
                    <MortgageViewFields mortgageLoaded={mortgageLoaded} />
                    <MortgageTabs
                      tab={tab}
                      tabChange={tabChange}
                      mortgageLoaded={mortgageLoaded}
                    />
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: 24 }}
                      container
                      direction='row'
                    >
                      <Grid item xs={4}>
                        <Typography variant='body1'>
                          Mortgage Transactions:
                        </Typography>
                      </Grid>
                    </Grid>
                    <TransactionTable rows={rows} openDialog={openDialog} />
                    <TransactionDialog
                      transEdit={transEdit}
                      handleTransactionText={handleTransactionText}
                      cancelDialog={cancelDialog}
                      transactionSubmit={transactionSubmit}
                    />
                    <ViewButtonFields
                      mortgageLoaded={mortgageLoaded}
                      openDialog={openDialog}
                      payoutDetail={payoutDetail}
                      setLoading={setLoading}
                    />

                    <PayoutDialog
                      payout={payout}
                      handlePayoutDate={handlePayoutDate}
                      cancelDialog={cancelDialog}
                      payoutSubmit={payoutSubmit}
                    />
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  )
}

export default MortgageView
