import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Fab,
} from '@material-ui/core'
import { Add, HighlightOffOutlined } from '@material-ui/icons'
import axios from 'axios'

import { useStyles } from './account.investor.form.style'
import {
  IBank,
  IAccountInvestorForm,
  DEFAULT_ACCOUNT_DATE_FIELDS,
  IAccountDateFields,
} from '../helper/investor.helper'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { IValues } from '../../../utils/types'
import { FixedHeightLoading } from '../../../components/loading/loading.component'
import { DatePicker } from '@material-ui/pickers'
import { AuthContext } from '../../../providers/auth/auth.provider'
import { Moment } from 'moment'

interface IJointInvestor {
  firstName: string
  lastName: string
  id: string
  sin: string
}

const AccountInvestorForm = () => {
  const classes = useStyles()
  const { userId } = useParams()
  const history = useHistory()

  const [bank, $bank] = useState<string>('')
  const [banks, $banks] = useState<any>([])
  const [investor, $investor] = useState<any>({})
  const [investors, $investors] = useState<IJointInvestor[]>([])
  const [selectedFields, $selectedFields] = useState<any[]>([])

  const [loading, $loading] = useState<boolean>(false)
  const [listLoading, $listLoading] = useState<boolean>(false)
  const [errors, $errors] = useState<IValues>({})
  const [dates, $dates] = useState<IAccountDateFields>(
    DEFAULT_ACCOUNT_DATE_FIELDS
  )
  const { token } = React.useContext(AuthContext)

  const [values, $values] = useState<IAccountInvestorForm>({
    operation: 'create',
    investorIds: [],
    account: {
      certificateNo: '',
      depositDate: '',
      ccoDate: '',
      investmentAmount: 0,
      class: '',
      interestDistribution: '',
      interestRate: 0,
      managementRate: 0,
      bankName: '',
      bankAccountNo: '',
    },
  })
  const [selectValues, $selectValues] = useState({
    interestDistribution: '',
    class: '',
  })

  const setErrors = (error: IValues) => {
    $errors({ ...errors, ...error })
  }

  const validateForm = () => {
    let newErrors: IValues = {}
    let haveError: boolean = false
    let submitted: any = values!.account
    if (values.account) {
      Object.keys(submitted).map((fieldName: string) => {
        if (
          fieldName !== 'bankName' &&
          fieldName !== 'bankAccountNo' &&
          fieldName !== 'class' &&
          fieldName !== 'interestDistribution'
        ) {
          if (
            submitted[fieldName] === null ||
            submitted[fieldName] === '' ||
            submitted[fieldName] === 0
          ) {
            newErrors[fieldName] = 'Required'
          }
        }
      })
    }

    setErrors(newErrors)
    Object.keys(newErrors).map((key) => {
      if (newErrors[key].length !== null) {
        haveError = true
      }
    })
    return haveError
  }

  useEffect(() => {
    const loadData = async () => {
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        const target = res.data.investors.filter((i: any) => i.id === userId)[0]
        $investor(target)
        if (target.banks !== undefined && target.banks.length > 0) {
          $bank(target.banks[0].bankName)
          $banks(target.banks)
        }
        const fieldInfo = {
          firstName: target.firstName,
          sin: target.sin,
        }
        $selectedFields([fieldInfo])
        const form = {
          investorIds: [target.id],
        }
        $values({ ...values, ...form })
      }
    }
    loadData()
  }, [])

  const setValues = (value: IAccountInvestorForm) => {
    $values({ ...values, ...value })
  }

  const handleAddFields = () => {
    const values = [...selectedFields]
    values.push({ firstName: '', sin: '' })
    $selectedFields(values)
  }

  const handleRemoveFields = (index: number) => {
    const fields = [...selectedFields]
    const target = investors.filter(
      (i) => i.firstName === fields[index].firstName
    )[0]
    fields.splice(index, 1)
    $selectedFields(fields)
    if (values.investorIds) {
      const idList = values.investorIds.filter((i) => i !== target.id)
      setValues({ investorIds: idList })
    }
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    index: number
  ) => {
    const fields = [...selectedFields]
    const value = event.target.value as string
    const target = investors.filter((i) => i.firstName === value)[0]
    fields[index].firstName = value
    fields[index].sin = target.sin
    const idList = values.investorIds
    idList.push(target.id)
    $selectedFields(fields)
    setValues({ investorIds: idList })
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    if (!validateForm()) {
      $loading(true)
      axios
        .put(`${PROD_OPS}/investment/info`, values, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        .then((res) => {
          if (res.status === 201) {
            $loading(false)
            history.push('/investors')
          } else {

          }
        })
    }
  }

  const handleBankSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    const value = event.target.value as string
    $bank(event.target.value as string)

    const bankSelected: IBank = banks.filter(
      (b: IBank) => b.bankName === value
    )[0]

    setValues({
      ...values,
      account: {
        ...values.account,
        bankName: value,
      },
    })
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    let newError: IValues = {}
    if (value === '') {
      newError[name] = 'Required'
    } else {
      newError[name] = null
    }
    setErrors(newError)
  }

  useEffect(() => {
    const loadData = async () => {
      $listLoading(true)
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        const list = res.data.investors.filter((i: any) => i.id !== userId)
        if (list.length !== 0) {
          $investors(list)
          $listLoading(false)
        }
      } else {
        throw 'Error Getting Investors'
      }
    }

    setValues({
      ...values,
      account: {
        ...values.account,
        bankName:
          investor.banks !== undefined
            ? investor.banks.length > 0
              ? investor.banks[0].bankName
              : ''
            : '',
      },
    })

    loadData()
  }, [investor])

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues({
      ...values,
      account: {
        ...values.account,
        [e.currentTarget.id]: e.currentTarget.value,
      },
    })
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    let list = dates
    list = { ...list, [name]: dateObj }
    $dates(list)

    setValues({
      ...values,
      account: {
        ...values.account,
        [name]: dateObj !== null ? dateObj.format('YYYY-MM-DD') : '',
      },
    })
  }

  const handleSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $selectValues({
      ...selectValues,
      [name]: event.target.value as string,
    })
    setValues({
      ...values,
      account: { ...values.account, [name]: event.target.value as string },
    })
  }

  return (
    <>
      <div className={classes.toolbarBg}></div>
      <form
        style={{
          width: '100%',
          minHeight: 'calc(100% - 136px)',
          position: 'relative',
          padding: 32,
        }}
        onSubmit={handleSubmit}
      >
        {loading ? (
          <FixedHeightLoading />
        ) : (
          <>
            <Grid container spacing={2} direction='column'>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  Investors *{' '}
                  <span>
                    (You can have multiple investors for the same account)
                  </span>
                </Typography>
              </Grid>
              {selectedFields.map((field, index: number) => {
                if (index === 0) {
                  return (
                    <React.Fragment key={`${field}-${index}`}>
                      <Grid item xs={12} container direction='row'>
                        <Grid item xs={3} container direction='column'>
                          <Grid item xs>
                            <Typography variant='body2'>Investor</Typography>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              margin='dense'
                              variant='outlined'
                              defaultValue={field.firstName}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={3} container direction='column'>
                          <Grid item xs>
                            <Typography variant='body2'>SIN</Typography>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              margin='dense'
                              variant='outlined'
                              defaultValue={field.sin}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={3} container direction='column'>
                          <Grid item xs>
                            <Typography variant='body2'>
                              Bank Account
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <FormControl
                              variant='outlined'
                              margin='dense'
                              fullWidth
                            >
                              <Select
                                name='bankName'
                                id='bank-select-outlined'
                                value={bank}
                                onChange={handleBankSelect}
                              >
                                {banks.map((b: IBank) => (
                                  <MenuItem
                                    key={b.bankName ? b.bankName : ''}
                                    value={b.bankName ? b.bankName : ''}
                                  >
                                    {b.bankName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment key={`${field}-${index}`}>
                      <Grid item xs={12} container direction='row'>
                        <Grid item xs={3} container direction='column'>
                          <Grid item xs>
                            <Typography variant='body2'>
                              Joint Investor
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <FormControl
                              style={{ minWidth: 195 }}
                              variant='outlined'
                              margin='dense'
                            >
                              <Select
                                name='investorIds'
                                id='investors-select-outlined'
                                value={selectedFields[index].firstName}
                                onChange={(e) => handleSelectChange(e, index)}
                              >
                                {investors.map((i: IJointInvestor) => (
                                  <MenuItem
                                    key={i.id ? i.id : '123'}
                                    value={i.firstName ? i.firstName : 'first'}
                                  >
                                    {i.firstName ? i.firstName : 'first'}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={3} container direction='column'>
                          <Grid item xs>
                            <Typography variant='body2'>SIN</Typography>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              disabled
                              value={selectedFields[index].sin}
                              margin='dense'
                              variant='outlined'
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={3} container direction='column'>
                          <Grid item xs />
                          <Grid item xs>
                            <HighlightOffOutlined
                              onClick={() => handleRemoveFields(index)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
                }
              })}
              <Grid item xs={12}>
                <Fab
                  classes={{ root: classes.investorButton }}
                  onClick={() => handleAddFields()}
                  variant='extended'
                  disabled={listLoading}
                >
                  {listLoading ? (
                    'Getting Investors...'
                  ) : (
                    <>
                      <Add classes={{ root: classes.bankIcon }} />
                      Add Joint Investors
                    </>
                  )}
                </Fab>
              </Grid>
            </Grid>
            {/* Detail Field */}
            <Grid container spacing={2} direction='column'>
              <Grid item xs={12} container direction='row'>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>
                      Bank Draft Receive Date:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <DatePicker
                      clearable
                      format='YYYY-MM-DD'
                      value={dates.depositDate}
                      onChange={(date) => handleDate(date, 'depositDate')}
                      inputVariant='outlined'
                      margin='dense'
                      error={!!errors.depositDate}
                      helperText={errors.depositDate}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>CCO Sign off Date:</Typography>
                  </Grid>
                  <Grid item xs>
                    <DatePicker
                      clearable
                      format='YYYY-MM-DD'
                      value={dates.ccoDate}
                      onChange={(date) => handleDate(date, 'ccoDate')}
                      inputVariant='outlined'
                      margin='dense'
                      error={!!errors.ccoDate}
                      helperText={errors.ccoDate}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>Investment Amount:</Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      id='investmentAmount'
                      error={!!errors.investmentAmount}
                      helperText={errors.investmentAmount}
                      onBlur={handleOnBlur}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>Class:</Typography>
                  </Grid>
                  <Grid item xs>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      style={{ minWidth: 195 }}
                    >
                      <Select
                        name='class'
                        id='class-select-outlined'
                        value={selectValues.class}
                        onChange={handleSelect}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value='A'>A</MenuItem>
                        <MenuItem value='B'>B</MenuItem>
                        <MenuItem value='C'>C</MenuItem>
                        <MenuItem value='D'>D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction='row'>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>
                      Interest Distribution:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      style={{ minWidth: 195 }}
                    >
                      <Select
                        name='interestDistribution'
                        id='bank-select-outlined'
                        value={selectValues.interestDistribution}
                        onChange={handleSelect}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value='Quarterly'>Quarterly</MenuItem>
                        <MenuItem value='Annually'>Annually</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>Rate of Return %</Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      id='interestRate'
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>Management Fee %</Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      id='managementRate'
                      error={!!errors.managementRate}
                      helperText={errors.managementRate}
                      onBlur={handleOnBlur}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>Profit Share %</Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      id='psPercentage'
                      error={!!errors.psPercentage}
                      helperText={errors.psPercentage}
                      onBlur={handleOnBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction='row'>
                <Grid item xs={3} container direction='column'>
                  <Grid item xs>
                    <Typography variant='body2'>Certification No.</Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      id='certificateNo'
                      error={!!errors.certificateNo}
                      helperText={errors.certificateNo}
                      onBlur={handleOnBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <div className={classes.buttonField}>
          <Button
            classes={{ root: classes.cancel }}
            variant='outlined'
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            classes={{ root: classes.submit }}
            variant='outlined'
            type='submit'
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  )
}

export default AccountInvestorForm
