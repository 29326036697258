import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '../../../assets/toolbar.png'
import { GRAY_500, RED, NAVY, WHITE, LIGHT_GRAY } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '93%',
      background: WHITE
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    form: {
      width: '100%',
      minHeight: 'calc(100% - 72px)',
      position: 'relative',
      padding: 32
    },
    navy: {
      color: NAVY
    },
    gray: {
      color: GRAY_500
    },
    red: {
      color: RED
    },
    addButton: {
      boxShadow: 'unset',
      backgroundColor: 'transparent',
      color: NAVY,

      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    addIcon: {
      marginRight: 8,
      backgroundColor: NAVY,
      color: WHITE,
      borderRadius: '50%'
    },
    buttonField: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.mixins.toolbar
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    marginTop24: {
      marginTop: theme.spacing(3)
    },
    marginBottom24: {
      marginBottom: theme.spacing(3)
    },
    marginTop40: {
      marginTop: theme.spacing(5)
    },
    margin24: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  })
)
