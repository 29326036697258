import React, { Fragment } from 'react'
import InputMask from 'react-input-mask'
import { Grid, Typography, TextField, Fab } from '@material-ui/core'

import { DeleteForeverOutlined, Add } from '@material-ui/icons'
import { GUARANTORS } from '../helper/mortgage.constants'
import { useStyles } from './guarantors.form.fields.style'
import { IValues } from '../../../utils/types'

const GuarantorsFormFields = ({
  guarantors,
  handleTextField,
  handleAddFields,
  handleRemoveFields,
  errors
}: {
  guarantors: IValues[]
  handleTextField: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAddFields: (field: string) => void
  handleRemoveFields: (idx: number, field: string) => void
  errors: IValues
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12} className={classes.marginTop40}>
        <Typography variant='body1'>
          Guarantor(s){' '}
          <span className={classes.gray}>
            (You can have multiple guarantors for the same account)
          </span>
        </Typography>
      </Grid>
      {guarantors.map((field, idx: number) => (
        <Fragment key={`${field}-${idx}`}>
          <Grid className={classes.margin24} item xs={12}>
            <Typography variant='body2'>Guarantor</Typography>
          </Grid>
          <Grid item xs={12} container direction='row' spacing={2}>
            <Grid item xs={2} container direction='column'>
              <Typography variant='body2'>
                Guarantor Name <span className={classes.red}>*</span>
              </Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                value={
                  guarantors[idx].name === null ? '' : guarantors[idx].name
                }
                id={`${GUARANTORS}#name#${idx}`}
                error={!!errors[`name#${idx}`]}
                helperText={errors[`name#${idx}`]}
              />
            </Grid>
            <Grid item xs={8} container direction='column'>
              <Typography variant='body2'>Guarantor Address</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                value={
                  guarantors[idx].address === null
                    ? ''
                    : guarantors[idx].address
                }
                id={`${GUARANTORS}#address#${idx}`}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} container direction='column'>
              <Grid item xs />
              <Grid item xs>
                <Fab
                  classes={{ root: classes.addButton }}
                  onClick={() => handleRemoveFields(idx, GUARANTORS)}
                  variant='extended'
                >
                  <DeleteForeverOutlined className={classes.red} />
                  <span style={{ marginLeft: 4 }} className={classes.red}>
                    Remove
                  </span>
                </Fab>
              </Grid>
            </Grid>
            <Grid item xs={2} container direction='column'>
              <Typography variant='body2'>City</Typography>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                value={
                  guarantors[idx].city === null ? '' : guarantors[idx].city
                }
                id={`${GUARANTORS}#city#${idx}`}
              />
            </Grid>
            <Grid item xs={2} container direction='column'>
              <Typography variant='body2'>Province</Typography>
              <InputMask
                mask='aa'
                maskChar=' '
                formatChars={{ a: '[A-Z]' }}
                onChange={handleTextField}
                value={
                  guarantors[idx].province === null
                    ? ''
                    : guarantors[idx].province
                }
              >
                {() => (
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    placeholder='BC, AB, ON'
                    value={
                      guarantors[idx].province === null
                        ? ''
                        : guarantors[idx].province
                    }
                    id={`${GUARANTORS}#province#${idx}`}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={2} container direction='column'>
              <Typography variant='body2'>Postal Code</Typography>
              <InputMask
                mask='a9a 9a9'
                maskChar=' '
                formatChars={{ a: '[A-Z]', 9: '[0-9]' }}
                onChange={handleTextField}
                value={
                  guarantors[idx].postCode === null
                    ? ''
                    : guarantors[idx].postCode
                }
              >
                {() => (
                  <TextField
                    margin='dense'
                    variant='outlined'
                    placeholder='A1A 1A1'
                    onChange={handleTextField}
                    value={
                      guarantors[idx].postCode === null
                        ? ''
                        : guarantors[idx].postCode
                    }
                    id={`${GUARANTORS}#postCode#${idx}`}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Fab
          classes={{ root: classes.addButton }}
          onClick={() => handleAddFields(GUARANTORS)}
          variant='extended'
          className={classes.marginBottom24}
        >
          <Add classes={{ root: classes.addIcon }} />
          Add Other Guarantors
        </Fab>
      </Grid>
    </>
  )
}

export default GuarantorsFormFields
