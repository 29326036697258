export const DEFAULT_INVESTOR_FILTER = {
  dateRange: 'All',
  type: 'All',
  status: 'Active',
  investClass: 'All',
  distribution: 'All',
  amount: 'All',
  fee: 'All',
}

export const DEFAULT_MORTGAGE_FILTER = {
  dateRange: 'All',
  type: 'All',
  status: 'Active',
  amount: 'All',
  rate: 'All',
}

export const DEFAULT_DATE = {
  startDate: null,
  endDate: null,
}
