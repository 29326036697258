import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { useStyles } from './dialog.component.style'
import { DialogLoading } from '../../../components/loading/loading.component'
import { NAVY } from '../../../styles/color'
import {
  ITransactionEdit,
  IPrimeRate,
  IPayout,
  IPrimeTable
} from '../helper/mortgage.interface'
import { formatter } from '../../../utils/format'
import { Moment } from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { TRANSACTION, PAYOUT } from '../helper/mortgage.constants'
import { PrimeRateTable } from './table.component'

export const TransactionDialog = ({
  transEdit,
  handleTransactionText,
  cancelDialog,
  transactionSubmit
}: {
  transEdit: ITransactionEdit
  handleTransactionText: (e: React.ChangeEvent<HTMLInputElement>) => void
  cancelDialog: (type: string) => void
  transactionSubmit: () => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullWidth
        open={transEdit.dialog}
        onClose={() => cancelDialog(TRANSACTION)}
        aria-labelledby='transaction-title'
      >
        <DialogTitle id='transaction-title'>Edit</DialogTitle>
        <DialogContent dividers>
          {transEdit.loading ? (
            <DialogLoading />
          ) : (
            <>
              {transEdit.finished ? (
                <Typography variant='body1'>{transEdit.text}</Typography>
              ) : (
                <>
                  <Grid
                    container
                    direction='row'
                    justify='space-around'
                    spacing={1}
                  >
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Date:</Typography>
                      <Typography style={{ color: NAVY }} variant='body1'>
                        {transEdit.selected.transDate}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Type:</Typography>
                      <Typography style={{ color: NAVY }} variant='body1'>
                        {transEdit.selected.type}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Amount:</Typography>
                      <Typography style={{ color: NAVY }} variant='body1'>
                        {formatter.format(transEdit.selected.amount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} />
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Cheque No.</Typography>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        id='chequeNo'
                        onChange={handleTransactionText}
                        defaultValue={transEdit.selected.chequeNo}
                      />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>NSF</Typography>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        id='nfs'
                        onChange={handleTransactionText}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          )
                        }}
                        defaultValue={
                          transEdit.selected.nfs === 0
                            ? ''
                            : transEdit.selected.nfs
                        }
                      />
                    </Grid>
                    <Grid item xs={12} container direction='column'>
                      <Typography variant='body2'>Notes</Typography>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        multiline
                        rows='3'
                        id='notes'
                        onChange={handleTransactionText}
                        defaultValue={
                          transEdit.selected.notes
                            ? transEdit.selected.notes
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            className={classes.dialogCancelButton}
            onClick={() => cancelDialog(TRANSACTION)}
          >
            {transEdit.finished ? 'Close' : 'Cancel'}
          </Button>
          <Button
            disabled={transEdit.loading || transEdit.finished}
            className={classes.dialogSubmitButton}
            onClick={() => transactionSubmit()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const PrimeRateDialog = ({
  primeRate,
  handleTextField,
  handleSelectField,
  handleDate,
  cancelDialog,
  dialogSubmit
}: {
  primeRate: IPrimeRate
  handleTextField: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectField: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void
  handleDate: (dateObj: Moment | null, name: string) => void
  cancelDialog: () => void
  dialogSubmit: () => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.prime }}
        fullWidth
        open={primeRate.dialog}
        onClose={cancelDialog}
        aria-labelledby='transaction-title'
      >
        <DialogTitle id='transaction-title'>Prime Rate</DialogTitle>
        <DialogContent dividers>
          {primeRate.loading ? (
            <DialogLoading />
          ) : (
            <>
              {primeRate.finished ? (
                <Typography variant='body1'>{primeRate.text}</Typography>
              ) : (
                <>
                  <Grid
                    container
                    direction='row'
                    justify='space-around'
                    spacing={1}
                  >
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Date:</Typography>
                      <DatePicker
                        clearable
                        format='YYYY-MM-DD'
                        value={primeRate.value.date}
                        onChange={date => handleDate(date, 'date')}
                        inputVariant='outlined'
                        margin='dense'
                        error={!!primeRate.errors.date}
                        helperText={primeRate.errors.date}
                      />
                    </Grid>
                    <Grid item xs={7} />
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Rate</Typography>
                      <TextField
                        error={!!primeRate.errors.rate}
                        helperText={primeRate.errors.rate}
                        margin='dense'
                        variant='outlined'
                        id='rate'
                        type='number'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>%</InputAdornment>
                          )
                        }}
                        onChange={handleTextField}
                      />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5} container direction='column'>
                      <Typography variant='body2'>Bank</Typography>
                      <FormControl
                        variant='outlined'
                        margin='dense'
                        error={!!primeRate.errors.bank}
                      >
                        <Select
                          name='bank'
                          value={
                            primeRate.value.bank === null
                              ? ''
                              : primeRate.value.bank
                          }
                          onChange={handleSelectField}
                        >
                          <MenuItem value=''>{<em>None</em>}</MenuItem>
                          <MenuItem value='CIBC'>CIBC</MenuItem>
                          <MenuItem value='TD'>TD</MenuItem>
                          <MenuItem value='BMO'>BMO</MenuItem>
                          <MenuItem value='Scotia'>Scotia Bank</MenuItem>
                        </Select>
                        <FormHelperText>{primeRate.errors.bank}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button className={classes.dialogCancelButton} onClick={cancelDialog}>
            {primeRate.finished ? 'Close' : 'Cancel'}
          </Button>
          <Button
            disabled={primeRate.loading || primeRate.finished}
            className={classes.dialogSubmitButton}
            onClick={() => dialogSubmit()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const PayoutDialog = ({
  payout,
  handlePayoutDate,
  cancelDialog,
  payoutSubmit
}: {
  payout: IPayout
  handlePayoutDate: (dateObj: Moment | null, name: string) => void
  cancelDialog: (type: string) => void
  payoutSubmit: () => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.payout }}
        fullWidth
        open={payout.dialog}
        onClose={() => cancelDialog(PAYOUT)}
        aria-labelledby='transaction-title'
      >
        <DialogTitle id='transaction-title'>Payout Date</DialogTitle>
        <DialogContent dividers>
          {payout.loading ? (
            <DialogLoading />
          ) : (
            <>
              {payout.finished ? (
                <Typography variant='body1'>{payout.text}</Typography>
              ) : (
                <>
                  <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'
                  >
                    <Grid item xs={6} container direction='column'>
                      <Typography variant='body2'>Date:</Typography>
                      <DatePicker
                        clearable
                        format='YYYY-MM-DD'
                        value={payout.value.date}
                        onChange={date => handlePayoutDate(date, 'date')}
                        inputVariant='outlined'
                        margin='dense'
                        error={!!payout.errors.date}
                        helperText={payout.errors.date}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            className={classes.dialogCancelButton}
            onClick={() => cancelDialog(PAYOUT)}
          >
            {payout.finished ? 'Close' : 'Cancel'}
          </Button>
          <Button
            disabled={payout.loading || payout.finished}
            className={classes.dialogSubmitButton}
            onClick={() => payoutSubmit()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const RateHistoryDialog = ({
  rows,
  historyDialog,
  closeHistory
}: {
  rows: IPrimeTable[]
  historyDialog: boolean
  closeHistory: () => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullWidth
        open={historyDialog}
        onClose={closeHistory}
        aria-labelledby='transaction-title'
      >
        <DialogTitle id='transaction-title'>Prime Rate History</DialogTitle>
        <DialogContent dividers>
          <PrimeRateTable rows={rows} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button className={classes.dialogSubmitButton} onClick={closeHistory}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
