import React from 'react'
import { Box } from '@material-ui/core'
import { GRAY_200 } from '../../../styles/color'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export const AccountTab = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      style={{
        background: GRAY_200,
        padding: 24,
        maxWidth: '100%'
      }}
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

export const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
