import * as React from 'react'
import { DEFAULT_PAYOUT } from './helper/constatns'
import { IPayoutContext, IPayoutInfo } from './helper/types'
import { IMortgageLoaded } from '../../pages/mortgage/helper/mortgage.interface'

export const PayoutContext = React.createContext<IPayoutContext>({
  payout: DEFAULT_PAYOUT,
  setPayout: (payInfo: IPayoutInfo) => {},
  resetPayout: () => {},
  loaded: null,
  setLoadedMortgage: (value: IMortgageLoaded) => {}
})

const { Provider } = PayoutContext

const PayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [payout, $payout] = React.useState<IPayoutInfo>(DEFAULT_PAYOUT)
  const [loaded, $loaded] = React.useState<IMortgageLoaded | null>(null)

  const setPayout = (payInfo: IPayoutInfo) => {
    $payout(payInfo)
  }

  const resetPayout = () => {
    $payout(DEFAULT_PAYOUT)
  }

  const setLoadedMortgage = (data: IMortgageLoaded) => {
    $loaded(data)
  }

  return (
    <Provider
      value={{ payout, setPayout, resetPayout, loaded, setLoadedMortgage }}
    >
      {children}
    </Provider>
  )
}

export default PayoutProvider
