import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, GRAY_600, WHITE } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: GRAY_600
    },
    table: {
      minWidth: '100%'
    },
    textWhite: {
      color: WHITE
    },
    textNavy: {
      color: NAVY
    },
    boxShadow: {
      boxShadow: 'unset',
      borderRadius: 'unset'
    }
  })
)
