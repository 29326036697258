import { Moment } from 'moment'
export interface IInvestorMappings {
  id: string
  investmentAmount: number
}

export interface IBank {
  bankName: string | null
}

export interface IAccountDateFields {
  depositDate: Moment | null
  ccoDate: Moment | null
}

export interface IInvestorForm {
  info: {
    fileNo: string
    investorType: string
    firstName: string
    middleName: string
    lastName: string
    gender: string
    birthDay: string
    sin: string
    companyName: string
    businessNumber: string
    contactName: string
    authorizerName: string
    address: string
    city: string
    province: string
    postCode: string
    phone: string
    mobile: string
    email: string
    belongsTo: string
  }
  banks: IBank[]
}

export interface IAccountInvestorForm {
  operation?: string
  investorIds: string[]
  account?: {
    certificateNo?: string
    depositDate?: string
    ccoDate?: string
    startDate?: string
    matureDate?: string
    endDate?: string
    status?: string
    investmentAmount?: number
    class?: string
    interestDistribution?: string
    nextInterestStartDate?: string
    interestRate?: number
    managementRate?: number
    psEligibility?: boolean
    psPercentage?: number
    bankName?: string
    bankAccountNo?: string
  }
}

export interface IInvestor {
  id: string
  fileNo: string
  investorType: string
  firstName: string
  middleName: string
  lastName: string
  gender: string
  birthDay: string
  sin: string
  companyName: string
  businessNumber: string
  contactName: string
  authorizerName: string
  address: string
  city: string
  province: string
  postCode: string
  phone: string
  mobile: string
  email: string
  belongsTo: string
  notes: string
  createdAt: string
  updatedAt: string
  investorMappings: IInvestorMappings[]
  accountNo: number
  amount: number
  banks: IBank[]
  status: string
}

export interface IInvestorID {
  id: string
}

export interface IAccountForm {
  certificateNo: string
  depositDate: string
  ccoDate: string
  startDate: string
  matureDate: string
  endDate: string
  status: string
  investmentAmount: number
  class: string
  interestDistribution: string
  nextInterestStartDate: string
  interestRate: number
  managementRate: number
  psEligibility: boolean
  psPercentage: number
  netProfit: number
  bankName: string
  bankAccountNo: string
  investorIds: IInvestorID[]
}

export interface IInvestorTable {
  firstName: string
  contactName: string
  phone: string
  amount: number
  accountNo: number
  status: string
  id: string
}

export interface IInvestmentTransaction {
  id: string
  transactionDate: string
  type: string
  amount: number
  referenceNo: string
  method: string
  notes: string
}

export interface IAccountInfo {
  id: string
  certificateNo: string
  depositDate: string
  ccoDate: string
  startDate: string
  matureDate: string
  endDate: string
  status: string
  investmentAmount: number
  class: string
  interestDistribution: string
  nextInterestStartDate: string
  interestRate: number
  managementRate: number
  psEligibility: boolean
  psPercentage: number
  bankName: string
  bankAccountNo: string
  createdAt: string
  updatedAt: string
  investmentTransactions: IInvestmentTransaction[]
  investor?: {
    companyName?: string
    contactName?: string
    firstName?: string
    investorType?: string
    lastName?: string
    phone?: string
  }
}

export interface ITransaction {
  id: string
  transactionDate: string
  type: string
  amount: number
  referenceNo: string
  method: string
  notes: string
}

export const DEFAULT_TRANSACTION = {
  id: '',
  transactionDate: '',
  type: '',
  amount: 0,
  referenceNo: '',
  method: '',
  notes: '',
}

export const DEFAULT_INVESTOR = {
  info: {
    fileNo: null,
    investorType: null,
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    birthDay: null,
    sin: null,
    companyName: null,
    businessNumber: null,
    contactName: null,
    authorizerName: null,
    address: null,
    city: null,
    province: null,
    postCode: null,
    phone: null,
    mobile: null,
    email: null,
    belongsTo: null,
  },
  banks: [],
}

export const DEFAULT_BANK: IBank = {
  bankName: null,
}

export const DEFAULT_ACCOUNT = {
  info: {
    certificateNo: null,
    depositDate: null,
    ccoDate: null,
    investmentAmount: 0,
    class: null,
    interestDistribution: null,
    interestRate: 0,
    managementRate: 0,
    bankName: null,
    bankAccountNo: null,
    psPercentage: 0,
  },
  investorIds: [],
}

export const DEFAULT_INVESTOR_INFO = {
  id: null,
  fileNo: null,
  investorType: null,
  firstName: null,
  middleName: null,
  lastName: null,
  gender: null,
  birthDay: null,
  sin: null,
  companyName: null,
  businessNumber: null,
  contactName: null,
  authorizerName: null,
  address: null,
  city: null,
  province: null,
  postCode: null,
  phone: null,
  mobile: null,
  email: null,
  belongsTo: null,
  notes: null,
  createdAt: null,
  updatedAt: null,
  investorMappings: [],
  accountNo: 0,
  amount: 0,
  banks: [],
}

export const DEFAULT_ACCOUNT_INFO = {
  id: '',
  certificateNo: '',
  depositDate: '',
  ccoDate: '',
  startDate: '',
  matureDate: '',
  endDate: '',
  status: '',
  investmentAmount: 0,
  class: '',
  interestDistribution: '',
  nextInterestStartDate: '',
  interestRate: 0,
  managementRate: 0,
  psEligibility: false,
  psPercentage: 0,
  bankName: '',
  bankAccountNo: '',
  createdAt: '',
  updatedAt: '',
  investmentTransactions: [],
}

export const DATE_CHARS = {
  n: '[0-1]',
  m: '[0-9]',
  e: '[0-3]',
  d: '[0-9]',
  z: '[1-2]',
  y: '[0-9]',
}

export const DEFAULT_ACCOUNT_DATE_FIELDS = {
  depositDate: null,
  ccoDate: null,
}
