import { ISettings } from '../setting'

export const DEFAULT_SETTINGS: ISettings = {
  id: '',
  classATerm: 0,
  classAProfitShare: false,
  classBTerm: 0,
  classBProfitShare: false,
  classCTerm: 0,
  classCProfitShare: false,
  classDTerm: 0,
  classDProfitShare: false,
  managementFeeRate: 0,
  monthlyInterestRate: 0,
  quarterlyInterestRate: 0,
  semiannuallyInterestRate: 0,
  annuallyInterestRate: 0,
  profitShare: 0,
  netProfit: 0,
  reminderInvestmentMaturity: 0,
  reminderInvestmentInterest: 0,
  reminderMortgageMaturity: 0,
  reminderMortgageInterest: 0,
  reminderTitle: false,
  reminderLegalDoc: false,
  reminderLenderPackage: false,
  createdAt: '',
  updatedAt: ''
}
