import InvestorPage from './investor'
import InvestorForm from './forms'
import InvestorView from './views'
import AccountInvestorForm from './forms/account.investor.form'
import AccountReminderView from './views/account.reminder.view'

export default [
  {
    Component: AccountInvestorForm,
    title: 'New Account with Investor Form',
    path: '/investors/:userId/newAccount'
  },
  {
    Component: InvestorView,
    title: 'Investor View',
    path: '/investors/:userId/view'
  },
  {
    Component: AccountReminderView,
    title: 'Account Reminder View',
    path: '/accounts/:accountId/view'
  },
  {
    Component: InvestorForm,
    title: 'Investor Form',
    path: '/investors/new'
  },

  {
    Component: InvestorPage,
    title: 'Investors',
    path: '/investors',
    exact: true
  }
]
