import React, { useState, useEffect } from 'react'

import { useStyles } from './setting.style'
import {
  Button,
  Paper,
  Typography,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core'
import axios from 'axios'
import { DEFAULT_SETTINGS } from './helper/setting.helper'
import { useHistory } from 'react-router-dom'
import { PROD_AUTH, AUTH_KEY } from '../../utils/constants'
import { FixedHeightLoading } from '../../components/loading/loading.component'
import { NAVY } from '../../styles/color'
import { ErrorPage } from '../../components/error/error.component'
import { AuthContext } from '../../providers/auth/auth.provider'

export interface IValues {
  [key: string]: any
}

export interface ISettings {
  id: string
  classATerm: number
  classAProfitShare: boolean
  classBTerm: number
  classBProfitShare: boolean
  classCTerm: number
  classCProfitShare: boolean
  classDTerm: number
  classDProfitShare: boolean
  managementFeeRate: number
  monthlyInterestRate: number
  quarterlyInterestRate: number
  semiannuallyInterestRate: number
  annuallyInterestRate: number
  profitShare: number
  netProfit: number
  reminderInvestmentMaturity: number
  reminderInvestmentInterest: number
  reminderMortgageMaturity: number
  reminderMortgageInterest: number
  reminderTitle: boolean
  reminderLegalDoc: boolean
  reminderLenderPackage: boolean
  createdAt?: string
  updatedAt?: string
}

const SettingPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const [initialLoading, $initialLoading] = useState(false)
  const [settings, $settings] = useState<ISettings>(DEFAULT_SETTINGS)
  const [values, $values] = useState<IValues>({})
  const [axiosError, $axiosError] = useState<boolean>(false)
  const { token } = React.useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        $initialLoading(true)
        const res = await axios.get(`${PROD_AUTH}/setting`, {
          headers: { 'x-api-key': AUTH_KEY, Authorization: token },
        })
        $settings(res.data.setting)
        $initialLoading(false)
      } catch {
        $axiosError(true)
        $initialLoading(false)
      }
    }
    fetchData()
  }, [])

  const formSubmission = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    $initialLoading(true)
    const body = {
      settingId: settings.id,
      setting: values,
    }
    axios
      .put(`${PROD_AUTH}/setting`, body, {
        headers: { 'x-api-key': AUTH_KEY, Authorization: token },
      })
      .then((res) => {
        $initialLoading(false)
        history.push('/')
      })
  }

  const setValues = (inputValues: IValues) => {
    $values({ ...values, ...inputValues })
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    setValues({ [name]: value })
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.id as string
    const bool = e.target.checked as boolean
    $settings({ ...settings, [name]: bool })
    setValues({ [name]: bool })
  }

  const [age, $age] = React.useState('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    $age(event.target.value as string)
  }

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as string
    const value = (event.target.value as string) === 'yes'

    $settings({ ...settings, [name]: value })
    setValues({ [name]: value })
  }
  return axiosError ? (
    <ErrorPage />
  ) : (
    <div className={classes.root}>
      <div className={classes.toolbarBg}>Setting</div>
      <form onSubmit={formSubmission} noValidate={true}>
        {initialLoading ? (
          <FixedHeightLoading />
        ) : (
          <>
            <div className={classes.settingPanel}>
              <Paper classes={{ root: classes.paper }}>
                <Typography variant='h6' style={{ marginBottom: 8 }}>
                  Investment Setting
                </Typography>
                <Grid container spacing={9}>
                  <Grid item xs={6} container direction='row'>
                    <Grid item xs={12}>
                      <TableContainer
                        component={Paper}
                        classes={{ root: classes.classPaper }}
                      >
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '20%' }}></TableCell>
                              <TableCell align='center'>Class A</TableCell>
                              <TableCell align='center'>Class B</TableCell>
                              <TableCell align='center'>Class C</TableCell>
                              <TableCell align='center'>Class D</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ width: '20%' }}>
                                Class Term
                              </TableCell>
                              <TableCell>
                                <TextField
                                  id='classATerm'
                                  type='number'
                                  defaultValue={settings.classATerm}
                                  onChange={handleTextField}
                                  inputProps={{
                                    style: { textAlign: 'center' },
                                  }}
                                  size='small'
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  id='classBTerm'
                                  type='number'
                                  defaultValue={settings.classBTerm}
                                  onChange={handleTextField}
                                  inputProps={{
                                    style: { textAlign: 'center' },
                                  }}
                                  size='small'
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  id='classCTerm'
                                  type='number'
                                  defaultValue={settings.classCTerm}
                                  onChange={handleTextField}
                                  inputProps={{
                                    style: { textAlign: 'center' },
                                  }}
                                  size='small'
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  id='classDTerm'
                                  type='number'
                                  defaultValue={settings.classDTerm}
                                  onChange={handleTextField}
                                  inputProps={{
                                    style: { textAlign: 'center' },
                                  }}
                                  size='small'
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ width: '20%' }}>
                                Profit Share
                              </TableCell>
                              <TableCell align='center'>
                                <Checkbox
                                  style={{ color: NAVY }}
                                  id='classAProfitShare'
                                  checked={settings.classAProfitShare}
                                  onChange={handleCheck}
                                />
                              </TableCell>
                              <TableCell align='center'>
                                <Checkbox
                                  style={{ color: NAVY }}
                                  id='classBProfitShare'
                                  checked={settings.classBProfitShare}
                                  onChange={handleCheck}
                                />
                              </TableCell>
                              <TableCell align='center'>
                                <Checkbox
                                  style={{ color: NAVY }}
                                  id='classCProfitShare'
                                  checked={settings.classCProfitShare}
                                  onChange={handleCheck}
                                />
                              </TableCell>
                              <TableCell align='center'>
                                <Checkbox
                                  style={{ color: NAVY }}
                                  id='classDProfitShare'
                                  checked={settings.classDProfitShare}
                                  onChange={handleCheck}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid className={classes.grid} item xs={6}>
                        Net Profit LP:{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='netProfit'
                          type='number'
                          defaultValue={settings.netProfit}
                          onChange={handleTextField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid className={classes.grid} item xs={12}>
                        Management Fee:{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='managementFeeRate'
                          type='number'
                          defaultValue={settings.managementFeeRate}
                          onChange={handleTextField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container direction='row' spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        Interest Distribution:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                      <Grid className={classes.grid} item xs={6}>
                        Annualy{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='annuallyInterestRate'
                          type='number'
                          defaultValue={settings.annuallyInterestRate}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={6}>
                        Semi-Annually{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='semiannuallyInterestRate'
                          type='number'
                          defaultValue={settings.semiannuallyInterestRate}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={6}>
                        Quaterly{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='quarterlyInterestRate'
                          type='number'
                          defaultValue={settings.quarterlyInterestRate}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={6}>
                        Monthly{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='monthlyInterestRate'
                          type='number'
                          defaultValue={settings.monthlyInterestRate}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2'>Reminders:</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                      <Grid className={classes.grid} item xs={12}>
                        Investment Maturity Reminder:{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='reminderInvestmentMaturity'
                          type='number'
                          defaultValue={settings.reminderInvestmentMaturity}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                days
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={12}>
                        Investment Interest Payment Reminder:{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='reminderInvestmentInterest'
                          type='number'
                          defaultValue={settings.reminderInvestmentInterest}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                days
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
            <div className={classes.settingPanel}>
              <Paper classes={{ root: classes.paper }}>
                <Typography variant='h6' style={{ marginBottom: 8 }}>
                  Mortgage Setting
                </Typography>
                <Grid container spacing={9}>
                  <Grid item xs={6} container direction='row' spacing={1}>
                    <Grid item xs={12} container>
                      <Grid item xs={12}>
                        Reminders:
                      </Grid>
                      <Grid item xs={12}>
                        Title Certificate:
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          name='reminderTitle'
                          value={settings.reminderTitle ? 'yes' : 'no'}
                          onChange={handleRadio}
                          row
                        >
                          <FormControlLabel
                            value='yes'
                            control={<Radio style={{ color: NAVY }} />}
                            label='Yes'
                            labelPlacement='end'
                          />
                          <FormControlLabel
                            value='no'
                            control={<Radio style={{ color: NAVY }} />}
                            label='No'
                            labelPlacement='end'
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12}>
                          Legal Document
                        </Grid>
                        <Grid item xs={12}>
                          <RadioGroup
                            name='reminderLegalDoc'
                            value={settings.reminderLegalDoc ? 'yes' : 'no'}
                            onChange={handleRadio}
                            row
                          >
                            <FormControlLabel
                              value='yes'
                              control={<Radio style={{ color: NAVY }} />}
                              label='Yes'
                              labelPlacement='end'
                            />
                            <FormControlLabel
                              value='no'
                              control={<Radio style={{ color: NAVY }} />}
                              label='No'
                              labelPlacement='end'
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container direction='row' spacing={1}>
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={12}>
                        Lender Package
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          name='reminderLenderPackage'
                          value={settings.reminderLenderPackage ? 'yes' : 'no'}
                          onChange={handleRadio}
                          row
                        >
                          <FormControlLabel
                            value='yes'
                            control={<Radio style={{ color: NAVY }} />}
                            label='Yes'
                            labelPlacement='end'
                          />
                          <FormControlLabel
                            value='no'
                            control={<Radio style={{ color: NAVY }} />}
                            label='No'
                            labelPlacement='end'
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                      <Grid className={classes.grid} item xs={12}>
                        Mortgage Maturity Reminder:{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='reminderMortgageMaturity'
                          type='number'
                          defaultValue={settings.reminderMortgageMaturity}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                days
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={12}>
                        Mortgage Interest Reminder:{' '}
                        <TextField
                          classes={{ root: classes.textfield }}
                          size='small'
                          variant='outlined'
                          id='reminderMortgageInterest'
                          type='number'
                          defaultValue={settings.reminderMortgageInterest}
                          onChange={handleTextField}
                          inputProps={{
                            style: { textAlign: 'end' },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                days
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </>
        )}
        <div
          className={classes.toolbar}
          style={{ width: '94%', margin: 'auto', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => history.push('/')}
            classes={{ root: classes.cancel }}
            variant='contained'
          >
            Cancel
          </Button>
          <Button
            disabled={initialLoading}
            type='submit'
            classes={{ root: classes.submit }}
            variant='contained'
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default SettingPage
