export const DEFAULT_USER = {
  username: '',
  id: '',
  role: '',
  authToken: ''
}

export const DEFAULT_AUTH_CONTEXT = {
  user: DEFAULT_USER,
  authToken: '',
  authorized: false
}
