import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { WHITE } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      background: WHITE
    }
  })
)
