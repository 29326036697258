import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY } from '../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '25%'
    },
    stat: {
      minWidth: 400,
      marginLeft: 15,
      marginRight: 15,
      padding: 24,
      background: 'white',
      height: 176,
      borderRadius: 18
    },
    iconTypo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    bigIcon: {
      marginRight: 10
    },
    smallIcon: {
      marginLeft: 30,
      marginRight: 10
    },
    buttonAndList: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: NAVY
    },
    link: {
      color: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  })
)
