import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import moment, { Moment } from 'moment'

import { useStyles } from './account.view.style'
import {
  Box,
  Typography,
  Grid,
  Dialog,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Divider,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { AccountTab, a11yProps } from '../components/account.tabs.component'
import { IValues } from '../../../utils/types'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import {
  IAccountInfo,
  DEFAULT_ACCOUNT_INFO,
  ITransaction,
  DEFAULT_TRANSACTION,
  IAccountDateFields,
  DEFAULT_ACCOUNT_DATE_FIELDS,
} from '../helper/investor.helper'
import { EditOutlined, Add } from '@material-ui/icons'
import AccountDetail from '../components/account.detail.component'
import {
  RedeemDialog,
  PartialRedeemDialog,
  TransferLPDialog,
  RedeemSuccessDialog,
} from '../components/account.dialogs.component'
import {
  FixedHeightLoading,
  DialogLoading,
} from '../../../components/loading/loading.component'
import { NAVY } from '../../../styles/color'
import { formatter } from '../../../utils/format'
import { AuthContext } from '../../../providers/auth/auth.provider'

interface IDialogs {
  transaction: boolean
  redeem: boolean
  partialRedeem: boolean
  transfer: boolean
}

interface IRedeemed {
  date: string
  principle: number
  interest: number
  total: number
}

const AccountView = ({
  loading,
  loadedInvestor,
  loadedMapping,
  account,
  edit,
  setEdit,
  setValues,
  setForm,
  handleBack,
  banks,
  investors,
  dialogSubmit,
  setDialogSubmit,
}: {
  loading: boolean
  loadedInvestor: any
  loadedMapping: any
  account: IValues
  setValues: (value: IValues, type: string) => void
  setForm: (name: string) => void
  edit: boolean
  setEdit: () => void
  handleBack: () => void
  banks: any[]
  investors: any[]
  dialogSubmit: boolean
  setDialogSubmit: (boolean: boolean) => void
}) => {
  const formType = 'account'
  const classes = useStyles()
  const history = useHistory()
  const [tabs, $tabs] = React.useState(0)
  const [bank, $bank] = React.useState<string>('')
  const [transactionL, $transactionL] = useState<boolean>(false)
  const [accountInfo, $accountInfo] = useState<IAccountInfo>(
    DEFAULT_ACCOUNT_INFO
  )
  const [selectedTransaction, $selectedTransaction] = useState<ITransaction>(
    DEFAULT_TRANSACTION
  )
  const [dates, $dates] = useState<IAccountDateFields>(
    DEFAULT_ACCOUNT_DATE_FIELDS
  )
  const { token } = React.useContext(AuthContext)

  const [distribution, $distribution] = useState(
    accountInfo.interestDistribution ? accountInfo.interestDistribution : ''
  )
  const [paymentMethod, $paymentMethod] = useState<string>('')
  const [investorId, $investorId] = useState<string>('')
  const [aClass, $aClass] = useState(accountInfo.class ? accountInfo.class : '')
  const [rows, $rows] = useState<ITransaction[]>([])
  const [dialogs, $dialogs] = useState<IDialogs>({
    transaction: false,
    redeem: false,
    partialRedeem: false,
    transfer: false,
  })
  const [successDialog, $successDialog] = useState<boolean>(false)

  const [redeemDate, $redeemDate] = useState<Moment | null>(null)
  const [partialDate, $partialDate] = useState<Moment | null>(null)
  const [transferDate, $transferDate] = useState<Moment | null>(null)
  const [transaction, $transaction] = useState<IValues>({})
  const [redeemValues, $redeemValues] = useState<IValues>({ date: '' })
  const [partialValues, $partialValues] = useState<IValues>({
    date: '',
    redeemAmount: '',
  })
  const [transferValues, $transferValues] = useState<IValues>({
    date: '',
    transfereeId: '',
    newCertificateNo: '',
    bankName: '',
    bankAccountNo: '',
  })
  const [dialogErrors, $dialogErrors] = useState<IValues>({})
  const [redeemedData, $redeemdData] = useState<IRedeemed>({
    date: '',
    principle: 0,
    interest: 0,
    total: 0,
  })

  const setTransaction = (value: IValues) => {
    $transaction({ ...transaction, ...value })
  }
  const setRedeemValues = (value: IValues) => {
    $redeemValues({ ...redeemValues, ...value })
  }
  const setPartialValues = (value: IValues) => {
    $partialValues({ ...partialValues, ...value })
  }
  const setTransferValues = (value: IValues) => {
    $transferValues({ ...transferValues, ...value })
  }

  const setDialogErrors = (error: IValues) => {
    $dialogErrors({ ...dialogErrors, ...error })
  }

  const handleTransactionText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setTransaction({
      ...transaction.transaction,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleTransactionSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    const value = event.target.value as string
    $paymentMethod(event.target.value as string)
    setTransaction({
      ...transaction.transaction,
      [name]: event.target.value as string,
    })
  }

  const handleRedeemDate = (dateObj: Moment | null) => {
    if (dateObj === null) {
      $redeemDate(dateObj)
      setRedeemValues({
        ...redeemValues,
        date: '',
      })
    } else {
      $redeemDate(dateObj)
      setRedeemValues({
        ...redeemValues,
        date: dateObj?.format('YYYY-MM-DD'),
      })
    }
  }

  const handlePartialDate = (dateObj: Moment | null) => {
    if (dateObj === null) {
      $partialDate(dateObj)
      setPartialValues({
        ...partialValues,
        date: '',
      })
    } else {
      $partialDate(dateObj)
      setPartialValues({
        ...partialValues,
        date: dateObj?.format('YYYY-MM-DD'),
      })
    }
  }

  const handleTransferDate = (dateObj: Moment | null) => {
    if (dateObj === null) {
      $transferDate(dateObj)
      setTransferValues({
        ...transferValues,
        date: '',
      })
    } else {
      $transferDate(dateObj)
      setTransferValues({
        ...transferValues,
        date: dateObj?.format('YYYY-MM-DD'),
      })
    }
  }

  const handleRedeemText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    let value = e.currentTarget.value as string

    setRedeemValues({
      ...redeemValues,
      [name]: value,
    })
  }
  const handlePartialText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    let value = e.currentTarget.value as string
    let numValue: number = 0
    if (name === 'redeemAmount') {
      numValue = Number(value)
      setPartialValues({
        ...partialValues,
        [name]: numValue,
      })
    } else {
      setPartialValues({
        ...partialValues,
        [name]: value,
      })
    }
  }
  const handleTransferText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    let value = e.currentTarget.value as string

    setTransferValues({
      ...transferValues,
      [name]: value,
    })
  }

  const handleDialogBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    let numValue: number
    let newError: IValues = {}

    if (name === 'redeemAmount') {
      numValue = Number(value)
      if (numValue > accountInfo.investmentAmount) {
        newError[
          name
        ] = `Amount must be smaller than $${accountInfo.investmentAmount}`
      } else {
        newError[name] = null
      }
    } else if (!moment(value, 'YYYY-MM-DD').isSameOrAfter()) {
      newError[name] = `Date needs to be equal of after ${moment().format(
        'YYYY-MM-DD'
      )}`
    } else {
      newError[name] = null
    }

    setDialogErrors(newError)
  }

  const handleDialogBank = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    let value = event.target.value as string
    const bankName = value.split('#')[0]
    const bankAccountNo = value.split('#')[1]
    $bank(value)
    setTransferValues({ ...transferValues, bankName, bankAccountNo })
  }

  const handleDialogInvestor = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $investorId(event.target.value as string)
    setTransferValues({
      ...transferValues,
      [name]: event.target.value as string,
    })
  }

  const openDialog = (type: string, transaction?: ITransaction) => {
    if (transaction) {
      $selectedTransaction(transaction)
    }
    $dialogs({ ...dialogs, [type]: true })
  }

  const openSuccessDialog = () => {
    $successDialog(true)
  }

  const closeSuccessDialog = () => {
    $successDialog(false)
  }

  const cancelDialog = (type: string) => {
    $selectedTransaction(DEFAULT_TRANSACTION)
    $redeemValues({})
    $partialValues({})
    $transferValues({})
    $dialogs({
      transaction: false,
      redeem: false,
      partialRedeem: false,
      transfer: false,
    })
    $dialogErrors({})
  }

  const transactionSubmit = async (type: string) => {
    $transactionL(true)
    const body = {
      transactionId: selectedTransaction.id,
      transaction: transaction,
    }
    const res = await axios.put(`${PROD_OPS}/investment/transaction`, body, {
      headers: { 'x-api-key': OPS_KEY, Authorization: token },
    })
    if (res.status === 200) {
      $transactionL(false)
      closeDialog(type)
    } else {
    }
  }

  const handleRedeemSubmit = async (type: string) => {
    if (!validateRedeemDialog()) {
      setDialogSubmit(true)
      const body = {
        accountId: accountInfo.id,
        ...redeemValues,
      }
      const res = await axios.post(`${PROD_OPS}/investment/${type}`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        setDialogSubmit(false)
        closeDialog(type)
        $dialogErrors({})
        if (type === 'redeem') {
          setDialogSubmit(false)
          $redeemdData({
            date: res.data.date,
            principle: res.data.principle,
            interest: res.data.interest,
            total: res.data.total,
          })
          $dialogErrors({})
          openSuccessDialog()
        }
      }
    }
  }
  const handlePartialSubmit = async (type: string) => {
    if (!validatePartialDialog()) {
      setDialogSubmit(true)
      const body = {
        accountId: accountInfo.id,
        ...partialValues,
      }
      const res = await axios.post(`${PROD_OPS}/investment/${type}`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        setDialogSubmit(false)
        closeDialog(type)
        $dialogErrors({})
      }
    }
  }
  const handleTransferSubmit = async (type: string) => {
    if (!validateTransferDialog()) {
      setDialogSubmit(true)
      const body = {
        accountId: accountInfo.id,
        ...transferValues,
      }
      const res = await axios.post(`${PROD_OPS}/investment/${type}`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        setDialogSubmit(false)
        closeDialog(type)
        $dialogErrors({})
      }
    }
  }

  const validateRedeemDialog = () => {
    let newErrors: IValues = dialogErrors
    let haveError: boolean = false

    Object.keys(redeemValues).map((fieldName: string) => {
      if (redeemValues[fieldName] === null || redeemValues[fieldName] === '') {
        newErrors[fieldName] = newErrors[fieldName]
          ? `${newErrors[fieldName]}, Required`
          : 'Required'
      }
    })
    setDialogErrors(newErrors)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== null) {
        haveError = true
      }
    })

    return haveError
  }

  const validatePartialDialog = () => {
    let newErrors: IValues = dialogErrors
    let haveError: boolean = false

    Object.keys(partialValues).map((fieldName: string) => {
      if (
        partialValues[fieldName] === null ||
        partialValues[fieldName] === ''
      ) {
        newErrors[fieldName] = newErrors[fieldName]
          ? `${newErrors[fieldName]}, Required`
          : 'Required'
      }
    })
    setDialogErrors(newErrors)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== null) {
        haveError = true
      }
    })

    return haveError
  }
  const validateTransferDialog = () => {
    let newErrors: IValues = dialogErrors
    let haveError: boolean = false

    Object.keys(transferValues).map((fieldName: string) => {
      if (
        transferValues[fieldName] === null ||
        transferValues[fieldName] === ''
      ) {
        newErrors[fieldName] = newErrors[fieldName]
          ? `${newErrors[fieldName]}, Required`
          : 'Required'
      }
    })
    setDialogErrors(newErrors)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== null) {
        haveError = true
      }
    })

    return haveError
  }

  const closeDialog = (type: string) => {
    $selectedTransaction(DEFAULT_TRANSACTION)
    $redeemValues({})
    $partialValues({})
    $transferValues({})
    $dialogs({ ...dialogs, [type]: false })
  }

  const handleTabs = (event: React.ChangeEvent<{}>, newTab: number) => {
    $tabs(newTab)
  }

  React.useEffect(() => {
    setForm('account')
    $distribution(accountInfo.interestDistribution)
    $aClass(accountInfo.class)
    $dates({
      depositDate: moment(accountInfo.depositDate, 'YYYY-MM-DD'),
      ccoDate: moment(accountInfo.ccoDate, 'YYYY-MM-DD'),
    })
    setValues(
      {
        ...account,
        accountId: accountInfo.id,
        account: {
          ...account.account,
          depositDate: accountInfo.depositDate,
          ccoDate: accountInfo.ccoDate,
          class: accountInfo.class,
        },
      },
      formType
    )
  }, [accountInfo])

  React.useEffect(() => {
    const loadData = async () => {
      let list: ITransaction[] = []
      if (loadedMapping.length !== 0) {
        const res = await axios.get(
          `${PROD_OPS}/investment/info/${loadedMapping[tabs].investmentAccountId}`,
          {
            headers: { 'x-api-key': OPS_KEY, Authorization: token },
          }
        )
        if (res.status === 200) {
          $accountInfo(res.data.account)
          const transactions: ITransaction[] =
            res.data.account.investmentTransactions
          if (transactions) {
            transactions.map((t) => {
              const generate: ITransaction = createData(t)
              list.push(generate)
            })
            $rows(list)
          }
        } else {
        }
      }
    }
    loadData()
  }, [tabs, loadedMapping, dialogs])

  const createData = ({
    id,
    transactionDate,
    type,
    amount,
    referenceNo,
    method,
    notes,
  }: ITransaction) => {
    return { id, transactionDate, type, amount, referenceNo, method, notes }
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues(
      {
        account: {
          ...account.account,
          [e.currentTarget.id]: e.currentTarget.value,
        },
      },
      formType
    )
  }

  const handleDistributionSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $distribution(event.target.value as string)
    setValues(
      { account: { ...account.account, [name]: event.target.value as string } },
      formType
    )
  }

  const handleClassSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $aClass(event.target.value as string)
    setValues(
      { account: { ...account.account, [name]: event.target.value as string } },
      formType
    )
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    let list = dates
    list = { ...list, [name]: dateObj }
    $dates(list)

    setValues(
      {
        info: {
          ...account.account,
          [name]: dateObj !== null ? dateObj.format('YYYY-MM-DD') : '',
        },
      },
      formType
    )
  }

  return (
    <>
      <Box component='div' className={classes.toolbar}>
        <RedeemDialog
          dialogOpen={dialogs.redeem}
          closeDialog={closeDialog}
          cancelDialog={cancelDialog}
          handleDialogText={handleRedeemText}
          handleDialogSubmit={handleRedeemSubmit}
          dialogSubmit={dialogSubmit}
          handleDialogBlur={handleDialogBlur}
          dialogErrors={dialogErrors}
          date={redeemDate}
          handleDate={handleRedeemDate}
        />
        <PartialRedeemDialog
          dialogOpen={dialogs.partialRedeem}
          closeDialog={closeDialog}
          cancelDialog={cancelDialog}
          handleDialogText={handlePartialText}
          handleDialogSubmit={handlePartialSubmit}
          dialogSubmit={dialogSubmit}
          handleDialogBlur={handleDialogBlur}
          dialogErrors={dialogErrors}
          date={partialDate}
          handleDate={handlePartialDate}
        />
        <TransferLPDialog
          dialogOpen={dialogs.transfer}
          closeDialog={closeDialog}
          cancelDialog={cancelDialog}
          handleDialogText={handleTransferText}
          handleDialogSubmit={handleTransferSubmit}
          banks={banks}
          bank={bank}
          handleDialogBank={handleDialogBank}
          investors={investors}
          investorId={investorId}
          handleDialogInvestor={handleDialogInvestor}
          dialogSubmit={dialogSubmit}
          handleDialogBlur={handleDialogBlur}
          dialogErrors={dialogErrors}
          date={transferDate}
          handleDate={handleTransferDate}
          loadedInvestor={loadedInvestor}
        />
        <RedeemSuccessDialog
          dialogOpen={successDialog}
          cancelDialog={closeSuccessDialog}
          redeemedData={redeemedData}
        />
        <Typography style={{ width: '90%' }} variant='body1'>
          Investment Information for{' '}
          {loadedInvestor.investorType === 'individual'
            ? `${loadedInvestor.firstName}`
            : `${loadedInvestor.companyName}`}
        </Typography>
        <div style={{ width: '8%' }}>
          <Button
            classes={{ root: classes.addButton }}
            onClick={() =>
              history.push(`/investors/${loadedInvestor.id}/newAccount`)
            }
            variant='contained'
          >
            {' '}
            <Add /> New Account{' '}
          </Button>
        </div>
      </Box>

      <Tabs
        classes={{
          indicator: classes.indicator,
        }}
        value={tabs}
        onChange={handleTabs}
        aria-label='investments'
      >
        {loadedMapping.map((info: any, idx: number) => (
          <Tab key={idx} label={`Investment ${idx + 1}`} {...a11yProps(idx)} />
        ))}
        {/* <Tab label='chart' {...a11yProps(0)} />
        <Tab label='table' {...a11yProps(1)} /> */}
      </Tabs>
      <AccountTab value={tabs} index={tabs}>
        {loading ? (
          <FixedHeightLoading />
        ) : (
          <>
            <Grid container direction='column' spacing={3}>
              <Grid item xs={12} container justify='flex-end'>
                {/* {edit ? (
                  <Button
                    classes={{ root: classes.submit }}
                    style={{ height: 40 }}
                    variant='outlined'
                    type='submit'
                  >
                    Save Account
                  </Button>
                ) : (
                  <Button
                    disabled={accountInfo.status === 'Closed'}
                    onClick={setEdit}
                    className={classes.editIcon}
                  >
                    <EditOutlined /> Edit
                  </Button>
                )} */}
              </Grid>
              {edit ? (
                <AccountDetail
                  handleTextField={handleTextField}
                  aClass={aClass}
                  handleClassSelect={handleClassSelect}
                  distribution={distribution}
                  handleDistributionSelect={handleDistributionSelect}
                  account={accountInfo}
                  dates={dates}
                  handleDate={handleDate}
                />
              ) : (
                <>
                  <Grid item xs={12} container direction='row' spacing={2}>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Certificate No:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.certificateNo}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Interest Start Date:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.startDate}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Mature Date:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.matureDate}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Redemption Date:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.endDate}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction='row' spacing={2}>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Bank Draft Received Date:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.depositDate}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        CCO Sign off Date:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.ccoDate}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Investment Amount:{' '}
                        <span className={classes.fieldValue}>
                          {formatter.format(accountInfo.investmentAmount)}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Bank Account:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.bankName} {accountInfo.bankAccountNo}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction='row' spacing={2}>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Interest Distribution:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.interestDistribution}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Rate of Return:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.interestRate} %
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Management Fee:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.managementRate} %
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Status:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.status}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction='row' spacing={2}>
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Profit Share:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.psPercentage} %
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Class:{' '}
                        <span className={classes.fieldValue}>
                          {accountInfo.class}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <Divider style={{ marginTop: 24, marginBottom: 24 }} />
              <Grid item xs={12} container direction='row'>
                <Grid item xs={4}>
                  <Typography variant='body1'>Account Transactions:</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  classes={{ root: classes.boxShadow }}
                >
                  <Table className={classes.table} aria-label='user table'>
                    <TableHead classes={{ root: classes.background }}>
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Date
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Type
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Reference No.
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Payment Method
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Note
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.textWhite }}
                          align='center'
                        >
                          Edit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows &&
                        rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              component='th'
                              align='center'
                              scope='row'
                            >
                              {row.transactionDate}
                            </TableCell>
                            <TableCell align='center'>{row.type}</TableCell>
                            <TableCell align='center'>
                              {formatter.format(row.amount)}
                            </TableCell>
                            <TableCell align='center'>
                              {row.referenceNo}
                            </TableCell>
                            <TableCell align='center'>{row.method}</TableCell>
                            <TableCell align='center'>{row.notes}</TableCell>
                            <TableCell align='center'>
                              <EditOutlined
                                className={classes.textNavy}
                                onClick={() => openDialog('transaction', row)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <Dialog
                      classes={{ paper: classes.dialog }}
                      fullWidth
                      open={dialogs.transaction}
                      onClose={() => closeDialog('transaction')}
                      aria-labelledby='transaction-title'
                    >
                      <DialogTitle id='transaction-title'>Edit</DialogTitle>
                      <DialogContent dividers>
                        {transactionL ? (
                          <DialogLoading />
                        ) : (
                          <>
                            <Grid
                              container
                              direction='row'
                              justify='space-around'
                              spacing={1}
                            >
                              <Grid item xs={5} container direction='column'>
                                <Typography variant='body2'>Date:</Typography>
                                <Typography
                                  style={{ color: NAVY }}
                                  variant='body1'
                                >
                                  {selectedTransaction.transactionDate}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={5} container direction='column'>
                                <Typography variant='body2'>Type:</Typography>
                                <Typography
                                  style={{ color: NAVY }}
                                  variant='body1'
                                >
                                  {selectedTransaction.type}
                                </Typography>
                              </Grid>
                              <Grid item xs={5} container direction='column'>
                                <Typography variant='body2'>Amount:</Typography>
                                <TextField
                                  margin='dense'
                                  variant='outlined'
                                  id='amount'
                                  onChange={handleTransactionText}
                                  defaultValue={selectedTransaction.amount}
                                />
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={5} container direction='column'>
                                <Typography variant='body2'>
                                  Reference No.
                                </Typography>
                                <TextField
                                  margin='dense'
                                  variant='outlined'
                                  id='referenceNo'
                                  onChange={handleTransactionText}
                                  defaultValue={selectedTransaction.referenceNo}
                                />
                              </Grid>
                              <Grid item xs={5} container direction='column'>
                                <Typography variant='body2'>
                                  Payment Method
                                </Typography>
                                <FormControl
                                  variant='outlined'
                                  margin='dense'
                                  style={{ minWidth: 195 }}
                                >
                                  <Select
                                    name='method'
                                    id='method-select-outlined'
                                    value={paymentMethod}
                                    onChange={handleTransactionSelect}
                                  >
                                    <MenuItem value=''>
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value='Cheque'>Cheque</MenuItem>
                                    <MenuItem value='Bank Draft'>
                                      Bank Draft
                                    </MenuItem>
                                    <MenuItem value='Bank Transfer'>
                                      Bank Transfer
                                    </MenuItem>
                                    <MenuItem value='Cash'>Cash</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={7} />
                              <Grid item xs={12} container direction='column'>
                                <Typography variant='body2'>Notes</Typography>
                                <TextField
                                  margin='dense'
                                  variant='outlined'
                                  multiline
                                  rows='3'
                                  id='notes'
                                  onChange={handleTransactionText}
                                  defaultValue={
                                    selectedTransaction.notes
                                      ? selectedTransaction.notes
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </DialogContent>
                      <DialogActions style={{ justifyContent: 'space-around' }}>
                        <Button
                          className={classes.dialogCancelButton}
                          onClick={() => cancelDialog('transaction')}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={transactionL}
                          className={classes.dialogSubmitButton}
                          onClick={() => transactionSubmit('transaction')}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} container direction='row'>
                <Grid item xs={2} container justify='center'>
                  <Button
                    disabled={accountInfo.status === 'Closed'}
                    onClick={() => openDialog('redeem')}
                    classes={{ root: classes.button }}
                  >
                    Renew
                  </Button>
                </Grid>
                <Grid item xs={2} container justify='center'>
                  <Button
                    disabled={accountInfo.status === 'Closed'}
                    onClick={() => openDialog('partialRedeem')}
                    classes={{ root: classes.button }}
                    style={{ width: 210 }}
                  >
                    Redeem/Partial Redeem
                  </Button>
                </Grid>
                <Grid item xs={2} container justify='center'>
                  <Button
                    disabled={accountInfo.status === 'Closed'}
                    onClick={() => openDialog('transfer')}
                    classes={{ root: classes.button }}
                  >
                    Transfer LP Units
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </AccountTab>
      <Box component='div' className={classes.buttonField}>
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='flex-end'
          spacing={2}
        >
          <Grid item xs container justify='center'>
            <Button
              fullWidth
              classes={{ root: classes.submit }}
              variant='outlined'
              onClick={handleBack}
            >
              {'< Previous'}
            </Button>
          </Grid>
          <Grid item xs container justify='center'>
            <Button
              fullWidth
              onClick={() => history.push('/investors')}
              classes={{ root: classes.cancel }}
              variant='outlined'
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AccountView
