import React, { useState } from 'react'
import axios from 'axios'

import { useHistory, useParams } from 'react-router-dom'
import { useStyles } from './mortgage.form.style'
import moment, { Moment } from 'moment'

import { IValues } from '../../../utils/types'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import {
  MORTGAGES,
  GUARANTORS,
  COLLATERALS,
  TERMS,
  NEWACCOUNT,
  DEFAULT_NEW_ACCOUNT_FIELDS,
  DEFAULT_GUARANTORS_FIELD,
  DEFAULT_COLLATERALS_FIELD,
  DEFAULT_TERMS_FIELD,
} from '../helper/mortgage.constants'
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Fab,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Add, DeleteForeverOutlined } from '@material-ui/icons'
import { NAVY } from '../../../styles/color'
import { FixedHeightLoading } from '../../../components/loading/loading.component'
import MortgagesFormFields from '../components/mortgages.form.fields'
import GuarantorsFormFields from '../components/guarantors.form.fields'
import CollateralsFormFields from '../components/collaterals.form.fields'
import { ButtonFields } from '../components/button.fields'
import { PayoutContext } from '../../../providers/payout/payout.provider'
import { AuthContext } from '../../../providers/auth/auth.provider'

const MortgageForm = () => {
  const { mortgageId } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [loading, $loading] = useState<boolean>(false)
  const [errors, $errors] = useState<IValues>({})
  const { loaded } = React.useContext(PayoutContext)
  const { token } = React.useContext(AuthContext)

  const [newAccount, $newAccount] = useState<IValues>(
    DEFAULT_NEW_ACCOUNT_FIELDS
  )
  const [mortgages, $mortgages] = useState<IValues[]>([
    {
      mortgageType: null,
      firstName: null,
      lastName: null,
      companyName: null,
      contactName: null,
      email: null,
    },
  ])
  const [terms, $terms] = useState<IValues[]>([
    {
      sequenceNo: 1,
      month: 0,
      minInterest: 0,
      floatInterest: 0,
    },
  ])

  const [guarantors, $guarantors] = useState<IValues[]>([])
  const [collaterals, $collaterals] = useState<IValues[]>([])
  const [sDate, $sDate] = useState<Moment | null>(null)

  React.useEffect(() => {
    if (loaded) {
      $mortgages(loaded.mortgages)
      $guarantors(loaded.guarantors)
      $collaterals(loaded.collaterals)
      $terms(loaded.mortgageTerms)
      let loadedValue = newAccount
      loadedValue = {
        fileNo: loaded.fileNo,
        mortgageType: loaded.mortgageType,
        startDate: loaded.startDate,
        mortgageAmount: loaded.mortgageAmount,
        interestType: loaded.interestType,
        interestAdjustment: loaded.interestAdjustment,
        ltv: loaded.ltv,
        lenderFee: loaded.lenderFee,
        adminFee: loaded.adminFee,
        brokerFee: loaded.brokerFee,
        coBrokerName: loaded.coBrokerName,
        coBrokerFee: loaded.coBrokerFee,
        brokerNotes: loaded.brokerNotes,
        title: loaded.title,
        legalDocs: loaded.legalDocs,
        lenderPackage: loaded.lenderPackage,
      }
      $newAccount(loadedValue)
      $sDate(moment(loaded.startDate, 'YYYY-MM-DD'))
      window.scrollTo(0, 0)
    }
  }, [])

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type: string = event.target.value
    const idx: number = Number(event.target.id.split('#')[1])
    const mortgageType = mortgages[idx].mortgageType
    const values = [...mortgages]

    if (mortgageType === null) {
      values[idx].mortgageType = type
      $mortgages(values)
    } else {
      if (type === 'individual') {
        if (mortgageType === 'entity') {
          values[idx].mortgageType = type
          $mortgages(values)
        } else {
          values[idx].mortgageType = null
          $mortgages(values)
        }
      } else {
        if (mortgageType === 'individual') {
          values[idx].mortgageType = type
          $mortgages(values)
        } else {
          values[idx].mortgageType = null
          $mortgages(values)
        }
      }
    }
  }

  const handleAddFields = (field: string) => {
    switch (field) {
      case MORTGAGES:
        const mortgageField = [...mortgages]
        mortgageField.push({
          mortgageType: null,
          firstName: null,
          lastName: null,
          companyName: null,
          contactName: null,
          email: null,
        })
        $mortgages(mortgageField)
        return
      case GUARANTORS:
        const guarantorField = [...guarantors]
        guarantorField.push(DEFAULT_GUARANTORS_FIELD)
        $guarantors(guarantorField)
        return
      case COLLATERALS:
        const collateralField = [...collaterals]
        collateralField.push(DEFAULT_COLLATERALS_FIELD)
        $collaterals(collateralField)
        return
      case TERMS:
        const termFields = [...terms]
        termFields.push(DEFAULT_TERMS_FIELD)
        $terms(termFields)
        return
      default:
        return
    }
  }

  const handleRemoveFields = (idx: number, field: string) => {
    switch (field) {
      case MORTGAGES:
        const mortgageValues = [...mortgages]
        mortgageValues.splice(idx, 1)
        $mortgages(mortgageValues)
        return
      case GUARANTORS:
        const guarantorValues = [...guarantors]
        guarantorValues.splice(idx, 1)
        $guarantors(guarantorValues)
        return
      case COLLATERALS:
        const collateralValues = [...collaterals]
        collateralValues.splice(idx, 1)
        $collaterals(collateralValues)
        return
      case TERMS:
        const termValues = [...terms]
        termValues.splice(idx, 1)
        $terms(termValues)
        return
      default:
        return
    }
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const field: string = e.target.id.split('#')[0]
    const name: string = e.target.id.split('#')[1]
    const idx: number = Number(e.target.id.split('#')[2])
    const value: string = e.target.value

    switch (field) {
      case NEWACCOUNT:
        let newAccountValues = newAccount
        newAccountValues = { ...newAccountValues, [name]: value }
        $newAccount(newAccountValues)
        return
      case MORTGAGES:
        const mortgageValues = [...mortgages]
        mortgageValues[idx] = { ...mortgageValues[idx], [name]: value }
        $mortgages(mortgageValues)
        return
      case GUARANTORS:
        const guarantorValues = [...guarantors]
        guarantorValues[idx] = { ...guarantorValues[idx], [name]: value }
        $guarantors(guarantorValues)
        return
      case COLLATERALS:
        const collateralValues = [...collaterals]
        collateralValues[idx] = { ...collateralValues[idx], [name]: value }
        $collaterals(collateralValues)
        return
      case TERMS:
        const termValues = [...terms]
        termValues[idx] = { ...termValues[idx], [name]: Number(value) }
        $terms(termValues)
        return
      default:
        return
    }
  }

  const handleSelectField = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = e.target.name as string
    const value = e.target.value as string
    const field = name.split('#')[0]
    const type = name.split('#')[1]
    const idx = Number(name.split('#')[2])

    switch (field) {
      case NEWACCOUNT:
        let newAccountValues = newAccount
        newAccountValues = { ...newAccountValues, [type]: value }
        $newAccount(newAccountValues)
        return
      case COLLATERALS:
        const collateralValues = [...collaterals]
        collateralValues[idx] = { ...collateralValues[idx], [type]: value }
        $collaterals(collateralValues)
        return
      default:
        return
    }
  }

  const handleNewAccountCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name: string = event.target.id
    const checked: boolean = event.target.checked

    let newAccountValues = newAccount
    newAccountValues = { ...newAccountValues, [name]: checked }
    $newAccount(newAccountValues)
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    $sDate(dateObj)

    let newAccountValues = newAccount
    newAccountValues = {
      ...newAccountValues,
      [name]: dateObj?.format('YYYY-MM-DD'),
    }
    $newAccount(newAccountValues)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    $loading(true)
    if (!validate()) {
      try {
        if (mortgageId) {
          const body = {
            accountId: mortgageId,
          }

          const renewAPI = await axios.post(
            `${PROD_OPS}/mortgage/renew`,
            body,
            {
              headers: { 'x-api-key': OPS_KEY, Authorization: token },
            }
          )
        }

        let termValues = terms
        termValues.map((t, idx: number) => {
          termValues[idx].sequenceNo = idx + 1
        })

        const body = {
          operation: 'create',
          newAccount,
          mortgages,
          guarantors,
          collaterals,
          terms: termValues,
        }

        const res = await axios
          .put(`${PROD_OPS}/mortgage/info`, body, {
            headers: { 'x-api-key': OPS_KEY, Authorization: token },
          })
          .then((res) => {
            if (res.status === 201) {
              $loading(false)
              history.push(`/mortgages/${res.data.mortgageAccountId}/view`)
            } else {
            }
          })
      } catch {}
    } else {
      $loading(false)
    }
  }

  const setErrors = (error: IValues) => {
    $errors({ ...errors, ...error })
  }

  const validate = () => {
    let newErrors: IValues = {}
    let haveError: boolean = false

    Object.keys(newAccount).map((fieldName: string) => {
      if (mortgageId) {
        if (
          fieldName !== 'lenderFee' &&
          fieldName !== 'adminFee' &&
          fieldName !== 'interestAdjustment' &&
          fieldName !== 'brokerFee' &&
          fieldName !== 'coBrokerName' &&
          fieldName !== 'coBrokerFee' &&
          fieldName !== 'brokerNotes'
        ) {
          if (
            newAccount[fieldName] === null ||
            newAccount[fieldName] === '' ||
            newAccount[fieldName] === 0
          ) {
            newErrors[fieldName] = 'Required'
          } else {
            newErrors[fieldName] = ''
          }
        }
      } else {
        if (
          fieldName !== 'lenderFee' &&
          fieldName !== 'coBrokerName' &&
          fieldName !== 'coBrokerFee' &&
          fieldName !== 'brokerNotes'
        ) {
          if (
            newAccount[fieldName] === null ||
            newAccount[fieldName] === '' ||
            newAccount[fieldName] === 0
          ) {
            newErrors[fieldName] = 'Required'
          } else {
            newErrors[fieldName] = ''
          }
        }
      }
    })

    mortgages.map((mortgage, idx: number) => {
      Object.keys(mortgage).map((fieldName: string) => {
        if (mortgage.mortgageType === 'individual') {
          if (
            fieldName !== 'firstName' &&
            fieldName !== 'lastName' &&
            fieldName !== 'contactName' &&
            fieldName !== 'email' &&
            fieldName !== 'companyName'
          ) {
            if (mortgage[fieldName] === null || mortgage[fieldName] === '') {
              newErrors[`${fieldName}#${idx}`] = 'Required'
            } else {
              newErrors[`${fieldName}#${idx}`] = ''
            }
          }
        } else {
          if (
            fieldName !== 'contactName' &&
            fieldName !== 'email' &&
            fieldName !== 'firstName' &&
            fieldName !== 'lastName'
          ) {
            if (mortgage[fieldName] === null || mortgage[fieldName] === '') {
              newErrors[`${fieldName}#${idx}`] = 'Required'
            } else {
              newErrors[`${fieldName}#${idx}`] = ''
            }
          }
        }
      })
    })

    terms.map((term, idx: number) => {
      Object.keys(term).map((fieldName: string) => {
        if (newAccount.interestType === 'Variable') {
          if (fieldName !== 'sequenceNo') {
            if (term[fieldName] === null || term[fieldName] === 0) {
              newErrors[`${fieldName}#${idx}`] = 'Required'
            } else {
              newErrors[`${fieldName}#${idx}`] = ''
            }
          }
        } else {
          if (fieldName !== 'floatInterest' && fieldName !== 'sequenceNo') {
            if (term[fieldName] === null || term[fieldName] === 0) {
              newErrors[`${fieldName}#${idx}`] = 'Required'
            } else {
              newErrors[`${fieldName}#${idx}`] = ''
            }
          }
        }
      })
    })

    guarantors.length > 0 &&
      guarantors.map((guarantor, idx: number) => {
        if (guarantor.name === null || guarantor.name === '') {
          newErrors[`name#${idx}`] = 'Required'
        } else {
          newErrors[`name#${idx}`] = ''
        }
      })

    collaterals.length > 0 &&
      collaterals.map((collateral, idx: number) => {
        if (collateral.type === null || collateral.type === '') {
          newErrors[`type#${idx}`] = 'Required'
        } else {
          newErrors[`type#${idx}`] = ''
        }
      })

    setErrors(newErrors)
    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== '') {
        haveError = true
      }
    })
    return haveError
  }

  return loading ? (
    <FixedHeightLoading />
  ) : (
    <Box component='div' className={classes.root}>
      <Box component='div' className={classes.toolbar}>
        Add New Mortgage Account
      </Box>
      <form className={classes.form} onSubmit={handleSubmit} noValidate={true}>
        <Grid container spacing={2} direction='column'>
          <Grid item xs={12} container direction='row'>
            <Grid item xs={2} container direction='column'>
              <Typography variant='body2'>
                File No. <span className={classes.red}>*</span>
              </Typography>
              <TextField
                margin='dense'
                variant='outlined'
                defaultValue={loaded ? loaded.fileNo : newAccount.fileNo}
                onChange={handleTextField}
                id={`${NEWACCOUNT}#fileNo`}
                error={!!errors.fileNo}
                helperText={errors.fileNo}
              />
            </Grid>
          </Grid>
          <MortgagesFormFields
            mortgages={mortgages}
            handleCheckbox={handleCheckbox}
            handleTextField={handleTextField}
            handleAddFields={handleAddFields}
            handleRemoveFields={handleRemoveFields}
            errors={errors}
          />
          <GuarantorsFormFields
            guarantors={guarantors}
            handleTextField={handleTextField}
            handleAddFields={handleAddFields}
            handleRemoveFields={handleRemoveFields}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} container direction='row' spacing={2}>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Start Date <span className={classes.red}>*</span>
            </Typography>
            <DatePicker
              clearable
              format='YYYY-MM-DD'
              value={sDate}
              onChange={(date) => handleDate(date, 'startDate')}
              inputVariant='outlined'
              margin='dense'
              error={!!errors.startDate}
              helperText={errors.startDate}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Mortgage Amount <span className={classes.red}>*</span>
            </Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              defaultValue={loaded ? loaded.mortgageAmount : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#mortgageAmount`}
              error={!!errors.mortgageAmount}
              helperText={errors.mortgageAmount}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              LTV % <span className={classes.red}>*</span>
            </Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              defaultValue={loaded ? loaded.ltv : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#ltv`}
              error={!!errors.ltv}
              helperText={errors.ltv}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>Lender Fee</Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              defaultValue={loaded ? loaded.lenderFee : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#lenderFee`}
              error={!!errors.lenderFee}
              helperText={errors.lenderFee}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Administration fee per Property{' '}
              {mortgageId ? '' : <span className={classes.red}>*</span>}
            </Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              defaultValue={loaded ? loaded.adminFee : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#adminFee`}
              error={!!errors.adminFee}
              helperText={errors.adminFee}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Mortgage Type <span className={classes.red}>*</span>
            </Typography>
            <FormControl
              variant='outlined'
              margin='dense'
              error={!!errors.mortgageType}
            >
              <Select
                name={`${NEWACCOUNT}#mortgageType`}
                value={
                  newAccount.mortgageType === null
                    ? ''
                    : newAccount.mortgageType
                }
                onChange={handleSelectField}
              >
                <MenuItem value=''>{<em>None</em>}</MenuItem>
                <MenuItem value='1st'>1st</MenuItem>
                <MenuItem value='2nd'>2nd</MenuItem>
                <MenuItem value='3rd'>3rd</MenuItem>
                <MenuItem value='4th'>4th</MenuItem>
              </Select>
              <FormHelperText>{errors.mortgageType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Interest Adjustment{' '}
              {mortgageId ? '' : <span className={classes.red}>*</span>}
            </Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              defaultValue={loaded ? loaded.interestAdjustment : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#interestAdjustment`}
              error={!!errors.interestAdjustment}
              helperText={errors.interestAdjustment}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: NAVY }}
                  checked={newAccount.title}
                  onChange={handleNewAccountCheckBox}
                  size='small'
                  value='title'
                  id='title'
                />
              }
              label='Title Certificate'
              labelPlacement='start'
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: NAVY }}
                  checked={newAccount.legalDocs}
                  onChange={handleNewAccountCheckBox}
                  size='small'
                  value='legalDocs'
                  id='legalDocs'
                />
              }
              label='Legal Docs'
              labelPlacement='start'
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: NAVY }}
                  checked={newAccount.lenderPackage}
                  onChange={handleNewAccountCheckBox}
                  size='small'
                  value='lenderPackage'
                  id='lenderPackage'
                />
              }
              label='Lender Package'
              labelPlacement='start'
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.marginTop40}>
          <Typography variant='body1'>Broker</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.marginTop24}
          container
          direction='row'
          spacing={2}
        >
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Total Brokerage Fee{' '}
              {mortgageId ? '' : <span className={classes.red}>*</span>}
            </Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              defaultValue={loaded ? loaded?.brokerFee : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#brokerFee`}
              error={!!errors.brokerFee}
              helperText={errors.brokerFee}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>Co-Broker Name</Typography>
            <TextField
              margin='dense'
              variant='outlined'
              defaultValue={
                loaded ? loaded.coBrokerName : newAccount.coBrokerName
              }
              onChange={handleTextField}
              id={`${NEWACCOUNT}#coBrokerName`}
            />
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>Co-Broker Fee</Typography>
            <TextField
              margin='dense'
              variant='outlined'
              type='number'
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              defaultValue={loaded ? loaded.coBrokerFee : ''}
              onChange={handleTextField}
              id={`${NEWACCOUNT}#coBrokerFee`}
            />
          </Grid>
          <Grid item xs={6} container direction='column'>
            <Typography variant='body2'>Notes</Typography>
            <TextField
              margin='dense'
              variant='outlined'
              defaultValue={
                loaded ? loaded.brokerNotes : newAccount.brokerNotes
              }
              onChange={handleTextField}
              id={`${NEWACCOUNT}#brokerNotes`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.marginTop40}>
          <Typography variant='body1'>
            Interest Rate{' '}
            <span className={classes.gray}>
              (You can have multiple terms for the same account)
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.marginTop24}
          container
          direction='row'
          spacing={2}
        >
          <Grid item xs={3} container direction='column'>
            <Typography variant='body2'>
              Interest Type <span className={classes.red}>*</span>
            </Typography>
            <FormControl
              variant='outlined'
              margin='dense'
              error={!!errors.interestType}
            >
              <Select
                name={`${NEWACCOUNT}#interestType`}
                value={
                  newAccount.interestType === null
                    ? ''
                    : newAccount.interestType
                }
                onChange={handleSelectField}
              >
                <MenuItem value=''>{<em>None</em>}</MenuItem>
                <MenuItem value='Fixed'>Fixed</MenuItem>
                <MenuItem value='Variable'>Variable</MenuItem>
              </Select>
              <FormHelperText>{errors.interestType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={9} />
          {terms.map((field, idx: number) => (
            <React.Fragment key={`${field}-${idx}`}>
              <Grid item xs={3} container direction='column'>
                <Typography variant='body2'>
                  Term <span className={classes.red}>*</span>
                </Typography>
                <TextField
                  margin='dense'
                  variant='outlined'
                  onChange={handleTextField}
                  type='number'
                  inputProps={{
                    min: 0,
                  }}
                  value={terms[idx].month === 0 ? '' : terms[idx].month}
                  id={`${TERMS}#month#${idx}`}
                  error={!!errors[`month#${idx}`]}
                  helperText={errors[`month#${idx}`]}
                />
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Typography variant='body2'>
                  Interest Rate % <span className={classes.red}>*</span>
                </Typography>
                <TextField
                  margin='dense'
                  variant='outlined'
                  onChange={handleTextField}
                  type='number'
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>%</InputAdornment>
                    ),
                  }}
                  value={
                    terms[idx].minInterest === 0 ? '' : terms[idx].minInterest
                  }
                  id={`${TERMS}#minInterest#${idx}`}
                  error={!!errors[`minInterest#${idx}`]}
                  helperText={errors[`minInterest#${idx}`]}
                />
              </Grid>
              {newAccount.interestType === 'Variable' ? (
                <>
                  <Grid item xs={3} container direction='column'>
                    <Typography variant='body2'>
                      Variable {idx + 1} <span className={classes.red}>*</span>
                    </Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>%</InputAdornment>
                        ),
                      }}
                      value={
                        terms[idx].floatInterest === 0
                          ? ''
                          : terms[idx].floatInterest
                      }
                      id={`${TERMS}#floatInterest#${idx}`}
                      error={!!errors[`floatInterest#${idx}`]}
                      helperText={errors[`floatInterest#${idx}`]}
                    />
                  </Grid>
                  <Grid item xs={2} />
                </>
              ) : (
                <Grid item xs={5} />
              )}
              {idx === 0 ? (
                <Grid item xs={1} />
              ) : (
                <>
                  <Grid item xs={1} container direction='column'>
                    <Grid item xs />
                    <Grid item xs>
                      <Fab
                        classes={{ root: classes.addButton }}
                        onClick={() => handleRemoveFields(idx, TERMS)}
                        variant='extended'
                      >
                        <DeleteForeverOutlined className={classes.red} />
                        <span style={{ marginLeft: 4 }} className={classes.red}>
                          Remove
                        </span>
                      </Fab>
                    </Grid>
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Fab
              classes={{ root: classes.addButton }}
              onClick={() => handleAddFields(TERMS)}
              variant='extended'
            >
              <Add classes={{ root: classes.addIcon }} />
              Add More Terms
            </Fab>
          </Grid>
        </Grid>

        <CollateralsFormFields
          collaterals={collaterals}
          handleTextField={handleTextField}
          handleSelectField={handleSelectField}
          handleAddFields={handleAddFields}
          handleRemoveFields={handleRemoveFields}
          errors={errors}
        />
        <ButtonFields />
      </form>
    </Box>
  )
}

export default MortgageForm
