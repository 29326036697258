import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  NAVY,
  WHITE,
  BORDER_GRAY,
  APPBAR_GRAY,
  BLACK,
  GRAY_600,
  LIGHT_GRAY
} from '../../../styles/color'
import Toolbar from '../../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '93%'
    },
    toolbarBg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 4),
      ...theme.mixins.toolbar
    },
    tableContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(4)
    },
    spaceAround: {
      justifyContent: 'space-around'
    },
    indicator: {
      backgroundColor: NAVY
    },
    paper: {
      background: '#FFFFFF 0% 0% no-repeat',
      border: `1px solid ${BORDER_GRAY}`,
      marginBottom: 16,
      height: '35%',
      width: '95%',
      margin: 'auto'
    },
    appbar: {
      backgroundColor: APPBAR_GRAY,
      color: WHITE,
      boxShadow: 'unset'
    },
    selected: {
      color: BLACK
    },
    capitalize: {
      textTransform: 'capitalize'
    },
    background: {
      backgroundColor: GRAY_600
    },
    table: {
      maxWidth: '100%',
      tableLayout: 'fixed'
    },
    textWhite: {
      color: WHITE
    },
    textNavy: {
      color: NAVY
    },
    boxShadow: {
      boxShadow: 'unset',
      borderRadius: 'unset'
    },
    underline: {
      '&&&:before': {
        borderBottom: 'none'
      },
      '&&:after': {
        borderBottom: 'none'
      }
    },
    buttonField: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.mixins.toolbar
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    viewCancel: {
      backgroundColor: WHITE,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: WHITE
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: NAVY
      }
    }
  })
)
