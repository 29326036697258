import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import {
  Theme,
  createStyles,
  makeStyles,
  withStyles
} from '@material-ui/core/styles'
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'

import { DRAWER_WIDTH } from '../../styles/size'
import { NAVY, WHITE, SKY_BLUE } from '../../styles/color'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    },
    drawerPaper: {
      borderRight: 'unset',
      width: DRAWER_WIDTH
    },
    drawerTitle: {
      background: NAVY,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: WHITE,
      fontWeight: 900,
      fontSize: 'medium',
      cursor: 'pointer',
      ...theme.mixins.toolbar
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3)
    }
  })
)

const StyledListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: SKY_BLUE
    }
  },
  selected: {}
})(ListItem)

const Sidebar = () => {
  const history = useHistory()
  const { pathname } = history.location
  const classes = useStyles()
  const [selectedIndex, $selectedIndex] = useState(0)

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    const path = `/${event.currentTarget
      .getElementsByTagName('span')[0]
      .innerHTML.toLowerCase()}`

    history.push(path === '/home' || path === '/dashboard' ? '/' : path)

    $selectedIndex(index)
  }

  React.useEffect(() => {
    switch (pathname) {
      case '/':
        return $selectedIndex(0)
      case '/investors':
        return $selectedIndex(1)
      case '/mortgages':
        return $selectedIndex(2)
      default:
        return $selectedIndex(Infinity)
    }
  }, [pathname])

  return (
    <>
      <Drawer
        variant='permanent'
        anchor='left'
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div onClick={() => history.push('/')} className={classes.drawerTitle}>
          VANMORTGAGE
        </div>
        <List
          style={{ padding: 0 }}
          component='nav'
          aria-label='home investors mortgages'
        >
          <StyledListItem
            className={classes.toolbar}
            button
            selected={selectedIndex === 0}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleListItemClick(event, 0)
            }
          >
            <ListItemText style={{ textAlign: 'center' }} primary='Dashboard' />
          </StyledListItem>
          <StyledListItem
            className={classes.toolbar}
            button
            selected={selectedIndex === 1}
            onClick={event => handleListItemClick(event, 1)}
          >
            <ListItemText style={{ textAlign: 'center' }} primary='Investors' />
          </StyledListItem>
          <StyledListItem
            className={classes.toolbar}
            button
            selected={selectedIndex === 2}
            onClick={event => handleListItemClick(event, 2)}
          >
            <ListItemText style={{ textAlign: 'center' }} primary='Mortgages' />
          </StyledListItem>
        </List>
      </Drawer>
    </>
  )
}

export default Sidebar
