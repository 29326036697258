import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  NAVY,
  WHITE,
  BORDER_GRAY,
  APPBAR_GRAY,
  BLACK,
  GRAY_400
} from '../../styles/color'
import Toolbar from '../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%'
    },
    toolbarBg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      ...theme.mixins.toolbar
    },
    spaceAround: {
      justifyContent: 'space-around'
    },
    tableHead: {
      backgroundColor: '#dcdcdc'
    },
    indicator: {
      backgroundColor: NAVY
    },
    paper: {
      background: '#FFFFFF 0% 0% no-repeat',
      border: `1px solid ${BORDER_GRAY}`,
      marginBottom: 16,
      height: '35%',
      width: '95%',
      margin: 'auto'
    },
    appbar: {
      backgroundColor: '#9696968a',
      color: BLACK,
      boxShadow: 'unset'
    },
    selected: {
      color: NAVY
    },
    capitalize: {
      textTransform: 'capitalize',
      width: '20%'
    }
  })
)
