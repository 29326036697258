import React, { Fragment } from 'react'
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Fab,
} from '@material-ui/core'
import { NAVY } from '../../../styles/color'
import { Add, DeleteForeverOutlined } from '@material-ui/icons'
import { MORTGAGES } from '../helper/mortgage.constants'
import { useStyles } from './mortgages.form.fields.style'
import { IValues } from '../../../utils/types'

const MortgagesFormFields = ({
  mortgages,
  handleCheckbox,
  handleTextField,
  handleAddFields,
  handleRemoveFields,
  errors,
}: {
  mortgages: IValues[]
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleTextField: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAddFields: (field: string) => void
  handleRemoveFields: (idx: number, field: string) => void
  errors: IValues
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12} className={classes.marginTop40}>
        <Typography variant='body1'>
          Mortgagor <span className={classes.red}>*</span>{' '}
          <span className={classes.gray}>
            (You can have multiple borrowers fo the same account)
          </span>
        </Typography>
      </Grid>
      {mortgages.map((field, idx: number) => (
        <Fragment key={`${field}-${idx}`}>
          <Grid item xs={12} container direction='row' alignItems='center'>
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: NAVY }}
                    checked={mortgages[idx].mortgageType === 'individual'}
                    onChange={handleCheckbox}
                    size='small'
                    value='individual'
                    id={`individual#${idx}`}
                  />
                }
                label='Individual'
                labelPlacement='end'
              />
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: NAVY }}
                    checked={mortgages[idx].mortgageType === 'entity'}
                    onChange={handleCheckbox}
                    size='small'
                    value='entity'
                    id={`entity#${idx}`}
                  />
                }
                label='Entity'
                labelPlacement='end'
              />
            </Grid>
            {mortgages[idx].mortgageType === null ? (
              <Grid className={classes.red} item xs={4}>
                (Please Choose the Mortgage Type First)
              </Grid>
            ) : (
              <Grid item xs={4} />
            )}
            {idx === 0 ? (
              <Grid item xs={6} />
            ) : (
              <>
                <Grid item xs={5} />
                <Grid item xs={1} container direction='column'>
                  <Grid item xs />
                  <Grid item xs>
                    <Fab
                      classes={{ root: classes.addButton }}
                      onClick={() => handleRemoveFields(idx, MORTGAGES)}
                      variant='extended'
                    >
                      <DeleteForeverOutlined className={classes.red} />
                      <span style={{ marginLeft: 4 }} className={classes.red}>
                        Remove
                      </span>
                    </Fab>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {mortgages[idx].mortgageType === null ? (
            ''
          ) : (
            <Grid item xs={12} container direction='row' spacing={2}>
              {mortgages[idx].mortgageType === 'individual' ? (
                <>
                  <Grid item xs={2} container direction='column'>
                    <Typography variant='body2'>Name</Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].firstName === null
                          ? ''
                          : mortgages[idx].firstName
                      }
                      id={`${MORTGAGES}#firstName#${idx}`}
                      error={!!errors[`firstName#${idx}`]}
                      helperText={errors[`firstName#${idx}`]}
                    />
                  </Grid>
                  <Grid item xs={2} container direction='column'>
                    <Typography variant='body2'>Name (Optional)</Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].lastName === null
                          ? ''
                          : mortgages[idx].lastName
                      }
                      id={`${MORTGAGES}#lastName#${idx}`}
                      error={!!errors[`lastName#${idx}`]}
                      helperText={errors[`lastName#${idx}`]}
                    />
                  </Grid>
                  <Grid item xs={3} container direction='column'>
                    <Typography variant='body2'>Contact Name</Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].contactName === null
                          ? ''
                          : mortgages[idx].contactName
                      }
                      id={`${MORTGAGES}#contactName#${idx}`}
                    />
                  </Grid>
                  <Grid item xs={3} container direction='column'>
                    <Typography variant='body2'>Email</Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      type='email'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].email === null
                          ? ''
                          : mortgages[idx].email
                      }
                      id={`${MORTGAGES}#email#${idx}`}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={4} container direction='column'>
                    <Typography variant='body2'>
                      Company <span className={classes.red}>*</span>
                    </Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].companyName === null
                          ? ''
                          : mortgages[idx].companyName
                      }
                      id={`${MORTGAGES}#companyName#${idx}`}
                      error={!!errors[`companyName#${idx}`]}
                      helperText={errors[`companyName#${idx}`]}
                    />
                  </Grid>
                  <Grid item xs={3} container direction='column'>
                    <Typography variant='body2'>Contact</Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].contactName === null
                          ? ''
                          : mortgages[idx].contactName
                      }
                      id={`${MORTGAGES}#contactName#${idx}`}
                    />
                  </Grid>
                  <Grid item xs={3} container direction='column'>
                    <Typography variant='body2'>Email</Typography>
                    <TextField
                      margin='dense'
                      variant='outlined'
                      type='email'
                      onChange={handleTextField}
                      value={
                        mortgages[idx].email === null
                          ? ''
                          : mortgages[idx].email
                      }
                      id={`${MORTGAGES}#email#${idx}`}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Fab
          classes={{ root: classes.addButton }}
          onClick={() => handleAddFields(MORTGAGES)}
          variant='extended'
        >
          <Add classes={{ root: classes.addIcon }} />
          Add Other Mortgagors
        </Fab>
      </Grid>
    </>
  )
}

export default MortgagesFormFields
