import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useStyles } from './payout.form.style'
import { ErrorPage } from '../../../components/error/error.component'
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Grid,
  Button
} from '@material-ui/core'
import { PayoutTableRow, PayoutTextField } from '../components/table.component'
import { formatter } from '../../../utils/format'
import moment from 'moment'
import { IValues } from '../../../utils/types'
import { PayoutContext } from '../../../providers/payout/payout.provider'

const MortgagePayoutView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { payout, resetPayout } = useContext(PayoutContext)

  const total: number =
    Number(payout.summary[1]) +
    Number(payout.summary[3]) +
    Number(payout.summary[5]) +
    Number(payout.summary[7]) +
    Number(payout.summary[9]) +
    Number(payout.summary[11]) +
    Number(payout.summary[13]) +
    Number(payout.summary[15]) +
    Number(payout.summary[17]) +
    payout.amount

  const close = () => {
    resetPayout()
    history.push(`/mortgages`)
  }

  return false ? (
    <ErrorPage />
  ) : (
    <Box component='div' className={classes.root}>
      <Box component='div' className={classes.toolbarBg}>
        Mortgage Payout Statement
      </Box>
      <Box
        component='div'
        style={{ paddingTop: 24 }}
        className={classes.toolbar}
      >
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12} container direction='row'>
            <Grid item xs={1}>
              <Typography variant='body1'>File No:</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='body1'>{payout.fileNo}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction='row'>
            <Grid item xs={1}>
              <Typography variant='body1'>Date:</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='body1'>
                {moment().format('MMMM Do, YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction='row'>
            <Grid item xs={1}>
              <Typography variant='body1'>Lender:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1'>
                Vanmortgage Capital GP Corporation
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction='row'>
            <Grid item xs={1}>
              <Typography variant='body1'>Borrower:</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='body1'>{payout.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box component='div' className={classes.tableContainer}>
        <TableContainer component={Paper} classes={{ root: classes.boxShadow }}>
          <Table
            className={classes.table}
            size='small'
            aria-label='payout-table'
          >
            <TableHead>
              <TableRow>
                <TableCell align='center'>Balances as of</TableCell>
                <TableCell align='center'>
                  {payout.date !== null
                    ? moment(payout.date, 'YYYY-MM-DD').format('MMMM Do, YYYY')
                    : ''}
                </TableCell>
                <TableCell align='center'>
                  {formatter.format(payout.amount)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <PayoutTableRow style={{ height: 60 }}>
                <TableCell align='center'>Items</TableCell>
                <TableCell align='center'>Notes</TableCell>
                <TableCell align='center'>Amount</TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>
                  3 Month Interest Penalty (if applicable)
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[0] !== 'null' ? payout.summary[0] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[1]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>Uncashed Payments to</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[2] !== 'null' ? payout.summary[2] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[3]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>Per Diem</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[4] !== 'null' ? payout.summary[4] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[5]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>NSF Fees</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[6] !== 'null' ? payout.summary[6] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[7]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>
                  Interests Accrued Based on Prime Rate Increase
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[8] !== 'null' ? payout.summary[8] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[9]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>Discharge Fee</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[10] !== 'null' ? payout.summary[10] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[11]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>Misc. Items</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[12] !== 'null' ? payout.summary[12] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[13]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>Lender Solicitor Costs</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[14] !== 'null' ? payout.summary[14] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>TBD</Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow>
                <TableCell align='center'>Others</TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {payout.summary[16] !== 'null' ? payout.summary[16] : ''}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body2'>
                    {formatter.format(Number(payout.summary[17]))}
                  </Typography>
                </TableCell>
              </PayoutTableRow>
              <PayoutTableRow style={{ height: 60 }}>
                <TableCell align='center'>Total Payout Balance as of</TableCell>
                <TableCell align='center'>
                  {payout.date !== null
                    ? moment(payout.date, 'YYYY-MM-DD').format('MMMM Do, YYYY')
                    : ''}
                </TableCell>
                <TableCell align='center'>{formatter.format(total)}</TableCell>
              </PayoutTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          component='div'
          style={{ marginTop: 24 }}
          className={classes.buttonField}
        >
          <Grid
            container
            direction='row'
            justify='space-evenly'
            alignItems='flex-end'
            spacing={2}
          >
            <Grid item xs={10} />

            <Grid item xs={1} container justify='center'>
              <Button
                fullWidth
                classes={{ root: classes.viewCancel }}
                variant='outlined'
                onClick={close}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default MortgagePayoutView
