import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '../../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%'
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    stepper: {
      width: '70%',
      cursor: 'pointer',
      background: 'transparent'
    },
    transparent: {
      borderColor: 'transparent'
    }
  })
)
