import React, { Fragment } from 'react'
import {
  Box,
  Paper,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { TabPanelProps } from '../helper/mortgage.interface'

import { formatter } from '../../../utils/format'
import { useStyles } from './tab.panels.style'
import { PayoutTableRow } from './table.component'

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`mortgage-tab-${index}`}
      aria-labelledby={`mortgage-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

export const a11yProps = (index: any) => {
  return {
    id: `mortgage-tab-${index}`,
    'aria-controls': `mortgage-tab-${index}`,
  }
}

export const MortgageTabs = ({
  tab,
  tabChange,
  mortgageLoaded,
}: {
  tab: number
  tabChange: (event: React.ChangeEvent<{}>, newValue: number) => void
  mortgageLoaded: any
}) => {
  const classes = useStyles()

  return (
    <>
      <Paper style={{ marginTop: 24 }} className={classes.paper}>
        <AppBar classes={{ root: classes.appbar }} position='static'>
          <Tabs
            centered
            classes={{
              centered: classes.spaceAround,
              indicator: classes.indicator,
            }}
            value={tab}
            onChange={tabChange}
            aria-label='mortgage-tabs'
          >
            <Tab
              classes={{
                root: classes.capitalize,
                selected: classes.selected,
              }}
              label='Mortgage Terms'
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                root: classes.capitalize,
                selected: classes.selected,
              }}
              label='Mortgagors'
              {...a11yProps(1)}
            />
            <Tab
              classes={{
                root: classes.capitalize,
                selected: classes.selected,
              }}
              label='Guaranters'
              {...a11yProps(2)}
            />
            <Tab
              classes={{
                root: classes.capitalize,
                selected: classes.selected,
              }}
              label='Collaterals'
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <TableContainer
            component={Paper}
            classes={{ root: classes.boxShadow }}
          >
            <Table style={{ maxHeight: '100%' }} size='small'>
              <TableHead classes={{ root: classes.background }}>
                <TableRow>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Sequence No
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Terms (month)
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Interest Rate (%)
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Variable (%)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mortgageLoaded.mortgageTerms &&
                  mortgageLoaded.mortgageTerms.map((term: any, idx: number) => (
                    <PayoutTableRow key={idx}>
                      <TableCell align='center'>{term.sequenceNo}</TableCell>
                      <TableCell align='center'>{term.month}</TableCell>
                      <TableCell align='center'>{term.minInterest} %</TableCell>
                      <TableCell align='center'>
                        {term.floatInterest === 0
                          ? ''
                          : `${term.floatInterest} %`}
                      </TableCell>
                    </PayoutTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <TableContainer
            component={Paper}
            classes={{ root: classes.boxShadow }}
          >
            <Table style={{ maxHeight: '100%' }} size='small'>
              <TableHead classes={{ root: classes.background }}>
                <TableRow>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Name
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Company
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Contact
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mortgageLoaded.mortgages &&
                  mortgageLoaded.mortgages.map((mortgage: any, idx: number) => (
                    <PayoutTableRow key={idx}>
                      <TableCell align='center'>{`${mortgage.firstName}`}</TableCell>
                      <TableCell align='center'>
                        {mortgage.companyName}
                      </TableCell>
                      <TableCell align='center'>
                        {mortgage.contactName}
                      </TableCell>
                      <TableCell align='center'>{mortgage.email}</TableCell>
                    </PayoutTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <TableContainer
            component={Paper}
            classes={{ root: classes.boxShadow }}
          >
            <Table style={{ maxHeight: '100%' }} size='small'>
              <TableHead classes={{ root: classes.background }}>
                <TableRow>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Name
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Address
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    City
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Province
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Postal Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mortgageLoaded.guarantors &&
                  mortgageLoaded.guarantors.map(
                    (guarantor: any, idx: number) => (
                      <PayoutTableRow key={idx}>
                        <TableCell align='center'>{guarantor.name}</TableCell>
                        <TableCell align='center'>
                          {guarantor.address}
                        </TableCell>
                        <TableCell align='center'>{guarantor.city}</TableCell>
                        <TableCell align='center'>
                          {guarantor.province}
                        </TableCell>
                        <TableCell align='center'>
                          {guarantor.postCode}
                        </TableCell>
                      </PayoutTableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <TableContainer
            component={Paper}
            classes={{ root: classes.boxShadow }}
          >
            <Table style={{ maxHeight: '100%' }} size='small'>
              <TableHead classes={{ root: classes.background }}>
                <TableRow>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Type
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    City
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Province
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Age
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Value
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Value Type
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Appraisal Agency
                  </TableCell>
                  <TableCell classes={{ root: classes.white }} align='center'>
                    Notes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mortgageLoaded.collaterals &&
                  mortgageLoaded.collaterals.map(
                    (collateral: any, idx: number) => (
                      <PayoutTableRow key={idx}>
                        <TableCell align='center'>{collateral.type}</TableCell>
                        <TableCell align='center'>{collateral.city}</TableCell>
                        <TableCell align='center'>
                          {collateral.province}
                        </TableCell>
                        <TableCell align='center'>{collateral.age}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(collateral.value)}
                        </TableCell>
                        <TableCell align='center'>
                          {collateral.valueType}
                        </TableCell>
                        <TableCell align='center'>
                          {collateral.appraisalCompany}
                        </TableCell>
                        <TableCell align='center'>{collateral.notes}</TableCell>
                      </PayoutTableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Paper>
    </>
  )
}
