import * as React from 'react'
import { ErrorOutlineOutlined } from '@material-ui/icons'
import { Box, Typography, Link } from '@material-ui/core'
import { useStyles } from './error.component.style'
import { useHistory } from 'react-router-dom'

export const ErrorPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const refresh = () => {
    window.location.reload()
  }
  return (
    <Box component='div' className={classes.root}>
      <ErrorOutlineOutlined className={classes.icon} />
      <Typography variant='h5' className={classes.title}>
        Unable to Load the Data
      </Typography>
      <Typography variant='body1' className={classes.text}>
        This happens if you are not connected to the internet or the data are
        not found
      </Typography>
      <Typography variant='body2' className={classes.text}>
        <span onClick={refresh} className={classes.link}>
          Click Here
        </span>{' '}
        to refresh the page or to the{' '}
        <Link href='/' className={classes.link}>
          Dashboard
        </Link>
      </Typography>
    </Box>
  )
}
