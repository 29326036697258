import * as React from 'react'
import { IFieldProps } from './user.fields'
import {
  UserDialogContent,
  UserDialogActions,
  UserDialogCancelButton,
  UserDialogSubmitButton
} from '../user.style'
import axios from 'axios'
import { AUTH_KEY } from '../../../utils/constants'
import { AuthContext } from '../../../providers/auth/auth.provider'

export interface IFields {
  [key: string]: IFieldProps
}

interface IFormProps {
  type: string
  action: string
  fields: IFields
  render: () => React.ReactNode
  cancelDialog: (type: string) => void
  closeDialog: (type: string) => void
}

export interface IFormValues {
  [key: string]: any
}

export interface IFormErrors {
  [key: string]: string
}

export interface IFormState {
  values: IFormValues
  errors: IFormErrors
  submitSuccess?: boolean
}

export interface IFormContext extends IFormState {
  setValues: (values: IFormValues) => void
  validate: (fieldName: string) => void
}

export const FormContext = React.createContext<IFormContext | undefined>(
  undefined
)

export const required = (values: IFormValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === ''
    ? `${fieldName} is required`
    : ''

export const match = (values: IFormValues, fieldName: string): string =>
  values[fieldName] && values[fieldName] !== values['password']
    ? 'Password should be same'
    : ''

export class Form extends React.Component<IFormProps, IFormState> {
  constructor(props: IFormProps) {
    super(props)

    const values: IFormValues = {}
    const errors: IFormErrors = {}
    this.state = {
      errors,
      values
    }
  }

  private setValues = (values: IFormValues) => {
    this.setState({ values: { ...this.state.values, ...values } })
  }

  private haveErrors(errors: IFormErrors) {
    let haveError: boolean = false
    Object.keys(errors).map(key => {
      if (errors[key].length > 0) {
        haveError = true
      }
    })
    return haveError
  }

  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    if (this.validateForm()) {
      const submitSuccess: boolean = await this.submitForm()
      this.setState({ submitSuccess })
    }
  }

  private validate = (fieldName: string): string => {
    let newError: string = ''

    if (
      this.props.fields[fieldName] &&
      this.props.fields[fieldName].validation
    ) {
      newError = this.props.fields[fieldName].validation!.rule(
        this.state.values,
        fieldName,
        this.props.fields[fieldName].validation!.args
      )
    }

    this.state.errors[fieldName] = newError
    this.setState({
      errors: { ...this.state.errors, [fieldName]: newError }
    })

    return newError
  }

  private validateForm(): boolean {
    const errors: IFormErrors = {}
    Object.keys(this.props.fields).map((fieldName: string) => {
      errors[fieldName] = this.validate(fieldName)
    })
    this.setState({ errors })
    return !this.haveErrors(errors)
  }

  private async submitForm(): Promise<boolean> {
    const { token } = React.useContext(AuthContext)
    const body = {
      operation: this.props.type,
      ...this.state.values
    }
    try {
      const res = await axios.put(this.props.action, body, {
        headers: { 'x-api-key': AUTH_KEY, Authorization: token }
      })
      if (res.status === 400) {
        /* Map the validation errors to IErrors */
        let resBody: any
        resBody = await res
        const errors: IFormErrors = {}
        Object.keys(resBody).map((key: string) => {
          // For ASP.NET core, the field names are in title case - so convert to camel case
          const fieldName = key.charAt(0).toLowerCase() + key.substring(1)
          errors[fieldName] = resBody[key]
        })
        this.setState({ errors })
      }

      if (res.status === 201) {
        this.props.closeDialog(this.props.type)
        return true
      } else {
        throw 'Please Review the form'
      }
    } catch (ex) {
      return false
    }
  }

  public render() {
    const { submitSuccess, errors } = this.state
    const context: IFormContext = {
      ...this.state,
      setValues: this.setValues,
      validate: this.validate
    }

    return (
      <>
        <FormContext.Provider value={context}>
          <form
            style={{
              height: 'calc(100% - 116px)'
            }}
            onSubmit={this.handleSubmit}
            noValidate={true}
          >
            <UserDialogContent dividers>
              {this.props.render()}
            </UserDialogContent>
            <UserDialogActions>
              <UserDialogCancelButton
                variant='contained'
                onClick={() => this.props.cancelDialog(this.props.type)}
              >
                Cancel
              </UserDialogCancelButton>
              <UserDialogSubmitButton
                type='submit'
                variant='contained'
                disabled={this.haveErrors(errors)}
              >
                Submit
              </UserDialogSubmitButton>
            </UserDialogActions>
          </form>
        </FormContext.Provider>
      </>
    )
  }
}
