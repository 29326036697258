import React from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Theme,
  createStyles,
  TextField
} from '@material-ui/core'

import { formatter } from '../../../utils/format'
import { EditOutlined } from '@material-ui/icons'
import { useStyles } from './table.component.style'
import { ITransaction, IPrimeTable } from '../helper/mortgage.interface'
import { TRANSACTION } from '../helper/mortgage.constants'
import { GRAY_400, WHITE } from '../../../styles/color'

export const TransactionTable = ({
  rows,
  openDialog
}: {
  rows: ITransaction[]
  openDialog: (type: string, transaction: ITransaction | undefined) => void
}) => {
  const classes = useStyles()

  return (
    <>
      <TableContainer component={Paper} classes={{ root: classes.boxShadow }}>
        <Table className={classes.table} aria-label='user table'>
          <TableHead classes={{ root: classes.background }}>
            <TableRow>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Transaction Date
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Type
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Amount
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                NSF
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Cheque No.
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Notes
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map(row => (
                <PayoutTableRow key={row.id}>
                  <TableCell component='th' align='center' scope='row'>
                    {row.transDate}
                  </TableCell>
                  <TableCell align='center'>{row.type}</TableCell>
                  <TableCell align='center'>
                    {formatter.format(row.amount)}
                  </TableCell>
                  <TableCell align='center'>
                    {formatter.format(row.nfs)}
                  </TableCell>
                  <TableCell align='center'>{row.chequeNo}</TableCell>
                  <TableCell align='center'>{row.notes}</TableCell>
                  <TableCell align='center'>
                    <EditOutlined
                      className={classes.textNavy}
                      onClick={() => openDialog(TRANSACTION, row)}
                    />
                  </TableCell>
                </PayoutTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const PrimeRateTable = ({ rows }: { rows: IPrimeTable[] }) => {
  const classes = useStyles()

  return (
    <>
      <TableContainer component={Paper} classes={{ root: classes.boxShadow }}>
        <Table className={classes.table} aria-label='user table'>
          <TableHead classes={{ root: classes.background }}>
            <TableRow>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Date
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Rate
              </TableCell>
              <TableCell classes={{ root: classes.textWhite }} align='center'>
                Bank
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, idx: number) => (
                <PayoutTableRow key={idx}>
                  <TableCell component='th' align='center' scope='row'>
                    {row.date}
                  </TableCell>
                  <TableCell align='center'>{row.rate} %</TableCell>
                  <TableCell align='center'>{row.bank}</TableCell>
                </PayoutTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const PayoutTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: GRAY_400
      }
    }
  })
)(TableRow)

export const PayoutTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: WHITE,
      border: '1px solid black'
    }
  })
)(TextField)
