import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { useStyles } from './index.style'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import InformationForm from './information.form'
import AccountForm from './account.form'
import { DEFAULT_INVESTOR, DEFAULT_ACCOUNT } from '../helper/investor.helper'
import { IValues } from '../../../utils/types'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { FiberManualRecord } from '@material-ui/icons'
import { AuthContext } from '../../../providers/auth/auth.provider'

const InvestorForm = () => {
  const history = useHistory()
  const classes = useStyles()
  const [activeStep, $activeStep] = useState(0)
  const [investor, $investor] = useState<IValues>(DEFAULT_INVESTOR)
  const [account, $account] = useState<IValues>(DEFAULT_ACCOUNT)
  const [loading, $loading] = useState<boolean>(false)
  const [errors, $errros] = useState<IValues>({})
  const [contactName, $contactName] = useState<string>('')
  const { token } = React.useContext(AuthContext)

  const steps = ['Add New Investor Information', 'Add New Investor Account']

  const setValues = (value: IValues, formType: string) => {
    if (formType === 'investor') {
      $investor({ ...investor, ...value })
    } else {
      $account({ ...account, ...value })
    }
  }

  const setErrors = (error: IValues) => {
    $errros({ ...errors, ...error })
  }

  React.useEffect(() => {
    if (contactName !== null) {
      setValues({ info: { ...investor.info, contactName } }, 'investor')
    }
  }, [contactName])

  const handleNext = () => {
    if (!validateInvestor()) {
      $activeStep(activeStep + 1)
    }
  }

  const handleBack = () => {
    $activeStep(activeStep - 1)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    $loading(true)
    if (!validateAccount()) {
      const body = {
        operation: 'create',
        newInvestor: {
          info: investor.info,
          banks: investor.banks,
        },
        newAccount: {
          info: account.info,
          investorIds: account.investorIds,
        },
      }

      axios
        .put(`${PROD_OPS}/investor/info`, body, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        .then((res) => {
          if (res.status === 201) {
            $loading(false)
            history.push(`/investors/${res.data.investorId}/view`)
          } else {
          }
        })
    } else {
      $loading(false)
    }
  }

  const validateInvestor = () => {
    let newErrors: IValues = {}
    let haveError: boolean = false
    Object.keys(investor.info).map((fieldName: string) => {
      if (investor.info.investorType === 'individual') {
        if (
          fieldName !== 'companyName' &&
          fieldName !== 'businessNumber' &&
          fieldName !== 'mobile' &&
          fieldName !== 'middleName' &&
          fieldName !== 'lastName' &&
          fieldName !== 'birthDay' &&
          fieldName !== 'gender' &&
          fieldName !== 'authorizerName' &&
          fieldName !== 'fileNo' &&
          fieldName !== 'email' &&
          fieldName !== 'belongsTo'
        ) {
          if (
            investor.info[fieldName] === null ||
            investor.info[fieldName] === ''
          ) {
            newErrors[fieldName] = 'Required'
          }
        }
      } else {
        if (
          fieldName !== 'firstName' &&
          fieldName !== 'middleName' &&
          fieldName !== 'lastName' &&
          fieldName !== 'gender' &&
          fieldName !== 'birthDay' &&
          fieldName !== 'sin' &&
          fieldName !== 'businessNumber' &&
          fieldName !== 'authorizerName' &&
          fieldName !== 'fileNo' &&
          fieldName !== 'mobile' &&
          fieldName !== 'email' &&
          fieldName !== 'belongsTo'
        ) {
          if (
            investor.info[fieldName] === null ||
            investor.info[fieldName] === ''
          ) {
            newErrors[fieldName] = 'Required'
          }
        }
      }
    })

    setErrors(newErrors)
    Object.keys(newErrors).map((key) => {
      if (newErrors[key].length !== null) {
        haveError = true
      }
    })
    return haveError
  }
  const validateAccount = () => {
    let newErrors: IValues = {}
    let haveError: boolean = false

    Object.keys(account.info).map((fieldName: string) => {
      if (fieldName !== 'bankName' && fieldName !== 'bankAccountNo') {
        if (
          account.info[fieldName] === null ||
          account.info[fieldName] === '' ||
          account.info[fieldName] === 0
        ) {
          newErrors[fieldName] = 'Required'
        }
      }
    })

    setErrors(newErrors)
    Object.keys(newErrors).map((key) => {
      if (newErrors[key].length !== null) {
        haveError = true
      }
    })
    return haveError
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <InformationForm
            handleNext={handleNext}
            investor={investor}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            contactName={contactName}
            setContactName={$contactName}
          />
        )
      case 1:
        return (
          <AccountForm
            handleBack={handleBack}
            investor={investor}
            account={account}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            loading={loading}
          />
        )
      default:
        throw new Error('Unknown Step')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Stepper
          activeStep={activeStep}
          connector={<StepConnector classes={{ line: classes.transparent }} />}
          classes={{ root: classes.stepper }}
        >
          {steps.map((label, index: number) => (
            <Step key={label}>
              <StepLabel
                icon={
                  index === activeStep ? (
                    <FiberManualRecord fontSize='small' />
                  ) : (
                    ''
                  )
                }
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <>
        <form
          style={{
            width: '100%',
            minHeight: 'calc(100% - 72px)',
            position: 'relative',
            padding: 32,
          }}
          onSubmit={handleSubmit}
        >
          {activeStep === steps.length ? (
            <>
              <div>redirect</div>
            </>
          ) : (
            <>{getStepContent(activeStep)}</>
          )}
        </form>
      </>
    </div>
  )
}

export default InvestorForm
