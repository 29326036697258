import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  Box,
  ListSubheader,
} from '@material-ui/core'
import {
  Add,
  MonetizationOnOutlined,
  Person,
  FindInPageOutlined,
  ErrorOutlineOutlined,
} from '@material-ui/icons'
import axios from 'axios'
import { formatter } from '../../utils/format'

import { useStyles } from './investor.style'
import { IInvestor, IInvestorTable } from './helper/investor.helper'
import { PROD_OPS, OPS_KEY } from '../../utils/constants'
import { TableLoading } from '../../components/loading/loading.component'
import { ErrorPage } from '../../components/error/error.component'
import { FilterContext } from '../../providers/filter/filter.provider'
import { DateRangePicker } from 'react-dates'
import { LIME, RED } from '../../styles/color'
import { renderMonthElement } from './components/month.element'
import { AuthContext } from '../../providers/auth/auth.provider'

interface ITotal {
  amount: number
  investors: number
}

const InvestorPage = () => {
  const classes = useStyles()
  const [initialLoading, $initialLoading] = useState(false)
  const [rows, $rows] = useState<IInvestorTable[]>([])
  const [original, $original] = useState<IInvestorTable[]>([])
  const [total, $total] = useState<ITotal>({
    amount: 0,
    investors: 0,
  })
  const [open, $open] = useState<boolean>(false)
  const [profitLoading, $profitLoading] = useState<boolean>(false)
  const [profitText, $profitText] = useState<string>('Request Submitted...')
  const [axiosError, $axiosError] = useState<boolean>(false)
  const [focused, $focused] = useState<any>(null)
  const {
    investor,
    url,
    handleFilterSelect,
    datesM,
    handleFilterDates,
  } = useContext(FilterContext)
  const { token } = useContext(AuthContext)

  useEffect(() => {
    const loadData = async () => {
      try {
        $initialLoading(true)
        var list: IInvestorTable[] = []
        var sum: ITotal = { amount: 0, investors: 0 }
        const res = await axios.get(`${PROD_OPS}/investor/list${url}`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        const investors: IInvestor[] = res.data.investors
        investors &&
          investors.map((investor) => {
            const generate = {
              firstName:
                investor.investorType === 'individual'
                  ? `${investor.firstName}`
                  : investor.companyName,
              contactName: investor.contactName,
              phone: investor.phone,
              amount: investor.amount,
              accountNo: investor.accountNo,
              status: investor.status,
              id: investor.id,
            }
            const created = createData(generate)
            sum.amount += investor.amount
            sum.investors += investor.accountNo
            list.push(created)
          })
        $rows(list)
        $original(list)
        $total(sum)
        $initialLoading(false)
      } catch {
        $axiosError(true)
        $initialLoading(false)
      }
    }
    loadData()
  }, [url])

  const handleFilterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.target.value
    if (value === '') {
      $rows(original)
    } else {
      let list = rows.filter((r) =>
        r.firstName
          ? r.firstName.toLowerCase().includes(value.toLowerCase())
          : null
      )
      $rows(list)
    }
  }

  const handleDialogOpen = () => {
    $open(true)
  }

  const handleDialogClose = () => {
    $profitText('Request Submitted...')
    $open(false)
  }

  const handleProfitSubmit = async () => {
    $profitLoading(true)
    try {
      const res = await axios.post(
        `${PROD_OPS}/investment/profitShare`,
        {},
        {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        }
      )

      if (res.status === 200) {
        $profitLoading(false)
        $profitText('Successful')
      }
    } catch (e) {
      $profitLoading(false)
      $profitText('Error Try Again')
    }
  }

  const redirectView = (id: string) => (
    <Link underline='none' href={`/investors/${id}/view`}>
      <FindInPageOutlined />
    </Link>
  )

  const createData = ({
    firstName,
    contactName,
    phone,
    amount,
    accountNo,
    status,
    id,
  }: {
    firstName: string
    contactName: string
    phone: string
    amount: number
    accountNo: number
    status: string
    id: string
  }) => {
    return { firstName, contactName, phone, amount, accountNo, status, id }
  }

  return axiosError ? (
    <ErrorPage />
  ) : (
    <div className={classes.root}>
      <div>
        <div className={classes.toolbarBg}>Investor List</div>
        <ListSubheader style={{ background: 'white', top: 64 }}>
          <Box component='div' className={classes.filterList}>
            <Box
              component='div'
              className={classes.filterMenu}
              style={{ width: '10%', marginLeft: 24 }}
            >
              <TextField
                size='small'
                margin='dense'
                helperText='Search Name'
                onChange={handleFilterText}
              />
            </Box>
            <Box
              component='div'
              className={classes.filterMenu}
              style={{ width: '20%' }}
            >
              <DateRangePicker
                renderMonthElement={renderMonthElement}
                startDate={datesM.startDate}
                startDateId='start-date-id'
                endDate={datesM.endDate}
                endDateId='end-date-id'
                onDatesChange={({ startDate, endDate }) =>
                  handleFilterDates({ startDate, endDate })
                }
                focusedInput={focused}
                onFocusChange={(focusedInput) => $focused(focusedInput)}
                numberOfMonths={1}
                showClearDates={true}
                small
                displayFormat='YYYY-MM-DD'
                showDefaultInputIcon
                isOutsideRange={() => false}
                minimumNights={0}
              />
            </Box>
            <Box
              component='div'
              className={classes.filterMenu}
              style={{ width: '10%' }}
            >
              <FormControl margin='dense' style={{ minWidth: 100 }}>
                <Select
                  name='status'
                  required
                  value={investor.status}
                  onChange={handleFilterSelect}
                >
                  <MenuItem value='All'>All</MenuItem>
                  <MenuItem value='Active'>Active</MenuItem>
                  <MenuItem value='Closed'>Non-Active</MenuItem>
                </Select>
                <FormHelperText>Status</FormHelperText>
              </FormControl>
            </Box>
            <Box
              component='div'
              className={classes.filterMenu}
              style={{ width: '10%' }}
            >
              <Button
                classes={{ root: classes.smallButton }}
                variant='contained'
                onClick={handleDialogOpen}
              >
                Profit Share
              </Button>
              <Dialog maxWidth='md' open={open} onClose={handleDialogClose}>
                <DialogContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 500,
                    height: 100,
                  }}
                >
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    variant='body1'
                  >
                    {profitLoading ? (
                      <>{profitText}</>
                    ) : (
                      <>
                        {profitText === 'Successful' ? (
                          profitText
                        ) : (
                          <>
                            <ErrorOutlineOutlined style={{ marginRight: 16 }} />{' '}
                            Are you sure you want to create profit share?
                          </>
                        )}
                      </>
                    )}
                  </Typography>
                </DialogContent>
                {profitLoading ? (
                  ''
                ) : (
                  <>
                    <DialogActions>
                      <Button onClick={handleDialogClose}>
                        {profitText === 'Successful' ? 'Close' : 'No'}
                      </Button>
                      {profitText === 'Successful' ? (
                        ''
                      ) : (
                        <Button onClick={handleProfitSubmit}>Yes</Button>
                      )}
                    </DialogActions>
                  </>
                )}
              </Dialog>
            </Box>
            <Box
              component='div'
              className={classes.filterMenu}
              style={{ width: '10%' }}
            >
              {/* <Button classes={{ root: classes.smallButton }} variant='contained'>
            Download PDF
          </Button> */}
            </Box>
            <Box
              component='div'
              className={classes.filterMenu}
              style={{ width: '35%', justifyContent: 'flex-end' }}
            >
              <Link className={classes.addButton} href='/investors/new'>
                <Add /> New Investors{' '}
              </Link>
            </Box>
          </Box>
          <div className={classes.filterbar}>
            <FormControl
              margin='dense'
              style={{ minWidth: 150, marginLeft: 24 }}
            >
              <Select
                name='amount'
                required
                value={investor.amount}
                onChange={handleFilterSelect}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='amount1'>{`< ${formatter.format(
                  1000000
                )}`}</MenuItem>
                <MenuItem value='amount2'>{`>= ${formatter.format(
                  1000000
                )}`}</MenuItem>
                <MenuItem value='amount3'>{`< ${formatter.format(
                  5000000
                )}`}</MenuItem>
              </Select>
              <FormHelperText>Investment Amount</FormHelperText>
            </FormControl>
            <FormControl
              margin='dense'
              style={{ minWidth: 50, marginLeft: 24 }}
            >
              <Select
                name='investClass'
                required
                value={investor.investClass}
                onChange={handleFilterSelect}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='A'>A</MenuItem>
                <MenuItem value='B'>B</MenuItem>
                <MenuItem value='C'>C</MenuItem>
                <MenuItem value='D'>D</MenuItem>
              </Select>
              <FormHelperText>Class</FormHelperText>
            </FormControl>
            <FormControl
              margin='dense'
              style={{ minWidth: 100, marginLeft: 24 }}
            >
              <Select
                name='type'
                required
                value={investor.type}
                onChange={handleFilterSelect}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='individual'>Individual</MenuItem>
                <MenuItem value='entity'>Entity</MenuItem>
              </Select>
              <FormHelperText>Investor Type</FormHelperText>
            </FormControl>
            <FormControl
              margin='dense'
              style={{ minWidth: 100, marginLeft: 24 }}
            >
              <Select
                name='distribution'
                required
                value={investor.distribution}
                onChange={handleFilterSelect}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Quarterly'>Quarterly</MenuItem>
                <MenuItem value='Annually'>Annually</MenuItem>
              </Select>
              <FormHelperText>Distribution</FormHelperText>
            </FormControl>
            <FormControl
              margin='dense'
              style={{ minWidth: 100, marginLeft: 24 }}
            >
              <Select
                name='fee'
                required
                value={investor.fee}
                onChange={handleFilterSelect}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='fee1'>{'< 1.0'}</MenuItem>
                <MenuItem value='fee2'>{'=> 1.0'}</MenuItem>
                <MenuItem value='fee3'>{'>= 1.5'}</MenuItem>
                <MenuItem value='fee4'>{'>= 2.0'}</MenuItem>
              </Select>
              <FormHelperText>Management Fee</FormHelperText>
            </FormControl>
          </div>
        </ListSubheader>
        {initialLoading ? (
          <TableLoading />
        ) : (
          <>
            <TableContainer
              component={Paper}
              classes={{ root: classes.boxShadow }}
            >
              <Table className={classes.table} aria-label='user table'>
                <TableHead classes={{ root: classes.background }}>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>Contact</TableCell>
                    <TableCell align='center'>Contact Tel</TableCell>
                    <TableCell align='center'>
                      Total Investment Amount
                    </TableCell>
                    <TableCell align='center'>Number of Investments</TableCell>
                    <TableCell align='center'>Investment Status</TableCell>
                    <TableCell align='center'>Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows &&
                    rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component='th' align='center' scope='row'>
                          {row.firstName}
                        </TableCell>
                        <TableCell align='center'>{row.contactName}</TableCell>
                        <TableCell align='center'>{row.phone}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(row.amount)}
                        </TableCell>
                        <TableCell align='center'>{row.accountNo}</TableCell>
                        <TableCell align='center'>
                          {row.status === 'Active' ? (
                            <span style={{ color: LIME, fontWeight: 700 }}>
                              Active
                            </span>
                          ) : (
                            <span style={{ color: RED, fontWeight: 700 }}>
                              Non-Active
                            </span>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {redirectView(row.id)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              className={classes.toolbar}
              style={{
                width: '95%',
                borderTop: '1px solid #C3C3C3',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant='h6' className={classes.typography}>
                <MonetizationOnOutlined className={classes.icon} />
                {formatter.format(total.amount)}
                <Person className={classes.icon} /> {total.investors}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default InvestorPage
