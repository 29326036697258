import React from 'react'
import {
  Grid,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { useStyles } from './edit.form.fields.style'
import { Moment } from 'moment'
import { NAVY } from '../../../styles/color'
import { IValues } from '../../../utils/types'

const MortgageEditFormFields = ({
  mortgageLoaded,
  sDate,
  selects,
  checkbox,
  handleTextField,
  handleDate,
  handleSelectField,
  handleNewAccountCheckBox,
}: {
  mortgageLoaded: IValues
  sDate: Moment | null
  selects: { mortgageType: string | null; interestType: string | null }
  checkbox: { title: boolean; legalDocs: boolean; lenderPackage: boolean }
  handleTextField: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDate: (dateObj: Moment | null, name: string) => void
  handleSelectField: (event: React.ChangeEvent<{ value: unknown }>) => void
  handleNewAccountCheckBox: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12} container direction='row' spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1'>
            Status: {mortgageLoaded.status}
          </Typography>
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>File No.</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            defaultValue={mortgageLoaded.fileNo}
            onChange={handleTextField}
            id='fileNo'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Start Date</Typography>
          <DatePicker
            disabled
            InputProps={{ className: classes.textfield }}
            clearable
            format='YYYY-MM-DD'
            value={sDate}
            onChange={(date) => handleDate(date, 'startDate')}
            inputVariant='outlined'
            margin='dense'
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Mortgage Amount</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            defaultValue={
              mortgageLoaded.mortgageAmount === 0
                ? ''
                : mortgageLoaded.mortgageAmount
            }
            onChange={handleTextField}
            id='mortgageAmount'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>LTV %</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
            defaultValue={mortgageLoaded.ltv === 0 ? '' : mortgageLoaded.ltv}
            onChange={handleTextField}
            id='ltv'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Lender Fee</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            defaultValue={
              mortgageLoaded.lenderFee === 0 ? '' : mortgageLoaded.lenderFee
            }
            onChange={handleTextField}
            id='lenderFee'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>
            Administration fee per Property
          </Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            defaultValue={
              mortgageLoaded.adminFee === 0 ? '' : mortgageLoaded.adminFee
            }
            onChange={handleTextField}
            id='adminFee'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Mortgage Type</Typography>
          <FormControl disabled variant='outlined' margin='dense'>
            <Select
              classes={{ root: classes.textfield }}
              name='mortgageType'
              value={selects.mortgageType === null ? '' : selects.mortgageType}
              onChange={handleSelectField}
            >
              <MenuItem value=''>{<em>None</em>}</MenuItem>
              <MenuItem value='1st'>1st</MenuItem>
              <MenuItem value='2nd'>2nd</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Interest Adjustment</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            defaultValue={
              mortgageLoaded.interestAdjustment === 0
                ? ''
                : mortgageLoaded.interestAdjustment
            }
            onChange={handleTextField}
            id='interestAdjustment'
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: NAVY }}
                checked={checkbox.title}
                onChange={handleNewAccountCheckBox}
                size='small'
                value='title'
                id='title'
              />
            }
            label='Title Certificate'
            labelPlacement='start'
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: NAVY }}
                checked={checkbox.legalDocs}
                onChange={handleNewAccountCheckBox}
                size='small'
                value='legalDocs'
                id='legalDocs'
              />
            }
            label='Legal Docs'
            labelPlacement='start'
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: NAVY }}
                checked={checkbox.lenderPackage}
                onChange={handleNewAccountCheckBox}
                size='small'
                value='lenderPackage'
                id='lenderPackage'
              />
            }
            label='Lender Package'
            labelPlacement='start'
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Total Brokerage Fee</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            defaultValue={
              mortgageLoaded.brokerFee === 0 ? '' : mortgageLoaded.brokerFee
            }
            onChange={handleTextField}
            id='brokerFee'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Co-Broker Name</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            defaultValue={mortgageLoaded.coBrokerName}
            onChange={handleTextField}
            id='coBrokerName'
          />
        </Grid>
        <Grid item xs={3} container direction='column'>
          <Typography variant='body2'>Co-Broker Fee</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            type='number'
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            defaultValue={
              mortgageLoaded.coBrokerFee === 0 ? '' : mortgageLoaded.coBrokerFee
            }
            onChange={handleTextField}
            id='coBrokerFee'
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={6} container direction='column'>
          <Typography variant='body2'>Notes</Typography>
          <TextField
            disabled
            classes={{ root: classes.textfield }}
            margin='dense'
            variant='outlined'
            defaultValue={mortgageLoaded.brokerNotes}
            onChange={handleTextField}
            id='brokerNotes'
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </>
  )
}

export default MortgageEditFormFields
