import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  NAVY,
  WHITE,
  LIGHT_GRAY,
  SKY_BLUE,
  GRAY_600
} from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100% - 72px)',
      padding: theme.spacing(4)
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      ...theme.mixins.toolbar
    },
    bankButton: {
      boxShadow: 'unset'
    },
    bankIcon: {
      marginRight: 8,
      backgroundColor: NAVY,
      color: WHITE,
      borderRadius: '50%'
    },
    bankDelete: {
      color: GRAY_600
    },
    bankItem: {
      width: '100%',
      boxShadow: 'unset',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: SKY_BLUE
    },
    dropContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    dropZone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundolor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    bankGrid: {
      padding: theme.spacing(0, 4, 4, 0)
    },
    buttonField: {
      position: 'absolute',
      bottom: '5%',
      width: '20%',
      right: '5%'
    }
  })
)
