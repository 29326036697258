import React from 'react'
import { GlobalStyle } from './styles/global.style'
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from './components/header/header.component'
import Sidebar from './components/sidebar/sidebar.component'
import { AuthContext } from './providers/auth/auth.provider'
import axios from 'axios'

import AppRouter from './app.router'
import { DEFAULT_USER } from './providers/auth/helper/constants'
import { PROD_AUTH, AUTH_KEY } from './utils/constants'
import { Redirect } from 'react-router-dom'

const App: React.FC = () => {
  const { reset } = React.useContext(AuthContext)

  const logout = () => {
    window.sessionStorage.clear()
    reset()
  }

  return (
    <>
      <GlobalStyle />
      <CssBaseline />
      <AppRouter header={<Header logout={logout} />} sidebar={<Sidebar />} />
    </>
  )
}

export default App
