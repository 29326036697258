import React, { ReactNode } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { WHITE } from './styles/color'

import FilterProvider from './providers/filter/filter.provider'
import PayoutProvider from './providers/payout/payout.provider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AuthContext } from './providers/auth/auth.provider'
import MomentUtils from '@date-io/moment'

const routes: any[] = [
  ...require('./pages/homepage/routes').default,
  ...require('./pages/investor/routes').default,
  ...require('./pages/mortgage/routes').default,
  ...require('./pages/reminder/routes').default,
  ...require('./pages/user/routes').default,
  ...require('./pages/setting/routes').default
]
const publicRoute: any[] = [...require('./pages/login/routes').default]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appbarSpacer: theme.mixins.toolbar,
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    content: {
      flexGrow: 1,
      backgroundColor: WHITE,
      height: '100%'
    }
  })
)

const AppRouter: React.FC<{
  header?: ReactNode
  sidebar?: ReactNode
}> = ({ header, sidebar, ...others }) => {
  const classes = useStyles()
  const { authorized } = React.useContext(AuthContext)

  return (
    <Router {...others}>
      {!authorized ? (
        <Switch>
          {publicRoute.map(({ Component, path, exact, props }, key) => {
            return (
              <Route path={path} exact={exact} key={`${path}-${key}`}>
                <Component {...props} />
              </Route>
            )
          })}
        </Switch>
      ) : (
        <>
          {header}
          {sidebar}
          <main className={classes.content}>
            <div className={classes.appbarSpacer} />
            <Switch>
              <FilterProvider>
                <PayoutProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    {routes.map(({ Component, path, exact, props }, key) => {
                      return (
                        <Route path={path} exact={exact} key={`${path}-${key}`}>
                          <Component {...props} />
                        </Route>
                      )
                    })}
                  </MuiPickersUtilsProvider>
                </PayoutProvider>
              </FilterProvider>
            </Switch>
          </main>
        </>
      )}
    </Router>
  )
}

export default AppRouter
