import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, YELLOW } from '../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottom: {
      height: '67%',
      display: 'block'
    },
    stats: {
      height: '100%',
      width: '22%',
      float: 'left',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    paper: {
      background: '#FFFFFF 0% 0% no-repeat',
      borderRadius: theme.spacing(2),
      boxShadow: '0px 3px 6px #00000029',
      padding: theme.spacing(1, 2, 2, 2),
      marginBottom: 8,
      maxHeight: '13%'
    },
    paper2: {
      background: '#FFFFFF 0% 0% no-repeat',
      borderRadius: theme.spacing(2),
      boxShadow: '0px 3px 6px #00000029',
      padding: theme.spacing(2),
      marginBottom: 16,
      maxHeight: '20%'
    },
    iconTypo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    bigIcon: {
      marginRight: 10
    },
    chart: {
      float: 'right',
      height: '100%',
      width: '78%',
      padding: theme.spacing(2)
    },
    yellow: {
      color: YELLOW
    },
    spaceAround: {
      justifyContent: 'space-around'
    },
    indicator: {
      backgroundColor: NAVY
    },
    table: {
      minWidth: 920
    }
  })
)
