import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useStyles } from './information.view.style'
import {
  Box,
  Grid,
  Typography,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core'
import {
  Add,
  AccountBalanceOutlined,
  DeleteForeverOutlined,
  EditOutlined,
} from '@material-ui/icons'
import { IBank, DEFAULT_BANK } from '../helper/investor.helper'
import { IValues } from '../../../utils/types'
import InvestorInfo from '../components/investor.info.component'
import Dropzone from '../../../components/dropzone/dropzone.component'
import axios from 'axios'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import {
  FixedHeightLoading,
  DialogLoading,
} from '../../../components/loading/loading.component'
import moment, { Moment } from 'moment'
import { AuthContext } from '../../../providers/auth/auth.provider'

export type CheckboxType = {
  [key: string]: boolean
}

const InformationView = ({
  loading,
  loadedInvestor,
  investor,
  handleNext,
  setValues,
  setForm,
  edit,
  setEdit,
}: {
  handleNext: () => void
  investor: IValues
  loadedInvestor: any
  loading: boolean
  setValues: (value: IValues, type: string) => void
  setForm: (name: string) => void
  edit: boolean
  setEdit: () => void
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [bank, $bank] = useState<IBank>(DEFAULT_BANK)
  const [banks, $banks] = useState<IBank[]>(loadedInvestor.banks)
  const [bankDialog, $bankDialog] = useState<boolean>(false)
  const [checkbox, $checkbox] = useState<CheckboxType>({
    individual: loadedInvestor.investorType === 'individual',
    entity: loadedInvestor.investorType === 'entity',
  })
  const [errors, $errors] = useState<IValues>({})
  const [birthDay, $birthday] = useState<Moment | null>(
    loadedInvestor.birthDay
      ? moment(loadedInvestor.birthDay, 'YYYY-MM-DD')
      : null
  )
  const { token } = React.useContext(AuthContext)

  React.useEffect(() => {
    setForm('investor')
    $checkbox({
      individual: loadedInvestor.investorType === 'individual',
      entity: loadedInvestor.investorType === 'entity',
    })
    $gender(loadedInvestor.gender)
    $banks(loadedInvestor.banks)
    $superAccount(loadedInvestor.belongsTo)
    $birthday(
      loadedInvestor.birthDay
        ? moment(loadedInvestor.birthDay, 'YYYY-MM-DD')
        : null
    )
  }, [loadedInvestor])

  const [belongsTo, $belongsTo] = useState<any>([])
  const [superAccount, $superAccount] = useState(loadedInvestor.belongsTo)
  const [gender, $gender] = useState(loadedInvestor.gender)
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current!.offsetWidth)
    }
  }, [])

  React.useEffect(() => {
    const loadInvestors = async () => {
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      $belongsTo(res.data.investors)
    }
    loadInvestors()
  }, [])
  const formType = 'investor'

  const handleDialogOpen = () => {
    $bankDialog(true)
  }

  const handleDialogClose = () => {
    $bankDialog(false)
  }

  const handleDialogCancel = () => {
    $bank(DEFAULT_BANK)
    handleDialogClose()
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {}

  const deleteBank = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault()
    const modified = banks
      .slice(0, index)
      .concat(banks.slice(index + 1, banks.length))
    $banks(banks.slice(0, index).concat(banks.slice(index + 1, banks.length)))
    setValues({ banks: modified }, formType)
  }

  const handleBankSubmit = () => {
    const values = [...banks]
    values.push(bank)
    $banks([...banks, bank])
    setValues(
      {
        banks: values,
      },
      formType
    )
    $bank(DEFAULT_BANK)
    handleDialogClose()
  }

  const handleBank = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.target.id as string
    const value = e.target.value as string

    $bank({ ...bank, [name]: value })
  }

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type: string = event.target.value
    const checked: boolean = event.target.checked

    if (!checkbox.individual && !checkbox.entity) {
      setValues({ info: { ...investor.info, investorType: type } }, formType)
      $checkbox({ ...checkbox, [type]: checked })
    } else {
      if (type === 'individual') {
        if (checkbox.entity) {
          $checkbox({ entity: false, [type]: checked })
          setValues(
            {
              info: {
                ...investor.info,
                investorType: type,
              },
            },
            formType
          )
        } else {
          $checkbox({ ...checkbox, [type]: checked })
          setValues(
            {
              info: {
                ...investor.info,
                investorType: '',
              },
            },
            formType
          )
        }
      } else {
        if (checkbox.individual) {
          $checkbox({ individual: false, [type]: checked })
          setValues(
            { info: { ...investor.info, investorType: type } },
            formType
          )
        } else {
          $checkbox({ ...checkbox, [type]: checked })
          setValues({ info: { ...investor.info, investorType: '' } }, formType)
        }
      }
    }
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues(
      {
        info: { ...investor.info, [e.currentTarget.id]: e.currentTarget.value },
      },
      formType
    )
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    $birthday(dateObj)

    setValues(
      { info: { ...investor.info, [name]: dateObj?.format('YYYY-MM-DD') } },
      formType
    )
  }

  const handleGenderSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $gender(event.target.value as string)
    setValues(
      { info: { ...investor.info, [name]: event.target.value as string } },
      formType
    )
  }

  const handleSuperAccount = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $superAccount(event.target.value as string)
    setValues(
      { info: { ...investor.info, [name]: event.target.value as string } },
      formType
    )
  }

  return (
    <>
      {loading ? (
        <FixedHeightLoading />
      ) : (
        <Box component='div' className={classes.root}>
          <Box component='div' className={classes.fields}>
            <Grid container direction='column' spacing={3}>
              <Grid item xs={12} container justify='flex-end'>
                {edit ? (
                  <Button
                    classes={{ root: classes.submit }}
                    style={{ height: 40 }}
                    variant='outlined'
                    type='submit'
                    disabled={loading}
                  >
                    Save Investor
                  </Button>
                ) : (
                  <Typography
                    onClick={setEdit}
                    className={classes.editIcon}
                    variant='body1'
                  >
                    <EditOutlined /> Edit
                  </Typography>
                )}
              </Grid>
              {edit ? (
                loading ? (
                  <DialogLoading />
                ) : (
                  <InvestorInfo
                    investor={loadedInvestor}
                    checkbox={checkbox}
                    handleCheckbox={handleCheckbox}
                    handleTextField={handleTextField}
                    inputLabel={inputLabel}
                    gender={gender}
                    handleGenderSelect={handleGenderSelect}
                    labelWidth={labelWidth}
                    handleSuperAccount={handleSuperAccount}
                    belongsTo={belongsTo}
                    superAccount={superAccount}
                    errors={errors}
                    handleOnBlur={handleOnBlur}
                    birthDay={birthDay}
                    handleDate={handleDate}
                  />
                )
              ) : (
                <>
                  <Grid style={{ marginBottom: 8 }} item xs={12}>
                    <Typography variant='body1'>
                      Type:{' '}
                      {loadedInvestor.investorType === 'individual'
                        ? 'Individual'
                        : 'Entity'}
                    </Typography>
                  </Grid>
                  {/* First Line Starts */}
                  {/* First Name Starts */}
                  {loadedInvestor.investorType === 'individual' ? (
                    <>
                      <Grid item xs={12} container direction='row' spacing={2}>
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Name{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.firstName}
                            </span>
                          </Typography>
                        </Grid>
                        {/* First Name Ends */}
                        {/* Middle Name Starts */}
                        {/* <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Middle Name{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.middleName}
                            </span>
                          </Typography>
                        </Grid> */}
                        {/* Middle Name Ends */}
                        {/* Last Name Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Name (Optional){' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.lastName
                                ? loadedInvestor.lastName
                                : ''}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Last Name End */}
                        {/* Gender Start */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Gender{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.gender}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Gender Ends */}
                      </Grid>
                      <Grid item xs={12} container direction='row' spacing={2}>
                        {/* Birthday Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Birthday{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.birthDay}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Birthday End */}
                        {/* SIN Start */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            SIN{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.sin}
                            </span>
                          </Typography>
                        </Grid>
                        {/* SIN End */}
                        {/* Phone Home Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Mobile{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.phone}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Phone Home Ends */}
                        {/* Mobile Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        ></Grid>
                        {/* Mobile Ends */}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} container direction='row' spacing={2}>
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Company Name{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.companyName}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Company Name Ends */}
                        {/* Business No Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Business No{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.businessNumber}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Phone Home Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Home{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.phone}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Phone Home Ends */}
                        {/* Mobile Starts */}
                        <Grid
                          item
                          xs={3}
                          container
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                        >
                          <Typography variant='body1'>
                            Mobile{' '}
                            <span className={classes.fieldValue}>
                              {loadedInvestor.mobile}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Mobile Ends */}
                      </Grid>
                    </>
                  )}

                  {/* Third Line Starts */}
                  <Grid item xs={12} container direction='row' spacing={2}>
                    {/* Contact Name Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Contact Name{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.contactName}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Contact Name Ends */}
                    {/* Third Party Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Third Party Authorize Name
                        <span className={classes.fieldValue}>
                          {loadedInvestor.authorizerName}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Third Party Ends */}
                    {/* Email Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Email{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.email}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Email Ends */}
                    {/* File Number Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        File Number{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.fileNo}
                        </span>
                      </Typography>
                    </Grid>
                    {/* File Number Ends */}
                  </Grid>
                  {/* Third Line Ends */}
                  {/* Fourth Line Starts */}
                  <Grid item xs={12} container direction='row' spacing={2}>
                    {/* Super Account Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Super Account{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.belongsTo
                            ? loadedInvestor.belongsTo
                            : ''}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Super Account Ends */}
                    {/* Address Starts */}
                    <Grid
                      item
                      xs={9}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Address{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.address}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Address Ends */}
                  </Grid>
                  {/* Fourth Line Ends */}
                  {/* Fifth Line Starts */}
                  <Grid item xs={12} container direction='row' spacing={2}>
                    {/* City Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        City{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.city}
                        </span>
                      </Typography>
                    </Grid>
                    {/* City Ends */}
                    {/* Province Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Province{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.province}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Province Ends */}
                    {/* Postal Code Starts */}
                    <Grid
                      item
                      xs={3}
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Typography variant='body1'>
                        Postal Code{' '}
                        <span className={classes.fieldValue}>
                          {loadedInvestor.postCode}
                        </span>
                      </Typography>
                    </Grid>
                    {/* Postal Code Ends */}
                  </Grid>
                  {/* Fifth Line Ends */}
                </>
              )}
              {/* Bank Account Field */}
              <Grid
                item
                xs={12}
                style={{ marginTop: 16 }}
                container
                direction='row'
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant='body1'>Bank Account</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Fab
                    classes={{ root: classes.bankButton }}
                    variant='extended'
                    onClick={handleDialogOpen}
                    disabled={banks.length === 3 || !edit}
                  >
                    <Add classes={{ root: classes.bankIcon }} />
                    Add Bank Account
                  </Fab>
                  <Dialog
                    open={bankDialog}
                    onClose={handleDialogClose}
                    aria-labelledby='bank-form'
                    fullWidth
                    maxWidth='sm'
                  >
                    <DialogTitle id='bank-form'>Add Bank Account</DialogTitle>
                    <DialogContent dividers>
                      <Grid
                        className={classes.bankGrid}
                        container
                        direction='column'
                        spacing={3}
                      >
                        <Grid item xs={12} container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='body1'>Bank Info</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='bankName'
                              fullWidth
                              margin='dense'
                              variant='outlined'
                              onChange={handleBank}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Grid
                        container
                        direction='row'
                        justify='space-around'
                        alignItems='center'
                      >
                        <Grid item xs container justify='center'>
                          <Button
                            classes={{ root: classes.cancel }}
                            style={{ width: 176 }}
                            variant='contained'
                            onClick={handleDialogCancel}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs container justify='center'>
                          <Button
                            classes={{ root: classes.submit }}
                            style={{ width: 176 }}
                            variant='contained'
                            onClick={handleBankSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </Dialog>
                </Grid>
                {banks &&
                  banks.map((b, index: number) => (
                    <Grid key={index} item xs={3}>
                      <Fab
                        classes={{ root: classes.bankItem }}
                        variant='extended'
                      >
                        <Grid container alignItems='center'>
                          <Grid item xs={1}>
                            <AccountBalanceOutlined />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography variant='body1'>
                              {b.bankName}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            {edit ? (
                              <DeleteForeverOutlined
                                onClick={(e) => deleteBank(e, index)}
                                style={{ marginRight: 0 }}
                                classes={{ root: classes.bankDelete }}
                              />
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      </Fab>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Box>
          <Grid
            style={{ marginTop: 32 }}
            item
            xs={12}
            container
            direction='row'
            spacing={2}
          >
            <Grid item xs={12} />
            <Grid item xs={9} />
          </Grid>
          <Box component='div' className={classes.buttonField}>
            <Grid
              container
              direction='row'
              justify='space-evenly'
              alignItems='flex-end'
              spacing={2}
            >
              <Grid item xs container justify='center'>
                <Button
                  fullWidth
                  classes={{ root: classes.cancel }}
                  variant='outlined'
                  onClick={() => history.goBack()}
                >
                  Close
                </Button>
              </Grid>
              {edit ? (
                ''
              ) : (
                <Grid item xs container justify='center'>
                  <Button
                    type='submit'
                    fullWidth
                    onClick={handleNext}
                    classes={{ root: classes.submit }}
                    variant='outlined'
                  >
                    Next {'>'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}

export default InformationView
