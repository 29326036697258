import * as React from 'react'
import { DEFAULT_USER } from './helper/constants'
import { IUser, IAuthContext } from './helper/types'
import axios from 'axios'
import { PROD_AUTH, AUTH_KEY } from '../../utils/constants'

export const AuthContext = React.createContext<IAuthContext>({
  user: DEFAULT_USER,
  token: '',
  authorized: false,
  setUser: (u: IUser) => {},
  setToken: (t: string) => {},
  isAuthorized: (token: string) => {},
  setAuthorized: (b: boolean) => {},
  reset: () => {}
})

const { Provider } = AuthContext

const getToken = () => {
  const st = window.sessionStorage.getItem('token')
  return st ? st : ''
}

const getUser = () => {
  const su = window.sessionStorage.getItem('user')
  return su ? JSON.parse(su) : DEFAULT_USER
}

const getSatus = () => {
  const ss = window.sessionStorage.getItem('status')
  return ss ? true : false
}

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [user, $user] = React.useState<IUser>(getUser())
  const [token, $token] = React.useState<string>(getToken())
  const [authorized, $authorized] = React.useState<any>(getSatus())

  const setUser = (u: IUser) => {
    $user(u)
  }

  const setToken = (t: string) => {
    $token(t)
  }

  const setAuthorized = (b: boolean) => {
    $authorized(b)
  }

  const reset = () => {
    setUser(DEFAULT_USER)
    setToken('')
    setAuthorized(false)
  }

  const isAuthorized = (t: string) => {
    axios
      .get(`${PROD_AUTH}/authStatus`, {
        headers: { 'x-api-key': AUTH_KEY, Authorization: t }
      })
      .then(res => {
        if (res.status === 200) {
          $authorized(true)
        } else {
          $authorized(false)
        }
      })
  }

  return (
    <Provider
      value={{
        user,
        token,
        authorized,
        setUser,
        setToken,
        setAuthorized,
        isAuthorized,
        reset
      }}
    >
      {children}
    </Provider>
  )
}

export default AuthProvider
