import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Container, Typography } from '@material-ui/core'

import { useStyles } from './homepage.style'

import HomeDashboard from '../../components/dashboard/home.dashboard.component'
import HomeChart from '../../components/chart/home.chart.component'
import { PROD_OPS, OPS_KEY, PROD_AUTH, AUTH_KEY } from '../../utils/constants'
import { IDashboard, DEFAULT_DASHBOARD } from './helper/homepage.helper'
import { LoadingB } from '../../components/loading/loading.component'
import { ErrorPage } from '../../components/error/error.component'
import UserContext from '../../contexts/user/user.context'
import { AuthContext } from '../../providers/auth/auth.provider'

const HomePage = () => {
  const classes = useStyles()
  const [dashboard, $dashboard] = useState<IDashboard>(DEFAULT_DASHBOARD)
  const [edit, $edit] = React.useState<boolean>(false)
  const [loading, $loading] = useState<boolean>(false)
  const [axiosError, $axiosError] = useState<boolean>(false)
  const [username, $username] = useState<string>('')
  const { token, user } = React.useContext(AuthContext)
  console.log(dashboard)

  const setEdit = () => {
    $edit(!edit)
  }

  useEffect(() => {
    const loadData = async () => {
      $loading(true)
      try {
        const res = await axios.get(`${PROD_OPS}/dashboard`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        const userRes = await axios.get(`${PROD_AUTH}/user/list`, {
          headers: { 'x-api-key': AUTH_KEY, Authorization: token },
        })

        if (userRes.status === 200) {
          const list = userRes.data.users
          const selected = list.filter((l: any) => l.id === user.id)[0]
          $username(selected.name)
          if (res.status === 200) {
            $dashboard(res.data.dashboard)
            $loading(false)
          }
        }
      } catch (e) {
        $axiosError(true)
        $loading(false)
      }
    }
    loadData()
  }, [edit])

  return (
    <>
      {loading ? (
        <LoadingB />
      ) : axiosError ? (
        <ErrorPage />
      ) : (
        <div className={classes.root}>
          <Container maxWidth='xl' className={classes.container}>
            <Typography className={classes.toolbar} variant='h6'>
              Hi, {username === '' ? '' : username}
            </Typography>
            <HomeDashboard
              dashboard={dashboard}
              edit={edit}
              setEdit={setEdit}
            />
            <HomeChart dashboard={dashboard} />
          </Container>
        </div>
      )}
    </>
  )
}

export default HomePage
