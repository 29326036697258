import React from 'react'

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Link } from '@material-ui/core'
import {
  Person,
  NotificationsOutlined,
  SettingsOutlined,
  ExitToAppOutlined
} from '@material-ui/icons'

import { DRAWER_WIDTH } from '../../styles/size'
import { WHITE, GRAY_400, BLACK } from '../../styles/color'
import { AuthContext } from '../../providers/auth/auth.provider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - ${DRAWER_WIDTH})`,
      marginLeft: DRAWER_WIDTH,
      boxShadow: 'unset',
      background: WHITE,
      color: BLACK,
      borderBottom: `1px solid ${GRAY_400}`
    },
    toolbar: {
      width: '35%',
      marginLeft: '65%',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      color: BLACK
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

const Header = ({ logout }: { logout: () => void }) => {
  const classes = useStyles()
  const { user } = React.useContext(AuthContext)

  return (
    <>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <div className={classes.toolbar}>
            {user.role === 'owner' ? (
              <Link
                className={classes.link}
                underline='none'
                color='inherit'
                href='/user'
              >
                <Person /> User
              </Link>
            ) : (
              ''
            )}
            <Link
              className={classes.link}
              underline='none'
              color='inherit'
              href='/reminder'
            >
              <NotificationsOutlined />
              Reminder
            </Link>
            <Link
              className={classes.link}
              underline='none'
              color='inherit'
              href='/setting'
            >
              <SettingsOutlined />
              Setting
            </Link>
            <Link
              onClick={logout}
              className={classes.link}
              underline='none'
              color='inherit'
              href='/'
            >
              <ExitToAppOutlined />
              Logout
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
