import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Fab,
  FormHelperText,
} from '@material-ui/core'
import { Add, HighlightOffOutlined } from '@material-ui/icons'
import axios from 'axios'

import { useStyles } from './account.form.style'
import { IBank } from '../helper/investor.helper'
import { IValues } from '../../../utils/types'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { FixedHeightLoading } from '../../../components/loading/loading.component'
import { DatePicker } from '@material-ui/pickers'
import { Moment } from 'moment'
import { AuthContext } from '../../../providers/auth/auth.provider'

interface IJointInvestor {
  firstName: string
  lastName: string
  id: string
  sin: string
}

interface IDates {
  depositDate: Moment | null
  ccoDate: Moment | null
}

const AccountForm = ({
  handleBack,
  investor,
  account,
  setValues,
  errors,
  setErrors,
  loading,
}: {
  handleBack: () => void
  investor: IValues
  account: IValues
  setValues: (value: IValues, type: string) => void
  errors: IValues
  setErrors: (error: IValues) => void
  loading: boolean
}) => {
  const classes = useStyles()
  const history = useHistory()
  const formType = 'account'
  const [selectFields, $selectFields] = useState<any[]>([
    {
      firstName: investor.info.firstName,
      sin: investor.info.sin,
    },
  ])

  const [investors, $investors] = useState<IJointInvestor[]>([])
  const [listLoading, $listLoading] = useState<boolean>(false)
  const [distribution, $distribution] = useState(
    account.info.interestDistribution ? account.info.interestDistribution : ''
  )
  const [aClass, $aClass] = useState(
    account.info.class ? account.info.class : ''
  )
  const [dates, $dates] = useState<IDates>({
    depositDate: null,
    ccoDate: null,
  })
  const { token } = React.useContext(AuthContext)

  const handleAddFields = () => {
    const values = [...selectFields]
    values.push({ firstName: '', sin: '' })
    $selectFields(values)
  }

  const handleRemoveFields = (index: number) => {
    const values = [...selectFields]
    const target = investors.filter(
      (i) => i.firstName === values[index].firstName
    )[0]
    values.splice(index, 1)
    $selectFields(values)

    const idList = account.investorIds.filter((i: any) => i !== target.id)
    setValues({ investorIds: idList }, formType)
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    index: number
  ) => {
    const values = [...selectFields]
    const value = event.target.value as string
    const target = investors.filter((i) => i.firstName === value)[0]
    values[index].firstName = value
    values[index].sin = target.sin
    const idList = account.investorIds
    idList.push(target.id)
    $selectFields(values)
    setValues({ investorIds: idList }, formType)
  }

  const [bank, $bank] = useState<string>(
    investor.banks[0] ? investor.banks[0].bankName : ''
  )
  const [banks, $banks] = useState<any>(investor.banks)

  const handleBankSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    const value = event.target.value as string
    $bank(event.target.value as string)

    const selectedBank: IBank[] = banks.filter(
      (b: IBank) => b.bankName === value
    )
    setValues(
      {
        info: {
          ...account.info,
          [name]: value,
        },
      },
      formType
    )
  }

  useEffect(() => {
    const loadData = async () => {
      $listLoading(true)
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        $investors(res.data.investors)
        $listLoading(false)
      } else {
        throw 'Error Getting Investors'
      }
    }

    setValues(
      {
        info: {
          ...account.info,
          bankName: investor.banks[0] ? investor.banks[0].bankName : null,
        },
      },
      formType
    )

    loadData()
  }, [])

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues(
      {
        info: { ...account.info, [e.currentTarget.id]: e.currentTarget.value },
      },
      formType
    )
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    let newError: IValues = {}
    if (value === '') {
      newError[name] = 'Required'
    } else {
      newError[name] = null
    }
    setErrors(newError)
  }

  const handleDistributionSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $distribution(event.target.value as string)
    setValues(
      { info: { ...account.info, [name]: event.target.value as string } },
      formType
    )
  }

  const handleClassSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $aClass(event.target.value as string)
    setValues(
      { info: { ...account.info, [name]: event.target.value as string } },
      formType
    )
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    let list = dates
    list = { ...list, [name]: dateObj }
    $dates(list)

    setValues(
      {
        info: {
          ...account.info,
          [name]: dateObj !== null ? dateObj.format('YYYY-MM-DD') : '',
        },
      },
      formType
    )
  }

  return (
    <>
      {loading ? (
        <FixedHeightLoading />
      ) : (
        <>
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Investors *{' '}
                <span>
                  (You can have multiple investors for the same account)
                </span>
              </Typography>
            </Grid>
            {selectFields.map((field, index: number) => {
              if (index === 0) {
                return (
                  <React.Fragment key={`${field}-${index}`}>
                    <Grid item xs={12} container direction='row'>
                      <Grid item xs={3} container direction='column'>
                        <Grid item xs>
                          <Typography variant='body2'>Investor</Typography>
                        </Grid>
                        <Grid item xs>
                          <TextField
                            margin='dense'
                            variant='outlined'
                            defaultValue={field.firstName}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={3} container direction='column'>
                        <Grid item xs>
                          <Typography variant='body2'>SIN</Typography>
                        </Grid>
                        <Grid item xs>
                          <TextField
                            margin='dense'
                            variant='outlined'
                            defaultValue={field.sin}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={3} container direction='column'>
                        <Grid item xs>
                          <Typography variant='body2'>Bank Account</Typography>
                        </Grid>
                        <Grid item xs>
                          <FormControl
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          >
                            <Select
                              name='bankName'
                              id='bank-select-outlined'
                              value={bank}
                              onChange={handleBankSelect}
                            >
                              {banks.map((b: IBank) => (
                                <MenuItem
                                  key={b.bankName ? b.bankName : ''}
                                  value={b.bankName ? b.bankName : ''}
                                >
                                  {b.bankName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={`${field}-${index}`}>
                    <Grid item xs={12} container direction='row'>
                      <Grid item xs={3} container direction='column'>
                        <Grid item xs>
                          <Typography variant='body2'>
                            Joint Investor
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <FormControl
                            style={{ minWidth: 195 }}
                            variant='outlined'
                            margin='dense'
                          >
                            <Select
                              name='investorIds'
                              id='investors-select-outlined'
                              value={selectFields[index].firstName}
                              onChange={(e) => handleSelectChange(e, index)}
                            >
                              {investors.map((i: IJointInvestor) => (
                                <MenuItem
                                  key={i.id ? i.id : '123'}
                                  value={i.firstName ? i.firstName : 'first'}
                                >
                                  {i.firstName ? i.firstName : 'first'}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={3} container direction='column'>
                        <Grid item xs>
                          <Typography variant='body2'>SIN</Typography>
                        </Grid>
                        <Grid item xs>
                          <TextField
                            disabled
                            value={selectFields[index].sin}
                            margin='dense'
                            variant='outlined'
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={3} container direction='column'>
                        <Grid item xs />
                        <Grid item xs>
                          <HighlightOffOutlined
                            onClick={() => handleRemoveFields(index)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              }
            })}
            <Grid item xs={12}>
              <Fab
                classes={{ root: classes.investorButton }}
                onClick={() => handleAddFields()}
                variant='extended'
                disabled={listLoading}
              >
                {listLoading ? (
                  'Getting Investors...'
                ) : (
                  <>
                    <Add classes={{ root: classes.bankIcon }} />
                    Add Joint Investors
                  </>
                )}
              </Fab>
            </Grid>
          </Grid>
          {/* Detail Field */}
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12} container direction='row'>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>
                    Bank Draft Receive Date *
                  </Typography>
                </Grid>
                <Grid item xs>
                  <DatePicker
                    clearable
                    format='YYYY-MM-DD'
                    value={dates.depositDate}
                    onChange={(date) => handleDate(date, 'depositDate')}
                    inputVariant='outlined'
                    margin='dense'
                    error={!!errors.depositDate}
                    helperText={errors.depositDate}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>CCO Sign off Date *</Typography>
                </Grid>
                <Grid item xs>
                  <DatePicker
                    clearable
                    format='YYYY-MM-DD'
                    value={dates.ccoDate}
                    onChange={(date) => handleDate(date, 'ccoDate')}
                    inputVariant='outlined'
                    margin='dense'
                    error={!!errors.ccoDate}
                    helperText={errors.ccoDate}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>Investment Amount *</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    id='investmentAmount'
                    defaultValue={
                      account.info.investmentAmount === 0
                        ? ''
                        : account.info.investmentAmount
                    }
                    error={!!errors.investmentAmount}
                    helperText={errors.investmentAmount}
                    onBlur={handleOnBlur}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>Class *</Typography>
                </Grid>
                <Grid item xs>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ minWidth: 195 }}
                    error={!!errors.class}
                  >
                    <Select
                      name='class'
                      id='class-select-outlined'
                      value={aClass}
                      onChange={handleClassSelect}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='A'>A</MenuItem>
                      <MenuItem value='B'>B</MenuItem>
                      <MenuItem value='C'>C</MenuItem>
                      <MenuItem value='D'>D</MenuItem>
                    </Select>
                    <FormHelperText>{errors.class}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container direction='row'>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>
                    Interest Distribution *
                  </Typography>
                </Grid>
                <Grid item xs>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ minWidth: 195 }}
                    error={!!errors.interestDistribution}
                  >
                    <Select
                      name='interestDistribution'
                      id='bank-select-outlined'
                      value={distribution}
                      onChange={handleDistributionSelect}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='Quarterly'>Quarterly</MenuItem>
                      <MenuItem value='Annually'>Annually</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.interestDistribution}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>Rate of Return % *</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    id='interestRate'
                    defaultValue={
                      account.info.interestRate === 0
                        ? ''
                        : account.info.interestRate
                    }
                    error={!!errors.interestRate}
                    helperText={errors.interestRate}
                    onBlur={handleOnBlur}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>Management Fee % *</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    id='managementRate'
                    defaultValue={
                      account.info.managementRate === 0
                        ? ''
                        : account.info.managementRate
                    }
                    error={!!errors.managementRate}
                    helperText={errors.managementRate}
                    onBlur={handleOnBlur}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>Profit Share % *</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    id='psPercentage'
                    defaultValue={
                      account.info.psPercentage === 0
                        ? ''
                        : account.info.psPercentage
                    }
                    error={!!errors.psPercentage}
                    helperText={errors.psPercentage}
                    onBlur={handleOnBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container direction='row'>
              <Grid item xs={3} container direction='column'>
                <Grid item xs>
                  <Typography variant='body2'>Certification No. *</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    margin='dense'
                    variant='outlined'
                    onChange={handleTextField}
                    id='certificateNo'
                    defaultValue={account.info.certificateNo}
                    error={!!errors.certificateNo}
                    helperText={errors.certificateNo}
                    onBlur={handleOnBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <div className={classes.buttonField}>
        <Button
          style={{ marginRight: 20 }}
          classes={{ root: classes.cancel }}
          variant='outlined'
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          style={{ marginRight: 20 }}
          onClick={handleBack}
          classes={{ root: classes.submit }}
          variant='outlined'
        >
          Previous
        </Button>
        <Button
          disabled={loading}
          classes={{ root: classes.submit }}
          variant='outlined'
          type='submit'
        >
          Submit
        </Button>
      </div>
    </>
  )
}

export default AccountForm
