import React, { useState } from 'react'
import { useStyles } from './user.style'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import { Add, EditOutlined, HighlightOffOutlined } from '@material-ui/icons'
import axios from 'axios'
import { PROD_AUTH, AUTH_KEY } from '../../utils/constants'
import { AddUserForm } from './form/add.user.form'
import { EditUserForm } from './form/edit.user.form'
import {
  TableLoading,
  DialogLoading,
} from '../../components/loading/loading.component'
import { ErrorPage } from '../../components/error/error.component'
import { AuthContext } from '../../providers/auth/auth.provider'
import { NewUserDialog } from './components/user.dialog.component'

export interface IUserValues {
  name: string
  department: string
  role: string
  email: string
  id: string
}

interface IDialogs {
  create: boolean
  update: boolean
  delete: boolean
}

const UserPage = () => {
  const INITIAL_DATA: IUserValues = {
    id: '',
    name: '',
    department: '',
    role: '',
    email: '',
  }

  const classes = useStyles()
  const [rows, $rows] = useState<IUserValues[]>([])
  const [dialogs, $dialogs] = useState<IDialogs>({
    create: false,
    update: false,
    delete: false,
  })
  const [selectedUser, $selectedUser] = useState<IUserValues>(INITIAL_DATA)
  const [initialLoading, $initialLoading] = useState(false)
  const [loading, $loading] = useState<boolean>(false)
  const [axiosError, $axiosError] = useState<boolean>(false)
  const { token } = React.useContext(AuthContext)

  const openDialog = (type: string, selected?: IUserValues) => {
    if (selected) {
      $selectedUser(selected)
    }

    $dialogs({ ...dialogs, [type]: true })
  }

  const cancelDialog = (type: string) => {
    $dialogs({ ...dialogs, [type]: false })
  }

  const closeDialog = (type: string) => {
    $selectedUser(INITIAL_DATA)
    $dialogs({ ...dialogs, [type]: false })
  }

  const onDelete = (id: string) => {
    $loading(true)
    const body = {
      operation: 'delete',
      userId: id,
    }
    axios
      .put(`${PROD_AUTH}/user/info`, body, {
        headers: { 'x-api-key': AUTH_KEY, Authorization: token },
      })
      .then((res) => {
        $loading(false)
        closeDialog('delete')
      })
  }

  const createData = ({ name, department, role, email, id }: IUserValues) => {
    return { name, department, role, email, id }
  }

  React.useEffect(() => {
    const loadData = async () => {
      $initialLoading(true)
      var list: IUserValues[] = []
      try {
        const res = await axios.get(`${PROD_AUTH}/user/list`, {
          headers: { 'x-api-key': AUTH_KEY, Authorization: token },
        })
        const users: IUserValues[] = res.data.users
        users.map((user) => {
          const generate: IUserValues = createData(user)
          list.push(generate)
        })
        $rows(list)
        $initialLoading(false)
      } catch {
        $axiosError(true)
        $initialLoading(false)
      }
    }
    loadData()
  }, [dialogs])

  const [cOpen, $cOpen] = React.useState<boolean>(false)
  const cOnClose = () => {
    $cOpen(false)
    window.location.reload()
  }

  return axiosError ? (
    <ErrorPage />
  ) : (
    <div className={classes.root}>
      <NewUserDialog open={cOpen} onClose={cOnClose} />
      <div className={classes.toolbarBg}>User Management</div>
      <div
        className={classes.toolbar}
        style={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingRight: 80,
        }}
      >
        <Button
          classes={{ root: classes.button }}
          onClick={() => $cOpen(true)}
          variant='contained'
        >
          {' '}
          <Add /> New User{' '}
        </Button>
        <Dialog
          classes={{ paper: classes.dialog }}
          fullWidth
          maxWidth='sm'
          open={dialogs.create}
          onClose={() => closeDialog('create')}
          aria-labelledby='add-new-user'
        >
          <DialogTitle id='form-dialog-title'>Add User</DialogTitle>
          <AddUserForm cancelDialog={cancelDialog} closeDialog={closeDialog} />
        </Dialog>
      </div>
      {initialLoading ? (
        <TableLoading />
      ) : (
        <TableContainer component={Paper} classes={{ root: classes.boxShadow }}>
          <Table className={classes.table} aria-label='user table'>
            <TableHead classes={{ root: classes.background }}>
              <TableRow>
                <TableCell align='center'>Name</TableCell>
                <TableCell align='center'>Department</TableCell>
                <TableCell align='center'>Permissions</TableCell>
                <TableCell align='center'>Email</TableCell>
                <TableCell align='center'>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component='th' align='center' scope='row'>
                      {row.name}
                    </TableCell>
                    <TableCell align='center'>{row.department}</TableCell>
                    <TableCell align='center'>{row.role}</TableCell>
                    <TableCell align='center'>{row.email}</TableCell>
                    <TableCell
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      align='center'
                    >
                      <EditOutlined
                        style={{ marginRight: 8 }}
                        onClick={() => openDialog('update', row)}
                      />{' '}
                      {row.role === 'owner' ? (
                        ''
                      ) : (
                        <>
                          |{' '}
                          <HighlightOffOutlined
                            style={{ marginLeft: 8 }}
                            onClick={() => openDialog('delete', row)}
                          />
                        </>
                      )}
                      <Dialog
                        classes={{ paper: classes.dialog }}
                        fullWidth
                        maxWidth='sm'
                        open={dialogs.update}
                        onClose={() => closeDialog('update')}
                        aria-labelledby='add-new-user'
                      >
                        <DialogTitle id='form-dialog-title'>Edit</DialogTitle>
                        <EditUserForm
                          cancelDialog={cancelDialog}
                          closeDialog={closeDialog}
                          selected={selectedUser}
                        />
                      </Dialog>
                      <Dialog
                        open={dialogs.delete}
                        aria-labelledby='delete-dialog-title'
                        aria-describedby='delete-dialog-description'
                      >
                        <DialogTitle id='delete-dialog-title'>
                          {`Delete User?`}
                        </DialogTitle>
                        <DialogContent>
                          {true ? (
                            <DialogLoading />
                          ) : (
                            <DialogContentText id='delete-dialog-description'>
                              {`Are you sure you want to delete the user?`}
                            </DialogContentText>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => closeDialog('delete')}
                            color='primary'
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={loading}
                            onClick={() => onDelete(selectedUser.id)}
                            color='primary'
                            autoFocus
                          >
                            Agree
                          </Button>
                        </DialogActions>
                      </Dialog>{' '}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default UserPage
