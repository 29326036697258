import React from 'react'
import { Paper, Grid, Typography, TextField, Button } from '@material-ui/core'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import Logo from '../../assets/logo.png'

import { PROD_AUTH, AUTH_KEY } from '../../utils/constants'
import { TableLoading } from '../../components/loading/loading.component'
import { useStyles } from './login.style'
import { AuthContext } from '../../providers/auth/auth.provider'

type FormData = {
  email: string
  password: string
}

const LoginPage = () => {
  const classes = useStyles()
  const [loading, $loading] = React.useState<boolean>(false)
  const [errors, $errors] = React.useState<string>('')
  const { setUser, setToken, setAuthorized } = React.useContext(AuthContext)

  const { handleSubmit, register } = useForm<FormData>()
  const onSubmit = async (data: FormData) => {
    $loading(true)
    try {
      const res = await axios.post(`${PROD_AUTH}/user/login`, data, {
        headers: { 'x-api-key': AUTH_KEY },
      })
      if (res.status === 200) {
        const auth = res.data

        axios
          .post(`${PROD_AUTH}/user/auth`, auth, {
            headers: { 'x-api-key': AUTH_KEY },
          })
          .then((res) => {
            window.sessionStorage.setItem('token', res.data.jwtToken)
            window.sessionStorage.setItem('status', 'true')
            window.sessionStorage.setItem('user', JSON.stringify(auth))
            setToken(res.data.jwtToken)
            setUser(auth)
            setAuthorized(true)
            $loading(false)
          })
      }
    } catch (e) {
      $errors('Invalid Email/Password')
      $loading(false)
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      $errors('')
    }, 2000)
  }, [errors])

  return (
    <Paper className={classes.background}>
      <Paper className={classes.loginBox}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction='column' alignItems='center' spacing={3}>
            <Grid item xs={12}>
              <img src={Logo} alt='logo' style={{ margin: 'auto' }} />
            </Grid>
            <Grid item xs={12} container direction='column'>
              {loading ? (
                <TableLoading />
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography variant='body1'>Email</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name='username'
                      margin='dense'
                      size='small'
                      variant='outlined'
                      placeholder='admin@vanmortgage.com'
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>Password</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name='password'
                      margin='dense'
                      size='small'
                      variant='outlined'
                      type='password'
                      inputRef={register}
                    />
                  </Grid>
                  <Grid className={classes.errors} item xs={12}>
                    {errors && errors}
                  </Grid>
                  <div style={{ height: 32 }} />
                  <Grid item xs={12}>
                    <Button
                      classes={{ root: classes.submit }}
                      variant='outlined'
                      type='submit'
                      fullWidth
                    >
                      Log In
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Paper>
  )
}

export default LoginPage
