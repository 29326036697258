import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body { 
    margin: 0;
    height: 100vh;
    width: 100vw;
  }

  #root {
    display: flex;
    height: 100%;
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }
`
