import React from 'react'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import { CheckboxType, IValues } from '../../../utils/types'
import { useStyles } from './investor.info.style'
import { NAVY } from '../../../styles/color'
import { Moment } from 'moment'
import { DatePicker } from '@material-ui/pickers'

const InvestorInfoForm = ({
  checkbox,
  handleCheckbox,
  inputLabel,
  gender,
  handleGenderSelect,
  handleTextField,
  labelWidth,
  investor,
  handleSuperAccount,
  belongsTo,
  superAccount,
  errors,
  handleOnBlur,
  birthDay,
  handleDate,
  contactName,
}: {
  checkbox: CheckboxType
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputLabel: any
  gender: string
  handleGenderSelect: (event: React.ChangeEvent<{ value: unknown }>) => void
  handleTextField: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  labelWidth: number
  investor: IValues
  handleSuperAccount: (event: React.ChangeEvent<{ value: unknown }>) => void
  belongsTo: any[]
  superAccount: string
  errors: IValues
  birthDay: Moment | null
  handleDate: (dateObj: Moment | null, name: string) => void
  contactName: string
}) => {
  const classes = useStyles()

  return (
    <>
      {/* Investor Type Field */}
      <Grid item xs={12} container alignItems='center'>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: NAVY }}
                checked={checkbox.individual}
                onChange={handleCheckbox}
                size='small'
                value='individual'
              />
            }
            label='Individual'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: NAVY }}
                checked={checkbox.entity}
                onChange={handleCheckbox}
                size='small'
                value='entity'
              />
            }
            label='Entity'
            labelPlacement='end'
          />
        </Grid>

        <Grid
          style={
            investor.info.investorType === null ||
            investor.info.investorType === ''
              ? { color: 'red' }
              : { color: 'black' }
          }
          item
          xs
        >
          (Please Choose the Investor Type First)
        </Grid>
      </Grid>

      {/* Personal Info Field */}
      {investor.info.investorType === null ||
      investor.info.investorType === '' ? (
        ''
      ) : (
        <>
          <Grid item xs={12} container direction='row' spacing={2}>
            {investor.info.investorType === 'entity' ? (
              <>
                <Grid item xs={2}>
                  <TextField
                    onChange={handleTextField}
                    id='companyName'
                    disabled={checkbox.individual}
                    label='Company Name'
                    required
                    margin='dense'
                    variant='outlined'
                    defaultValue={investor.info.companyName}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputMask
                    disabled={checkbox.individual}
                    mask='999-999-9999'
                    required
                    maskChar=' '
                    onChange={handleTextField}
                    defaultValue={investor.info.businessNumber}
                  >
                    {() => (
                      <TextField
                        onChange={handleTextField}
                        id='businessNumber'
                        label='Business No'
                        disabled={checkbox.individual}
                        type='text'
                        margin='dense'
                        variant='outlined'
                        defaultValue={investor.info.businessNumber}
                      />
                    )}
                  </InputMask>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={2}>
                  <TextField
                    required
                    classes={{ root: classes.root }}
                    onChange={handleTextField}
                    id='firstName'
                    label='Name'
                    margin='dense'
                    variant='outlined'
                    disabled={checkbox.entity}
                    defaultValue={investor.info.firstName}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    onBlur={handleOnBlur}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <TextField
                    onChange={handleTextField}
                    id='middleName'
                    label='Middle Name'
                    margin='dense'
                    variant='outlined'
                    disabled={checkbox.entity}
                    defaultValue={investor.info.middleName}
                  />
                </Grid> */}
                <Grid item xs={2}>
                  <TextField
                    onChange={handleTextField}
                    id='lastName'
                    label='Name (Optional)'
                    margin='dense'
                    variant='outlined'
                    disabled={checkbox.entity}
                    defaultValue={
                      investor.info.lastName ? investor.info.lastName : ''
                    }
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    onBlur={handleOnBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ minWidth: 100 }}
                    disabled={checkbox.entity}
                  >
                    <InputLabel ref={inputLabel} id='select-gender'>
                      Gender
                    </InputLabel>
                    <Select
                      labelId='select-gender'
                      name='gender'
                      required
                      value={gender}
                      onChange={handleGenderSelect}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='Male'>Male</MenuItem>
                      <MenuItem value='Female'>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <DatePicker
                    clearable
                    disableFuture
                    openTo='year'
                    format='YYYY-MM-DD'
                    label='Date of birth'
                    views={['year', 'month', 'date']}
                    value={birthDay}
                    onChange={(date) => handleDate(date, 'birthDay')}
                    inputVariant='outlined'
                    margin='dense'
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputMask
                    mask='999-999-999'
                    maskChar=' '
                    onChange={handleTextField}
                    disabled={checkbox.entity}
                    defaultValue={investor.info.sin}
                    onBlur={handleOnBlur}
                  >
                    {() => (
                      <TextField
                        onChange={handleTextField}
                        id='sin'
                        label='SIN'
                        type='text'
                        margin='dense'
                        required
                        variant='outlined'
                        disabled={checkbox.entity}
                        defaultValue={investor.info.sin}
                        error={!!errors.sin}
                        helperText={errors.sin ? errors.sin : 'ex. 123-456-789'}
                        onBlur={handleOnBlur}
                      />
                    )}
                  </InputMask>
                </Grid>
              </>
            )}
          </Grid>
          {/* Contact Info Field */}
          <Grid item xs={12} container direction='row' spacing={2}>
            <Grid item xs={3}>
              <TextField
                onChange={handleTextField}
                id='contactName'
                label='Contact Name'
                required
                margin='dense'
                variant='outlined'
                value={contactName}
                error={!!errors.contactName}
                helperText={errors.contactName}
                onBlur={handleOnBlur}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleTextField}
                id='authorizerName'
                label='Third Party Authorize Name'
                margin='dense'
                variant='outlined'
                fullWidth
                defaultValue={investor.info.authorizerName}
                error={!!errors.authorizerName}
                helperText={errors.authorizerName}
                onBlur={handleOnBlur}
              />
            </Grid>
            <Grid item xs={2}>
              <InputMask
                mask='999-999-9999'
                maskChar=' '
                onChange={handleTextField}
                defaultValue={investor.info.phone}
                onBlur={handleOnBlur}
              >
                {() => (
                  <TextField
                    onChange={handleTextField}
                    id='phone'
                    required
                    label='Mobile'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    defaultValue={investor.info.phone}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    onBlur={handleOnBlur}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={2}>
              <TextField
                onChange={handleTextField}
                id='email'
                label='Email'
                margin='dense'
                type='email'
                variant='outlined'
                defaultValue={investor.info.email}
              />
            </Grid>
            <Grid item xs={2}>
              {/* <TextField
                onChange={handleTextField}
                id='email'
                label='Email'
                margin='dense'
                required
                type='email'
                variant='outlined'
                defaultValue={investor.info.email}
                error={!!errors.email}
                helperText={errors.email}
                onBlur={handleOnBlur}
              /> */}
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleTextField}
                id='fileNo'
                label='File Number'
                margin='dense'
                variant='outlined'
                defaultValue={investor.info.fileNo}
              />
            </Grid>
            <Grid item xs={9}>
              <FormControl
                variant='outlined'
                margin='dense'
                style={{ minWidth: 150 }}
              >
                <InputLabel ref={inputLabel} id='select-superAccount'>
                  Super Account
                </InputLabel>
                <Select
                  labelId='select-superAccount'
                  name='belongsTo'
                  required
                  value={superAccount !== undefined ? superAccount : ''}
                  onChange={handleSuperAccount}
                  labelWidth={labelWidth}
                >
                  {belongsTo &&
                    belongsTo.map((b: any, idx: number) => (
                      <MenuItem key={idx} value={b.id}>
                        {b.firstName !== '' ? b.firstName : b.companyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleTextField}
                id='address'
                label='Address'
                margin='dense'
                variant='outlined'
                fullWidth
                required
                defaultValue={investor.info.address}
                error={!!errors.address}
                helperText={errors.address}
                onBlur={handleOnBlur}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                onChange={handleTextField}
                id='city'
                label='City'
                margin='dense'
                required
                variant='outlined'
                defaultValue={investor.info.city}
                error={!!errors.city}
                helperText={errors.city}
                onBlur={handleOnBlur}
              />
            </Grid>
            <Grid item xs={2}>
              <InputMask
                mask='aa'
                maskChar=' '
                formatChars={{ a: '[A-Z]' }}
                onChange={handleTextField}
                defaultValue={investor.info.province}
                onBlur={handleOnBlur}
              >
                {() => (
                  <TextField
                    onChange={handleTextField}
                    id='province'
                    label='Province'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    required
                    defaultValue={investor.info.province}
                    error={!!errors.province}
                    helperText={errors.province ? errors.province : 'ex. BC'}
                    onBlur={handleOnBlur}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={2}>
              <InputMask
                mask='a9a 9a9'
                maskChar=' '
                formatChars={{ a: '[A-Z]', 9: '[0-9]' }}
                onChange={handleTextField}
                defaultValue={investor.info.postCode}
                onBlur={handleOnBlur}
              >
                {() => (
                  <TextField
                    onChange={handleTextField}
                    id='postCode'
                    label='Postal Code'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    required
                    defaultValue={investor.info.postCode}
                    error={!!errors.postCode}
                    helperText={
                      errors.postCode ? errors.postCode : 'ex. A1A 1A1'
                    }
                    onBlur={handleOnBlur}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default InvestorInfoForm
