import React from 'react'
import { ReactComponent as Spin96 } from '../../assets/spin96.svg'
import { ReactComponent as LoadingSmall } from '../../assets/loadingSmall.svg'

export const LoadingB = () => (
  <div
    style={{
      height: '93%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Spin96 />
  </div>
)

export const TableLoading = () => (
  <div
    style={{
      height: 'calc(100% - 192px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Spin96 />
  </div>
)

export const DialogLoading = () => (
  <div
    style={{
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Spin96 />
  </div>
)

export const FixedHeightLoading = () => (
  <div
    style={{
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Spin96 />
  </div>
)

export const LoadingS = () => (
  <div
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <LoadingSmall />
  </div>
)
