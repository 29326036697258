import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&$disabled': {
        background: 'gray'
      }
    },
    disabled: {}
  })
)
