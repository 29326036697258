import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { useStyles } from './account.reminder.view.style'
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { EditOutlined } from '@material-ui/icons'
import AccountDetail from '../components/account.detail.component'
import {
  IAccountInfo,
  ITransaction,
  DEFAULT_ACCOUNT_INFO,
  DEFAULT_TRANSACTION,
  IAccountDateFields,
  DEFAULT_ACCOUNT_DATE_FIELDS,
  DEFAULT_INVESTOR_INFO,
} from '../helper/investor.helper'
import { IValues } from '../../../utils/types'
import { formatter } from '../../../utils/format'
import { AuthContext } from '../../../providers/auth/auth.provider'
import {
  RedeemDialog,
  PartialRedeemDialog,
  TransferLPDialog,
  RedeemSuccessDialog,
} from '../components/account.dialogs.component'

interface ISelectValues {
  interestDistribution: string
  class: string
}

interface IDialogs {
  transaction: boolean
  redeem: boolean
  partialRedeem: boolean
  transfer: boolean
}

interface IRedeemed {
  date: string
  principle: number
  interest: number
  total: number
}

const AccountReminderView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { accountId } = useParams()
  const [loading, $loading] = useState<boolean>(false)
  const [edit, $edit] = useState<boolean>(false)
  const [paymentMethod, $paymentMethod] = useState<string>('')
  const { token } = React.useContext(AuthContext)

  const [selectValues, $selectValues] = useState<ISelectValues>({
    interestDistribution: '',
    class: '',
  })
  const [accountInfo, $accountInfo] = useState<IAccountInfo>(
    DEFAULT_ACCOUNT_INFO
  )
  const [selectedTransaction, $selectedTransaction] = useState<ITransaction>(
    DEFAULT_TRANSACTION
  )
  const [dates, $dates] = useState<IAccountDateFields>(
    DEFAULT_ACCOUNT_DATE_FIELDS
  )
  const [rows, $rows] = useState<ITransaction[]>([])
  const [dialogs, $dialogs] = useState<IDialogs>({
    transaction: false,
    redeem: false,
    partialRedeem: false,
    transfer: false,
  })
  const [banks, $banks] = useState<any>([])
  const [dialogSubmit, setDialogSubmit] = useState<boolean>(false)

  const [values, $values] = useState<IValues>({})

  const [successDialog, $successDialog] = useState<boolean>(false)
  const [loadedInvestor, $loadedInvestor] = useState<any>(DEFAULT_INVESTOR_INFO)

  const [redeemDate, $redeemDate] = useState<Moment | null>(null)
  const [partialDate, $partialDate] = useState<Moment | null>(null)
  const [transferDate, $transferDate] = useState<Moment | null>(null)
  const [transaction, $transaction] = useState<IValues>({})
  const [redeemValues, $redeemValues] = useState<IValues>({ date: '' })
  const [partialValues, $partialValues] = useState<IValues>({
    date: '',
    redeemAmount: '',
  })
  const [transferValues, $transferValues] = useState<IValues>({
    date: '',
    transfereeId: '',
    newCertificateNo: '',
    bankName: '',
    bankAccountNo: '',
  })
  const [dialogErrors, $dialogErrors] = useState<IValues>({})
  const [redeemedData, $redeemdData] = useState<IRedeemed>({
    date: '',
    principle: 0,
    interest: 0,
    total: 0,
  })
  const [bank, $bank] = React.useState<string>('')
  const [investorId, $investorId] = useState<string>('')
  const [transactionL, $transactionL] = useState<boolean>(false)
  const [investors, $investors] = useState<any>([])

  const setValues = (value: IValues) => {
    $values({ ...values, ...value })
  }

  const setTransaction = (value: IValues) => {
    $transaction({ ...transaction, ...value })
  }
  const setRedeemValues = (value: IValues) => {
    $redeemValues({ ...redeemValues, ...value })
  }
  const setPartialValues = (value: IValues) => {
    $partialValues({ ...partialValues, ...value })
  }
  const setTransferValues = (value: IValues) => {
    $transferValues({ ...transferValues, ...value })
  }

  const setDialogErrors = (error: IValues) => {
    $dialogErrors({ ...dialogErrors, ...error })
  }

  const handleTransactionText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setTransaction({
      ...transaction.transaction,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleTransactionSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    const value = event.target.value as string
    $paymentMethod(event.target.value as string)
    setTransaction({
      ...transaction.transaction,
      [name]: event.target.value as string,
    })
  }

  const handleRedeemDate = (dateObj: Moment | null) => {
    if (dateObj === null) {
      $redeemDate(dateObj)
      setRedeemValues({
        ...redeemValues,
        date: '',
      })
    } else {
      $redeemDate(dateObj)
      setRedeemValues({
        ...redeemValues,
        date: dateObj?.format('YYYY-MM-DD'),
      })
    }
  }

  const handlePartialDate = (dateObj: Moment | null) => {
    if (dateObj === null) {
      $partialDate(dateObj)
      setPartialValues({
        ...partialValues,
        date: '',
      })
    } else {
      $partialDate(dateObj)
      setPartialValues({
        ...partialValues,
        date: dateObj?.format('YYYY-MM-DD'),
      })
    }
  }

  const handleTransferDate = (dateObj: Moment | null) => {
    if (dateObj === null) {
      $transferDate(dateObj)
      setTransferValues({
        ...transferValues,
        date: '',
      })
    } else {
      $transferDate(dateObj)
      setTransferValues({
        ...transferValues,
        date: dateObj?.format('YYYY-MM-DD'),
      })
    }
  }

  const handleRedeemText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    let value = e.currentTarget.value as string

    setRedeemValues({
      ...redeemValues,
      [name]: value,
    })
  }
  const handlePartialText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    let value = e.currentTarget.value as string
    let numValue: number = 0
    if (name === 'redeemAmount') {
      numValue = Number(value)
      setPartialValues({
        ...partialValues,
        [name]: numValue,
      })
    } else {
      setPartialValues({
        ...partialValues,
        [name]: value,
      })
    }
  }
  const handleTransferText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    let value = e.currentTarget.value as string

    setTransferValues({
      ...transferValues,
      [name]: value,
    })
  }

  const handleDialogBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    let numValue: number
    let newError: IValues = {}

    if (name === 'redeemAmount') {
      numValue = Number(value)
      if (numValue > accountInfo.investmentAmount) {
        newError[
          name
        ] = `Amount must be smaller than $${accountInfo.investmentAmount}`
      } else {
        newError[name] = null
      }
    } else if (!moment(value, 'YYYY-MM-DD').isSameOrAfter()) {
      newError[name] = `Date needs to be equal of after ${moment().format(
        'YYYY-MM-DD'
      )}`
    } else {
      newError[name] = null
    }

    setDialogErrors(newError)
  }

  const handleDialogBank = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    let value = event.target.value as string
    const bankName = value.split('#')[0]
    const bankAccountNo = value.split('#')[1]
    $bank(value)
    setTransferValues({ ...transferValues, bankName, bankAccountNo })
  }

  const handleDialogInvestor = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $investorId(event.target.value as string)
    setTransferValues({
      ...transferValues,
      [name]: event.target.value as string,
    })
  }

  const openDialog = (type: string, transaction?: ITransaction) => {
    if (transaction) {
      $selectedTransaction(transaction)
    }
    $dialogs({ ...dialogs, [type]: true })
  }

  const openSuccessDialog = () => {
    $successDialog(true)
  }

  const closeSuccessDialog = () => {
    $successDialog(false)
  }

  const cancelDialog = (type: string) => {
    $selectedTransaction(DEFAULT_TRANSACTION)
    $redeemValues({})
    $partialValues({})
    $transferValues({})
    $dialogs({
      transaction: false,
      redeem: false,
      partialRedeem: false,
      transfer: false,
    })
    $dialogErrors({})
  }

  const transactionSubmit = async (type: string) => {
    $transactionL(true)
    const body = {
      transactionId: selectedTransaction.id,
      transaction: transaction,
    }
    const res = await axios.put(`${PROD_OPS}/investment/transaction`, body, {
      headers: { 'x-api-key': OPS_KEY, Authorization: token },
    })
    if (res.status === 200) {
      $transactionL(false)
      closeDialog(type)
    } else {
    }
  }

  const handleRedeemSubmit = async (type: string) => {
    if (!validateRedeemDialog()) {
      const body = {
        accountId: accountInfo.id,
        ...redeemValues,
      }
      const res = await axios.post(`${PROD_OPS}/investment/${type}`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        closeDialog(type)
        $dialogErrors({})
        if (type === 'redeem') {
          $redeemdData({
            date: res.data.date,
            principle: res.data.principle,
            interest: res.data.interest,
            total: res.data.total,
          })
          $dialogErrors({})
          openSuccessDialog()
        }
      }
    }
  }
  const handlePartialSubmit = async (type: string) => {
    if (!validatePartialDialog()) {
      const body = {
        accountId: accountInfo.id,
        ...partialValues,
      }
      const res = await axios.post(`${PROD_OPS}/investment/${type}`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        closeDialog(type)
        $dialogErrors({})
      }
    }
  }
  const handleTransferSubmit = async (type: string) => {
    if (!validateTransferDialog()) {
      const body = {
        accountId: accountInfo.id,
        ...transferValues,
      }

      const res = await axios.post(`${PROD_OPS}/investment/${type}`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      if (res.status === 200) {
        closeDialog(type)
        $dialogErrors({})
      }
    }
  }

  const validateRedeemDialog = () => {
    let newErrors: IValues = dialogErrors
    let haveError: boolean = false

    Object.keys(redeemValues).map((fieldName: string) => {
      if (redeemValues[fieldName] === null || redeemValues[fieldName] === '') {
        newErrors[fieldName] = newErrors[fieldName]
          ? `${newErrors[fieldName]}, Required`
          : 'Required'
      }
    })
    setDialogErrors(newErrors)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== null) {
        haveError = true
      }
    })

    return haveError
  }

  const validatePartialDialog = () => {
    let newErrors: IValues = dialogErrors
    let haveError: boolean = false

    Object.keys(partialValues).map((fieldName: string) => {
      if (
        partialValues[fieldName] === null ||
        partialValues[fieldName] === ''
      ) {
        newErrors[fieldName] = newErrors[fieldName]
          ? `${newErrors[fieldName]}, Required`
          : 'Required'
      }
    })
    setDialogErrors(newErrors)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== null) {
        haveError = true
      }
    })

    return haveError
  }
  const validateTransferDialog = () => {
    let newErrors: IValues = dialogErrors
    let haveError: boolean = false

    Object.keys(transferValues).map((fieldName: string) => {
      if (
        transferValues[fieldName] === null ||
        transferValues[fieldName] === ''
      ) {
        newErrors[fieldName] = newErrors[fieldName]
          ? `${newErrors[fieldName]}, Required`
          : 'Required'
      }
    })
    setDialogErrors(newErrors)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== null) {
        haveError = true
      }
    })

    return haveError
  }

  const closeDialog = (type: string) => {
    $selectedTransaction(DEFAULT_TRANSACTION)
    $redeemValues({})
    $partialValues({})
    $transferValues({})
    $dialogs({ ...dialogs, [type]: false })
  }

  const setEdit = () => {
    $edit(!edit)
  }

  // const openDialog = (type: string, transaction?: ITransaction) => {
  //   if (transaction) {
  //     $selectedTransaction(transaction)
  //   }
  //   $dialogs({ ...dialogs, [type]: true })
  // }

  // const cancelDialog = () => {
  //   $selectedTransaction(DEFAULT_TRANSACTION)
  //   $dialog(false)
  // }
  // const closeDialog = () => {
  //   $selectedTransaction(DEFAULT_TRANSACTION)
  //   $dialog(false)
  // }

  // const transactionSubmit = async (type: string) => {
  //   const body = {
  //     transactionId: selectedTransaction.id,
  //     transaction: transaction,
  //   }
  //   const res = await axios.put(`${PROD_OPS}/investment/transaction`, body, {
  //     headers: { 'x-api-key': OPS_KEY, Authorization: token },
  //   })
  //   if (res.status === 200) {
  //     closeDialog()
  //   } else {

  //   }
  // }

  // const handleTransactionText = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault()
  //   setTransaction({
  //     ...transaction.transaction,
  //     [e.currentTarget.id]: e.currentTarget.value,
  //   })
  // }

  // const handleTransactionSelect = (
  //   event: React.ChangeEvent<{ name?: string; value: unknown }>
  // ) => {
  //   const name = event.target.name as string
  //   $paymentMethod(event.target.value as string)
  //   setTransaction({
  //     ...transaction.transaction,
  //     [name]: event.target.value as string,
  //   })
  // }

  // useEffect(() => {
  //   const loadData = async () => {
  //     try {
  //       const res = await axios.get(`${PROD_OPS}/investor/info/${userId}`, {
  //         headers: { 'x-api-key': OPS_KEY, Authorization: token }
  //       })
  //       if (res.status === 200) {
  //         $loadedInvestor(res.data.investor)
  //         $banks(res.data.investor.banks)
  //       }
  //     } catch (e) {

  //     }
  //   }
  //   loadData()
  // }, [edit, dialogSubmit, userId])

  useEffect(() => {
    const loadData = async () => {
      let list: ITransaction[] = []
      const res = await axios.get(`${PROD_OPS}/investment/info/${accountId}`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })

      if (res.status === 200) {
        console.log(res.data)
        $accountInfo(res.data.account)
        $dates({
          depositDate: moment(res.data.account.depositDate, 'YYYY-MM-DD'),
          ccoDate: moment(res.data.account.ccoDate, 'YYYY-MM-DD'),
        })
        res.data.account.investmentTransactions.map((a: any) => {
          const generate: ITransaction = createData(a)
          list.push(generate)
        })

        $rows(list)
      } else {
      }
    }
    loadData()
  }, [dialogs])

  const createData = ({
    id,
    transactionDate,
    type,
    amount,
    referenceNo,
    method,
    notes,
  }: ITransaction) => {
    return { id, transactionDate, type, amount, referenceNo, method, notes }
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues({
      account: {
        ...values.account,
        [e.currentTarget.id]: e.currentTarget.value,
      },
    })
  }

  const handleSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $selectValues({
      ...selectValues,
      [name]: event.target.value as string,
    })
    setValues({
      account: { ...values.account, [name]: event.target.value as string },
    })
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    let list = dates
    list = { ...list, [name]: dateObj }
    $dates(list)

    setValues({
      account: {
        ...values.account,
        [name]: dateObj !== null ? dateObj.format('YYYY-MM-DD') : '',
      },
    })
  }

  React.useEffect(() => {
    const loadInvestors = async () => {
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      $investors(res.data.investors)
    }
    loadInvestors()
  }, [])

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    axios
      .put(`${PROD_OPS}/investment/info`, values, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      .then((res) => {
        if (res.status === 201) {
          history.push('/investors')
        } else {
        }
      })
  }

  return (
    <>
      <div className={classes.toolbar}>
        Account View for Certificate No. {accountInfo.certificateNo}
      </div>
      <RedeemDialog
        dialogOpen={dialogs.redeem}
        closeDialog={closeDialog}
        cancelDialog={cancelDialog}
        handleDialogText={handleRedeemText}
        handleDialogSubmit={handleRedeemSubmit}
        dialogSubmit={dialogSubmit}
        handleDialogBlur={handleDialogBlur}
        dialogErrors={dialogErrors}
        date={redeemDate}
        handleDate={handleRedeemDate}
      />
      <PartialRedeemDialog
        dialogOpen={dialogs.partialRedeem}
        closeDialog={closeDialog}
        cancelDialog={cancelDialog}
        handleDialogText={handlePartialText}
        handleDialogSubmit={handlePartialSubmit}
        dialogSubmit={dialogSubmit}
        handleDialogBlur={handleDialogBlur}
        dialogErrors={dialogErrors}
        date={partialDate}
        handleDate={handlePartialDate}
      />
      <TransferLPDialog
        dialogOpen={dialogs.transfer}
        closeDialog={closeDialog}
        cancelDialog={cancelDialog}
        handleDialogText={handleTransferText}
        handleDialogSubmit={handleTransferSubmit}
        banks={banks}
        bank={bank}
        handleDialogBank={handleDialogBank}
        investors={investors}
        investorId={investorId}
        handleDialogInvestor={handleDialogInvestor}
        dialogSubmit={dialogSubmit}
        handleDialogBlur={handleDialogBlur}
        dialogErrors={dialogErrors}
        date={transferDate}
        handleDate={handleTransferDate}
        loadedInvestor={loadedInvestor}
      />
      <RedeemSuccessDialog
        dialogOpen={successDialog}
        cancelDialog={closeSuccessDialog}
        redeemedData={redeemedData}
      />
      <form
        style={{
          width: '100%',
          minHeight: 'calc(100% - 136px)',
          position: 'relative',
          padding: 32,
        }}
        onSubmit={handleSubmit}
      >
        <Box component='div' className={classes.box}>
          {loading ? (
            'Loading Account Information...'
          ) : (
            <>
              <Grid container direction='column' spacing={3}>
                <Grid item xs={12} container justify='flex-end'>
                  {edit ? (
                    <Button
                      classes={{ root: classes.submit }}
                      style={{ height: 40 }}
                      variant='outlined'
                      type='submit'
                    >
                      Save Account
                    </Button>
                  ) : (
                    <Typography
                      onClick={setEdit}
                      className={classes.editIcon}
                      variant='body1'
                    >
                      {/* <EditOutlined /> Edit */}
                    </Typography>
                  )}
                </Grid>
                {edit ? (
                  <AccountDetail
                    handleTextField={handleTextField}
                    aClass={selectValues.class}
                    handleClassSelect={handleSelect}
                    distribution={selectValues.interestDistribution}
                    handleDistributionSelect={handleSelect}
                    account={accountInfo}
                    dates={dates}
                    handleDate={handleDate}
                  />
                ) : (
                  <>
                    <Grid item xs={12} container direction='row' spacing={2}>
                      <Grid
                        item
                        xs={6}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Contact Name:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo?.investor?.contactName}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Phone:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo?.investor?.phone}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' spacing={2}>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Certificate No:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.certificateNo}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Interest Start Date:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.startDate}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Mature Date:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.matureDate}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Redemption Date:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.endDate}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' spacing={2}>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Bank Draft Received Date:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.depositDate}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          CCO Sign off Date:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.ccoDate}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Investment Amount:{' '}
                          <span className={classes.fieldValue}>
                            {formatter.format(accountInfo.investmentAmount)}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Bank Account:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.bankName} {accountInfo.bankAccountNo}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' spacing={2}>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Interest Distribution:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.interestDistribution}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Rate of Return:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.interestRate} %
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Management Fee:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.managementRate} %
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Status:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.status}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Class:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.class}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' spacing={2}>
                      <Grid
                        item
                        xs={3}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Profit Share:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.psPercentage}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body1'>
                          Class:{' '}
                          <span className={classes.fieldValue}>
                            {accountInfo.class}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                <Grid item xs={12} container direction='row'>
                  <Grid item xs={4}>
                    <Typography variant='body1'>
                      Account Transactions:
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer
                    component={Paper}
                    classes={{ root: classes.boxShadow }}
                  >
                    <Table className={classes.table} aria-label='user table'>
                      <TableHead classes={{ root: classes.background }}>
                        <TableRow>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Date
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Type
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Amount
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Reference No.
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Payment Method
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Note
                          </TableCell>
                          <TableCell
                            classes={{ root: classes.textWhite }}
                            align='center'
                          >
                            Edit
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows &&
                          rows.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell
                                component='th'
                                align='center'
                                scope='row'
                              >
                                {row.transactionDate}
                              </TableCell>
                              <TableCell align='center'>{row.type}</TableCell>
                              <TableCell align='center'>
                                {formatter.format(row.amount)}
                              </TableCell>
                              <TableCell align='center'>
                                {row.referenceNo}
                              </TableCell>
                              <TableCell align='center'>{row.method}</TableCell>
                              <TableCell align='center'>{row.notes}</TableCell>
                              <TableCell align='center'>
                                <EditOutlined
                                  className={classes.textNavy}
                                  onClick={() => openDialog('transaction', row)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <Dialog
                        classes={{ paper: classes.dialog }}
                        fullWidth
                        open={dialogs.transaction}
                        onClose={closeDialog}
                        aria-labelledby='transaction-title'
                      >
                        <DialogTitle id='transaction-title'>Edit</DialogTitle>
                        <DialogContent dividers>
                          <Grid
                            container
                            direction='row'
                            justify='space-around'
                            spacing={1}
                          >
                            <Grid item xs={5} container direction='column'>
                              <Typography variant='body2'>
                                Date: (Display Only)
                              </Typography>
                              <TextField
                                disabled
                                margin='dense'
                                variant='outlined'
                                value={selectedTransaction.transactionDate}
                              />
                            </Grid>
                            <Grid item xs={2} />
                            <Grid item xs={5} container direction='column'>
                              <Typography variant='body2'>
                                Type: (Display Only)
                              </Typography>
                              <TextField
                                disabled
                                margin='dense'
                                variant='outlined'
                                value={selectedTransaction.type}
                              />
                            </Grid>
                            <Grid item xs={5} container direction='column'>
                              <Typography variant='body2'>Amount:</Typography>
                              <TextField
                                margin='dense'
                                variant='outlined'
                                id='amount'
                                onChange={handleTransactionText}
                                defaultValue={selectedTransaction.amount}
                              />
                            </Grid>
                            <Grid item xs={2} />
                            <Grid item xs={5} container direction='column'>
                              <Typography variant='body2'>
                                Reference No.
                              </Typography>
                              <TextField
                                margin='dense'
                                variant='outlined'
                                id='referenceNo'
                                onChange={handleTransactionText}
                                defaultValue={selectedTransaction.referenceNo}
                              />
                            </Grid>
                            <Grid item xs={5} container direction='column'>
                              <Typography variant='body2'>
                                Payment Method
                              </Typography>
                              <FormControl
                                variant='outlined'
                                margin='dense'
                                style={{ minWidth: 195 }}
                              >
                                <Select
                                  name='method'
                                  id='method-select-outlined'
                                  value={paymentMethod}
                                  onChange={handleTransactionSelect}
                                >
                                  <MenuItem value=''>
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value='Cheque'>Cheque</MenuItem>
                                  <MenuItem value='Bank Draft'>
                                    Bank Draft
                                  </MenuItem>
                                  <MenuItem value='Bank Transfer'>
                                    Bank Transfer
                                  </MenuItem>
                                  <MenuItem value='Cash'>Cash</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={7} />
                            <Grid item xs={12} container direction='column'>
                              <Typography variant='body2'>Notes</Typography>
                              <TextField
                                margin='dense'
                                variant='outlined'
                                multiline
                                rows='3'
                                id='notes'
                                onChange={handleTransactionText}
                                defaultValue={
                                  selectedTransaction.notes
                                    ? selectedTransaction.notes
                                    : ''
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions
                          style={{ justifyContent: 'space-around' }}
                        >
                          <Button
                            className={classes.dialogCancelButton}
                            onClick={() => cancelDialog('transaction')}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={classes.dialogSubmitButton}
                            onClick={() => transactionSubmit('transaction')}
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} container direction='row'>
                  <Grid item xs={2} container justify='center'>
                    <Button
                      disabled={accountInfo.status === 'Closed'}
                      onClick={() => openDialog('redeem')}
                      classes={{ root: classes.button }}
                    >
                      Renew
                    </Button>
                  </Grid>
                  <Grid item xs={2} container justify='center'>
                    <Button
                      disabled={accountInfo.status === 'Closed'}
                      onClick={() => openDialog('partialRedeem')}
                      classes={{ root: classes.button }}
                      style={{ width: 210 }}
                    >
                      Redeem/Partial Redeem
                    </Button>
                  </Grid>
                  <Grid item xs={2} container justify='center'>
                    <Button
                      disabled={accountInfo.status === 'Closed'}
                      onClick={() => openDialog('transfer')}
                      classes={{ root: classes.button }}
                    >
                      Transfer LP Units
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <div className={classes.buttonField}>
          <Button
            classes={{ root: classes.submit }}
            variant='outlined'
            onClick={() => history.push('/reminder')}
          >
            Finish
          </Button>
        </div>
      </form>
    </>
  )
}

export default AccountReminderView
