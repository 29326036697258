import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '../../../assets/toolbar.png'
import { GRAY_500, RED, NAVY, WHITE, LIGHT_GRAY } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonField: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.mixins.toolbar
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    viewCancel: {
      backgroundColor: WHITE,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: WHITE
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: NAVY
      }
    }
  })
)
