import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '../../../assets/toolbar.png'
import {
  GRAY_500,
  GRAY_600,
  GRAY_700,
  RED,
  NAVY,
  WHITE,
  LIGHT_GRAY,
  BORDER_GRAY,
  APPBAR_GRAY,
  BLACK
} from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaceAround: {
      justifyContent: 'space-around'
    },
    indicator: {
      backgroundColor: NAVY
    },
    paper: {
      background: '#FFFFFF 0% 0% no-repeat',
      border: `1px solid ${BORDER_GRAY}`,
      marginBottom: 16,
      minHeight: theme.spacing(38),
      width: '100%',
      margin: 'auto'
    },
    appbar: {
      backgroundColor: APPBAR_GRAY,
      color: BLACK,
      boxShadow: 'unset'
    },
    selected: {
      color: BLACK
    },
    capitalize: {
      textTransform: 'capitalize',
      width: '20%'
    },
    boxShadow: {
      boxShadow: 'unset',
      borderRadius: 'unset'
    },
    background: {
      backgroundColor: GRAY_600
    },
    navy: {
      color: NAVY
    },
    gray: {
      color: GRAY_500
    },
    gray600: {
      color: GRAY_600
    },
    red: {
      color: RED
    },
    white: {
      color: WHITE
    }
  })
)
