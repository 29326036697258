export const API_URL =
  'https://86nin08at6.execute-api.us-east-1.amazonaws.com/dev'

export const GLOBAL_URL =
  'https://5ocfj35pgf.execute-api.us-east-1.amazonaws.com/dev'

export const PROD_AUTH =
  'https://lwrgnmowp6.execute-api.us-west-2.amazonaws.com/prod'
export const PROD_OPS =
  'https://hyd9w5zr8d.execute-api.us-west-2.amazonaws.com/prod'
export const AUTH_KEY = '1N9LS5wcXB4D3R93dNaRR69kMjxUxPKg4Fz579o0'
export const OPS_KEY = '5ya3Ilv2N15GsnUKQSS5Q7VnMZMslETs5ZhmYw1N'
