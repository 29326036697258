import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import {
  Grid,
  Button,
  TextField,
  Typography,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@material-ui/core'
import {
  Add,
  DeleteForeverOutlined,
  AccountBalanceOutlined,
} from '@material-ui/icons'
import { useStyles } from './information.form.style'
import moment, { Moment } from 'moment'

import InvestorInfoForm from '../components/investor.info.view.form.component'
import Dropzone from '../../../components/dropzone/dropzone.component'
import { IValues } from '../../../utils/types'
import { IBank, DEFAULT_BANK } from '../helper/investor.helper'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { AuthContext } from '../../../providers/auth/auth.provider'

export type CheckboxType = {
  [key: string]: boolean
}

const InformationForm = ({
  handleNext,
  investor,
  setValues,
  errors,
  setErrors,
  contactName,
  setContactName,
}: {
  handleNext: () => void
  investor: IValues
  setValues: (value: IValues, type: string) => void
  errors: IValues
  setErrors: (error: IValues) => void
  contactName: string
  setContactName: (value: string) => void
}) => {
  const classes = useStyles()
  const history = useHistory()
  const formType = 'investor'

  const [bank, $bank] = useState<IBank>(DEFAULT_BANK)
  const [banks, $banks] = useState<IBank[]>(investor.banks)
  const { token } = React.useContext(AuthContext)

  const [checkbox, $checkbox] = useState<CheckboxType>({
    individual: investor.info.investorType === 'individual',
    entity: investor.info.investorType === 'entity',
  })

  const [belongsTo, $belongsTo] = useState<any>([])
  const [birthDay, $birthday] = useState<Moment | null>(
    investor.info.birthDay ? moment(investor.info.birthDay, 'YYYY-MM-DD') : null
  )
  const [gender, $gender] = useState(investor.info.gender)
  const [superAccount, $superAccount] = useState(investor.info.belongsTo)
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current!.offsetWidth)
    }
  }, [])

  const handleDate = (dateObj: Moment | null, name: string) => {
    $birthday(dateObj)

    setValues(
      { info: { ...investor.info, [name]: dateObj?.format('YYYY-MM-DD') } },
      formType
    )
  }

  const [bankDialog, $bankDialog] = useState(false)

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type: string = event.target.value
    const checked: boolean = event.target.checked

    if (!checkbox.individual && !checkbox.entity) {
      setValues({ info: { ...investor.info, investorType: type } }, formType)
      $checkbox({ ...checkbox, [type]: checked })
    } else {
      if (type === 'individual') {
        if (checkbox.entity) {
          $checkbox({ entity: false, [type]: checked })
          setValues(
            {
              info: {
                ...investor.info,
                investorType: type,
              },
            },
            formType
          )
        } else {
          $checkbox({ ...checkbox, [type]: checked })
          setValues(
            {
              info: {
                ...investor.info,
                investorType: '',
              },
            },
            formType
          )
        }
      } else {
        if (checkbox.individual) {
          $checkbox({ individual: false, [type]: checked })
          setValues(
            { info: { ...investor.info, investorType: type } },
            formType
          )
        } else {
          $checkbox({ ...checkbox, [type]: checked })
          setValues({ info: { ...investor.info, investorType: '' } }, formType)
        }
      }
    }
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const field = e.currentTarget.id as string
    const value = e.currentTarget.value as string

    if (field === 'contactName') {
      setContactName(value)
    }

    setValues(
      {
        info: { ...investor.info, [e.currentTarget.id]: e.currentTarget.value },
      },
      formType
    )
  }

  const handleGenderSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $gender(event.target.value as string)
    setValues(
      { info: { ...investor.info, [name]: event.target.value as string } },
      formType
    )
  }

  const handleDialogOpen = () => {
    $bankDialog(true)
  }

  const handleDialogClose = () => {
    $bankDialog(false)
  }

  const handleDialogCancel = () => {
    $bank(DEFAULT_BANK)
    handleDialogClose()
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = e.currentTarget.value as string
    let newError: IValues = {}
    if (value === '') {
      newError[name] = 'Required'
    } else {
      newError[name] = null
    }
    setErrors(newError)
  }

  const deleteBank = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault()
    const modified = banks
      .slice(0, index)
      .concat(banks.slice(index + 1, banks.length))
    $banks(banks.slice(0, index).concat(banks.slice(index + 1, banks.length)))
    setValues({ banks: modified }, formType)
  }

  React.useEffect(() => {
    const loadInvestors = async () => {
      const res = await axios.get(`${PROD_OPS}/investor/all`, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      $belongsTo(res.data.investors)
    }
    loadInvestors()
  }, [])

  React.useEffect(() => {
    const firstName = investor.info.firstName as string
    let modified = ''

    if (firstName !== null) {
      modified = `${firstName}`
    }

    setContactName(modified)
  }, [investor.info.firstName])

  const handleBankSubmit = () => {
    $banks([...banks, bank])
    setValues(
      {
        banks: [...investor.banks, bank],
      },
      formType
    )
    $bank(DEFAULT_BANK)
    handleDialogClose()
  }

  const handleBank = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.target.id as string
    const value = e.target.value as string

    $bank({ ...bank, [name]: value })
  }

  const handleSuperAccount = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    $superAccount(event.target.value as string)
    setValues(
      { info: { ...investor.info, [name]: event.target.value as string } },
      formType
    )
  }

  return (
    <>
      <Grid container spacing={2} direction='column'>
        {/* Investor Type Field */}
        <InvestorInfoForm
          investor={investor}
          checkbox={checkbox}
          handleCheckbox={handleCheckbox}
          handleTextField={handleTextField}
          inputLabel={inputLabel}
          gender={gender}
          handleGenderSelect={handleGenderSelect}
          labelWidth={labelWidth}
          handleSuperAccount={handleSuperAccount}
          belongsTo={belongsTo}
          superAccount={superAccount}
          errors={errors}
          handleOnBlur={handleOnBlur}
          birthDay={birthDay}
          handleDate={handleDate}
          contactName={contactName}
        />
        {/* Bank Account Field */}
        <Grid
          style={{ marginTop: 32 }}
          item
          xs={12}
          container
          direction='row'
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant='body1'>Bank Account</Typography>
          </Grid>
          <Grid item xs={3}>
            <Fab
              classes={{ root: classes.bankButton }}
              variant='extended'
              onClick={handleDialogOpen}
              disabled={banks.length === 3}
            >
              <Add classes={{ root: classes.bankIcon }} />
              Add Bank Account
            </Fab>
            <Dialog
              open={bankDialog}
              onClose={handleDialogClose}
              aria-labelledby='bank-form'
              fullWidth
              maxWidth='sm'
            >
              <DialogTitle id='bank-form'>Add Bank Account</DialogTitle>
              <DialogContent dividers>
                <Grid
                  className={classes.bankGrid}
                  container
                  direction='column'
                  spacing={3}
                >
                  <Grid item xs={12} container direction='column'>
                    <Grid item xs={12}>
                      <Typography variant='body1'>Bank Info</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='bankName'
                        fullWidth
                        margin='dense'
                        variant='outlined'
                        onChange={handleBank}
                        helperText='BMO-2234-002-001'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  alignItems='center'
                >
                  <Grid item xs container justify='center'>
                    <Button
                      classes={{ root: classes.cancel }}
                      style={{ width: 176 }}
                      variant='contained'
                      onClick={handleDialogCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs container justify='center'>
                    <Button
                      classes={{ root: classes.submit }}
                      style={{ width: 176 }}
                      variant='contained'
                      onClick={handleBankSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Grid>
          {banks &&
            banks.map((b, index: number) => (
              <Grid key={index} item xs={3}>
                <Fab classes={{ root: classes.bankItem }} variant='extended'>
                  <AccountBalanceOutlined />
                  <Typography variant='body1'>{b.bankName}</Typography>
                  <DeleteForeverOutlined
                    onClick={(e) => deleteBank(e, index)}
                    style={{ marginRight: 0 }}
                    classes={{ root: classes.bankDelete }}
                  />
                </Fab>
              </Grid>
            ))}
        </Grid>
        {/* File Upload and Button Field */}
        <Grid
          style={{ marginTop: 32 }}
          item
          xs={12}
          container
          direction='row'
          spacing={2}
        >
          <Grid item xs={12}>
            {/* <Typography variant='body1'>File Upload</Typography> */}
          </Grid>
          <Grid item xs={9}>
            {/* <Dropzone /> */}
          </Grid>
        </Grid>
      </Grid>
      <Box component='div' className={classes.buttonField}>
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='flex-end'
          spacing={2}
        >
          <Grid item xs container justify='center'>
            <Button
              fullWidth
              classes={{ root: classes.cancel }}
              variant='outlined'
              onClick={() => history.push('/investors')}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs container justify='center'>
            <Button
              type='submit'
              fullWidth
              onClick={handleNext}
              classes={{ root: classes.submit }}
              variant='outlined'
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default InformationForm
