import * as React from 'react'
import axios from 'axios'
import { IUserValues } from '../user'
import {
  UserDialogContent,
  UserDialogActions,
  UserDialogCancelButton,
  UserDialogSubmitButton
} from '../user.style'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { PROD_AUTH, AUTH_KEY } from '../../../utils/constants'
import { DialogLoading } from '../../../components/loading/loading.component'
import { AuthContext } from '../../../providers/auth/auth.provider'

export interface IValues {
  [key: string]: string
}

export interface IFormProps {
  selected: IUserValues
  cancelDialog: (type: string) => void
  closeDialog: (type: string) => void
}

export const EditUserForm: React.SFC<IFormProps> = ({
  selected,
  cancelDialog,
  closeDialog
}: IFormProps) => {
  const [values, $values] = React.useState<IValues>({
    userId: selected.id,
    name: selected.name,
    department: selected.department,
    role: selected.role,
    email: selected.email
  })
  const [loading, $loading] = React.useState<boolean>(false)
  const [isSubmitting, $isSubmitting] = React.useState<boolean>(false)
  const [selectedRole, $selectedRole] = React.useState<string>(
    selected.role
      .split('-')
      .map(s => s.charAt(0).toLowerCase() + s.substring(1))
      .join('-')
  )
  const options = ['None', 'Admin-View', 'Admin-General']
  const { token } = React.useContext(AuthContext)

  const formSubmission = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    $loading(true)
    $isSubmitting(true)
    const body = {
      operation: 'update',
      ...values
    }
    axios
      .put(`${PROD_AUTH}/user/info`, body, {
        headers: { 'x-api-key': AUTH_KEY, Authorization: token }
      })
      .then(res => {
        $isSubmitting(false)
        $loading(false)
        closeDialog('update')
      })
  }

  const setValues = (inputValues: IValues) => {
    $values({ ...values, ...inputValues })
  }

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.currentTarget.id !== 'confirmPassword') {
      setValues({ [e.currentTarget.id]: e.currentTarget.value })
    }
  }

  const handleSelect = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    $selectedRole(e.target.value as string)
    setValues({
      [e.target.name as string]: e.target.value as string
    })
  }

  return (
    <>
      <form
        style={{
          height: 'calc(100% - 116px)'
        }}
        onSubmit={formSubmission}
        noValidate={true}
      >
        <UserDialogContent dividers>
          {loading ? (
            <DialogLoading />
          ) : (
            <>
              <Grid
                container
                direction='row'
                justify='space-around'
                spacing={1}
              >
                <Grid item xs={5} container direction='column'>
                  <TextField
                    id='name'
                    type='text'
                    defaultValue={selected.name}
                    label='Name'
                    margin='dense'
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5} container direction='column'>
                  <FormControl margin='dense' style={{ minWidth: 195 }}>
                    <InputLabel id='role'>Permission</InputLabel>
                    <Select
                      labelId='role'
                      name='role'
                      onChange={handleSelect}
                      value={selectedRole}
                    >
                      {options &&
                        options.map(option => (
                          <MenuItem
                            key={option}
                            value={option
                              .split('-')
                              .map(
                                s => s.charAt(0).toLowerCase() + s.substring(1)
                              )
                              .join('-')}
                            disabled={option === 'None'}
                          >
                            {option}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} container direction='column'>
                  <TextField
                    id='department'
                    type='text'
                    defaultValue={selected.department}
                    label='Department'
                    margin='dense'
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5} container direction='column'>
                  <TextField
                    id='email'
                    type='text'
                    defaultValue={selected.email}
                    label='Email'
                    margin='dense'
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={5} container direction='column'>
                  <TextField
                    id='password'
                    type='password'
                    label='Password'
                    margin='dense'
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5} container direction='column'>
                  <TextField
                    id='confirmPassword'
                    type='password'
                    label='Confirm Password'
                    margin='dense'
                    onChange={handleChanges}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </UserDialogContent>
        <UserDialogActions>
          <UserDialogCancelButton
            variant='contained'
            onClick={() => cancelDialog('update')}
          >
            Cancel
          </UserDialogCancelButton>
          <UserDialogSubmitButton
            disabled={loading}
            type='submit'
            variant='contained'
          >
            Submit
          </UserDialogSubmitButton>
        </UserDialogActions>
      </form>
    </>
  )
}
