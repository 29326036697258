import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-roboto'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './styles/react.dates.style.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import AuthProvider from './providers/auth/auth.provider'

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
