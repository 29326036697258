import React from 'react'

import { Grid, Typography, Divider, Link, TextField } from '@material-ui/core'
import {
  People,
  MonetizationOnOutlined,
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  DonutLargeOutlined,
  CreateOutlined,
  CheckOutlined
} from '@material-ui/icons'
import axios from 'axios'

import { useHistory } from 'react-router-dom'

import { useStyles } from './home.dashboard.style'
import { IDashboard } from '../../pages/homepage/helper/homepage.helper'
import { IValues } from '../../utils/types'
import { PROD_AUTH, AUTH_KEY } from '../../utils/constants'
import { formatter } from '../../utils/format'
import { FilterContext } from '../../providers/filter/filter.provider'
import { AuthContext } from '../../providers/auth/auth.provider'

const HomeDashboard = ({
  dashboard,
  edit,
  setEdit
}: {
  dashboard: IDashboard
  edit: boolean
  setEdit: () => void
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { token } = React.useContext(AuthContext)
  const {
    activeInvestor,
    closedInvestor,
    activeMortgage,
    closedMortgage
  } = React.useContext(FilterContext)

  const [value, $value] = React.useState<IValues>({})

  const setValues = (balance: IValues) => {
    $value({ ...value, ...balance })
  }

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string
    const value = (e.currentTarget.value as unknown) as number
    setValues({ [name]: value })
  }

  const handleBalanceSubmit = async () => {
    const res = await axios.put(`${PROD_AUTH}/balance`, value, {
      headers: { 'x-api-key': AUTH_KEY, Authorization: token }
    })
    if ((res.status = 200)) {
      setEdit()
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justify='center' alignItems='center'>
        {/* Grid Item Start */}
        <Grid className={classes.stat} item xs={3} container direction='column'>
          <Grid item xs container>
            <Grid item xs>
              <Typography variant='h6'>Investors</Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.iconTypo} variant='h6'>
                <People className={classes.bigIcon} />
                {dashboard.investorTotal}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container>
            <Grid item xs>
              <Typography
                onClick={activeInvestor}
                className={classes.iconTypo}
                variant='subtitle2'
              >
                <span className={classes.link}>Active</span>
                <People className={classes.smallIcon} />{' '}
                {dashboard.investorActive}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='subtitle2'>|</Typography>
            </Grid>
            <Grid item xs>
              <Typography
                onClick={closedInvestor}
                className={classes.iconTypo}
                variant='subtitle2'
              >
                <span className={classes.link}>Non-Active</span>
                <People className={classes.smallIcon} />
                {dashboard.investorClosed}
              </Typography>
            </Grid>
          </Grid>
          <Divider variant='middle' />
          <Grid item xs className={classes.buttonAndList}>
            <Typography
              className={classes.link}
              onClick={() => history.push('/investors')}
              variant='body2'
            >
              View Investors List >>
            </Typography>
          </Grid>
        </Grid>
        {/* Grid Item Start */}
        <Grid className={classes.stat} item xs={3} container direction='column'>
          <Grid item xs container>
            <Grid item xs>
              <Typography variant='h6'>Mortgages</Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.iconTypo} variant='h6'>
                <People className={classes.bigIcon} />
                {dashboard.mortgageTotal}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container>
            <Grid item xs>
              <Typography
                onClick={activeMortgage}
                className={classes.iconTypo}
                variant='subtitle2'
              >
                <span className={classes.link}>Active</span>
                <People className={classes.smallIcon} />{' '}
                {dashboard.mortgageActive}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='subtitle2'>|</Typography>
            </Grid>
            <Grid item xs>
              <Typography
                onClick={closedMortgage}
                className={classes.iconTypo}
                variant='subtitle2'
              >
                <span className={classes.link}>Non-Active</span>
                <People className={classes.smallIcon} />
                {dashboard.mortgageClosed}
              </Typography>
            </Grid>
          </Grid>
          <Divider variant='middle' />
          <Grid item xs className={classes.buttonAndList}>
            <Typography
              className={classes.link}
              onClick={() => history.push('/mortgages')}
              variant='body2'
            >
              View Mortgages List >>
            </Typography>
          </Grid>
        </Grid>
        {/* Grid Item Start */}
        <Grid
          className={classes.stat}
          style={{ padding: 16 }}
          item
          xs={3}
          container
          direction='column'
        >
          <Grid item xs container direction='row' alignItems='center'>
            <Grid item xs>
              <Typography className={classes.iconTypo} variant='body2'>
                <MonetizationOnOutlined className={classes.bigIcon} />
                Total Investment
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.buttonAndList} variant='h6'>
                {formatter.format(dashboard.totalInvestment)}
              </Typography>
            </Grid>
            <Grid className={classes.buttonAndList} item xs={1} />
          </Grid>
          <Grid item xs container direction='row' alignItems='center'>
            <Grid item xs>
              <Typography className={classes.iconTypo} variant='body2'>
                <AccountBalanceOutlined className={classes.bigIcon} />
                Total Mortgages
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.buttonAndList} variant='h6'>
                {formatter.format(dashboard.totalMortgage)}
              </Typography>
            </Grid>
            <Grid className={classes.buttonAndList} item xs={1} />
          </Grid>
          <Grid item xs container direction='row' alignItems='center'>
            <Grid item xs>
              <Typography className={classes.iconTypo} variant='body2'>
                <DonutLargeOutlined className={classes.bigIcon} />
                Differences
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.buttonAndList} variant='h6'>
                {formatter.format(dashboard.difference)}
              </Typography>
            </Grid>
            <Grid className={classes.buttonAndList} item xs={1} />
          </Grid>
          <Grid item xs container direction='row' alignItems='center'>
            <Grid item xs>
              <Typography className={classes.iconTypo} variant='body2'>
                <AccountBalanceWalletOutlined className={classes.bigIcon} />
                Bank Balances
              </Typography>
            </Grid>
            <Grid item xs>
              {edit ? (
                <>
                  <TextField
                    type='number'
                    onChange={handleTextField}
                    id='balance'
                  />
                </>
              ) : (
                <>
                  <Typography className={classes.buttonAndList} variant='h6'>
                    {formatter.format(dashboard.bankBalance)}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid className={classes.buttonAndList} item xs={1}>
              {edit ? (
                <CheckOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={handleBalanceSubmit}
                />
              ) : (
                <CreateOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEdit()}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeDashboard
