import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { GRAY_400, NAVY, GRAY_500 } from '../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: ' center',
      flexDirection: 'column'
    },
    icon: {
      fontSize: '8rem',
      color: GRAY_400,
      marginBottom: theme.spacing(4)
    },
    title: {
      color: NAVY
    },
    text: {
      color: GRAY_500
    },
    link: {
      color: NAVY,
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })
)
