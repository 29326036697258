import React, { useEffect } from 'react'
import axios from 'axios'

import {
  Box,
  Paper,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Link,
  TablePagination,
} from '@material-ui/core'

import { useStyles } from './reminder.style'
import { PROD_OPS, OPS_KEY } from '../../utils/constants'
import { DialogLoading } from '../../components/loading/loading.component'
import { ErrorPage } from '../../components/error/error.component'
import { formatter } from '../../utils/format'
import {
  IInvInterest,
  IInvMaturity,
  TabPanelProps,
  IMortgageReminder,
} from './helper/reminder.interface'
import { DEFAULT_MORTGAGE_REMINDER } from './helper/reminder.constants'
import { AuthContext } from '../../providers/auth/auth.provider'

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        maxHeight: '75%',
      }}
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

const a11yProps = (index: any) => {
  return {
    id: `reminder-tab-${index}`,
    'aria-controls': `reminder-tabpanel-${index}`,
  }
}

const ReminderPage = () => {
  const classes = useStyles()
  const [invValue, $invValue] = React.useState(0)
  const [morValue, $morValue] = React.useState(0)
  const [invInterest, $invInterest] = React.useState<IInvInterest[]>([])
  const [invMaturity, $invMaturity] = React.useState<IInvMaturity[]>([])
  const [loading, $loading] = React.useState<boolean>(false)
  const [mLoading, $mLoading] = React.useState<boolean>(false)
  const [axiosError, $axiosError] = React.useState<boolean>(false)
  const [mReminders, $mReminders] = React.useState<IMortgageReminder>(
    DEFAULT_MORTGAGE_REMINDER
  )

  const [page, $page] = React.useState({
    invMaturity: 0,
    invInterest: 0,
  })
  const [rowsPerPage, $rowsPerPage] = React.useState({
    invMaturity: 5,
    invInterest: 5,
  })
  const { token } = React.useContext(AuthContext)

  const handleChangePage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    const id = e?.currentTarget.id as string
    $page({ ...page, [id]: newPage })
  }

  useEffect(() => {
    const loadData = async () => {
      $loading(true)
      $mLoading(true)
      try {
        const inv = await axios.get(`${PROD_OPS}/reminder/investmentMaturity`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        const mor = await axios.get(`${PROD_OPS}/reminder/mortgageInterest`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        if (inv.status === 200) {
          $invMaturity(inv.data.list)
        }
        if (mor.status === 200) {
          let newInt = mReminders
          newInt = { ...newInt, interest: mor.data.list }
          $mReminders(newInt)
        }
        $loading(false)
        $mLoading(false)
      } catch {
        $axiosError(true)
        $loading(false)
      }
    }
    loadData()
  }, [])

  const invTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    $invValue(newValue)
    switch (newValue) {
      case 0:
        $loading(true)
        axios
          .get(`${PROD_OPS}/reminder/investmentMaturity`, {
            headers: { 'x-api-key': OPS_KEY, Authorization: token },
          })
          .then((res) => {
            if (res.status === 200) {
              $invMaturity(res.data.list)
            }
            $loading(false)
          })
        break
      case 1:
        $loading(true)
        axios
          .get(`${PROD_OPS}/reminder/investmentInterest`, {
            headers: { 'x-api-key': OPS_KEY, Authorization: token },
          })
          .then((res) => {
            if (res.status === 200) {
              $invInterest(res.data.list)
            }
            $loading(false)
          })
        break
      default:
        break
    }
  }
  const morTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    $morValue(newValue)
    let url = `${PROD_OPS}/reminder/motrgageInterest`
    let type = 'interest'

    if (newValue === 0) {
      url = `${PROD_OPS}/reminder/motrgageInterest`
      type = 'interest'
    }

    if (newValue === 1) {
      url = `${PROD_OPS}/reminder/mortgageMaturity`
      type = 'maturity'
    }

    if (newValue === 2) {
      url = `${PROD_OPS}/reminder/tileCert`
      type = 'title'
    }

    if (newValue === 3) {
      url = `${PROD_OPS}/reminder/legalDoc`
      type = 'legal'
    }

    if (newValue === 4) {
      url = `${PROD_OPS}/reminder/lenderPackage`
      type = 'lender'
    }

    const getReminder = async (url: string, type: string) => {
      $mLoading(true)
      try {
        const res = await axios.get(url, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })

        if (res.status === 200) {
          $mReminders({ ...mReminders, [type]: res.data.list })
        }
      } catch {
      } finally {
        $mLoading(false)
      }
    }

    getReminder(url, type)

    // switch (newValue) {
    //   case 0:
    //     $mLoading(true)
    //     axios
    //       .get(`${PROD_OPS}/reminder/mortgageInterest`, {
    //         headers: { 'x-api-key': OPS_KEY, Authorization: token },
    //       })
    //       .then((res) => {
    //         if (res.status === 200) {
    //           let newInt = mReminders
    //           newInt = { ...newInt, interest: res.data.list }
    //           $mReminders(newInt)
    //         }
    //         $mLoading(false)
    //       })
    //     break
    //   case 1:
    //     $mLoading(true)
    //     axios
    //       .get(`${PROD_OPS}/reminder/mortgageMaturity`, {
    //         headers: { 'x-api-key': OPS_KEY, Authorization: token },
    //       })
    //       .then((res) => {
    //         if (res.status === 200) {
    //           let newMat = mReminders
    //           newMat = { ...newMat, maturity: res.data.list }
    //           $mReminders(newMat)
    //         }
    //         $mLoading(false)
    //       })
    //     break
    //   case 2:
    //     $mLoading(true)
    //     axios
    //       .get(`${PROD_OPS}/reminder/tileCert`, {
    //         headers: { 'x-api-key': OPS_KEY, Authorization: token },
    //       })
    //       .then((res) => {
    //         if (res.status === 200) {
    //           let newTitle = mReminders
    //           newTitle = { ...newTitle, title: res.data.list }
    //           $mReminders(newTitle)
    //         }
    //         $mLoading(false)
    //       })
    //     break
    //   case 3:
    //     $mLoading(true)
    //     axios
    //       .get(`${PROD_OPS}/reminder/legalDoc`, {
    //         headers: { 'x-api-key': OPS_KEY, Authorization: token },
    //       })
    //       .then((res) => {
    //         if (res.status === 200) {
    //           let newLegal = mReminders
    //           newLegal = { ...newLegal, legal: res.data.list }
    //           $mReminders(newLegal)
    //         }
    //         $mLoading(false)
    //       })
    //     break
    //   case 4:
    //     $mLoading(true)
    //     axios
    //       .get(`${PROD_OPS}/reminder/lenderPackage`, {
    //         headers: { 'x-api-key': OPS_KEY, Authorization: token },
    //       })
    //       .then((res) => {
    //         if (res.status === 200) {
    //           let newLender = mReminders
    //           newLender = { ...newLender, lender: res.data.list }
    //           $mReminders(newLender)
    //         }
    //         $mLoading(false)
    //       })
    //     break
    //   default:
    //     break
    // }
  }

  return axiosError ? (
    <ErrorPage />
  ) : (
    <div className={classes.root}>
      <div className={classes.toolbarBg}>Reminders</div>
      <div
        className={classes.toolbar}
        style={{
          justifyContent: 'flex-start',
          fontWeight: 900,
          width: '95%',
          margin: 'auto',
        }}
      >
        Investment Reminders
      </div>
      <Paper className={classes.paper}>
        <AppBar classes={{ root: classes.appbar }} position='static'>
          <Tabs
            centered
            classes={{
              centered: classes.spaceAround,
              indicator: classes.indicator,
            }}
            value={invValue}
            onChange={invTabChange}
            aria-label='Investment Reminders'
          >
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Investment Maturity Reminder'
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Investor Interest Payment Reminder'
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={invValue} index={0}>
          {loading ? (
            <DialogLoading />
          ) : (
            <>
              <TableContainer>
                <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                  <TableHead classes={{ root: classes.tableHead }}>
                    <TableRow>
                      <TableCell align='center'>Certificate No.</TableCell>
                      <TableCell align='center'>Start Date</TableCell>
                      <TableCell align='center'>Investment Amount</TableCell>
                      <TableCell align='center'>Class</TableCell>
                      <TableCell align='center'>Mature Date</TableCell>
                      <TableCell align='center'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invMaturity &&
                      invMaturity.map((i, idx: number) => (
                        <React.Fragment key={idx}>
                          <TableRow>
                            <TableCell align='center'>
                              {i.certificateNo}
                            </TableCell>
                            <TableCell align='center'>{i.startDate}</TableCell>
                            <TableCell align='center'>
                              {formatter.format(i.investmentAmount)}
                            </TableCell>
                            <TableCell align='center'>{i.class}</TableCell>
                            <TableCell align='center'>{i.matureDate}</TableCell>
                            <TableCell align='center'>
                              <Link href={`/accounts/${i.id}/view`}>
                                {'View Account >>'}
                              </Link>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </TabPanel>
        <TabPanel value={invValue} index={1}>
          {loading ? (
            <DialogLoading />
          ) : (
            <TableContainer>
              <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                <TableHead classes={{ root: classes.tableHead }}>
                  <TableRow>
                    <TableCell align='center'>Certificate No.</TableCell>
                    <TableCell align='center'>Start Date</TableCell>
                    <TableCell align='center'>Investment Amount</TableCell>
                    <TableCell align='center'>Class</TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invInterest &&
                    invInterest.map((i, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell align='center'>{i.certificateNo}</TableCell>
                        <TableCell align='center'>{i.startDate}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(i.investmentAmount)}
                        </TableCell>
                        <TableCell align='center'>{i.class}</TableCell>
                        <TableCell align='center'>
                          <Link href={`/accounts/${i.id}/view`}>
                            {'View Account >>'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
      </Paper>
      <div
        className={classes.toolbar}
        style={{
          justifyContent: 'flex-start',
          fontWeight: 900,
          width: '95%',
          margin: 'auto',
        }}
      >
        Mortgage Reminders
      </div>
      <Paper className={classes.paper}>
        <AppBar classes={{ root: classes.appbar }} position='static'>
          <Tabs
            centered
            classes={{
              centered: classes.spaceAround,
              indicator: classes.indicator,
            }}
            value={morValue}
            onChange={morTabChange}
            aria-label='Mortgage Reminders'
          >
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Mortgage Interest Reminder'
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Mortgage Maturity Reminder'
              {...a11yProps(1)}
            />
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Title Certificate'
              {...a11yProps(2)}
            />
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Legal Document'
              {...a11yProps(3)}
            />
            <Tab
              classes={{ root: classes.capitalize, selected: classes.selected }}
              label='Lender Package'
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={morValue} index={2}>
          {mLoading ? (
            <DialogLoading />
          ) : (
            <TableContainer>
              <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                <TableHead classes={{ root: classes.tableHead }}>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>File Number</TableCell>
                    <TableCell align='center'>Start Date</TableCell>
                    <TableCell align='center'>Mature Date</TableCell>
                    <TableCell align='center'>Amount</TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mReminders.title &&
                    mReminders.title.map((m, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell align='center'>{m.mortgageName}</TableCell>
                        <TableCell align='center'>{m.fileNo}</TableCell>
                        <TableCell align='center'>{m.startDate}</TableCell>
                        <TableCell align='center'>{m.matureDate}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(m.mortgageAmount)}
                        </TableCell>
                        <TableCell align='center'>
                          <Link href={`/mortgages/${m.id}/view`}>
                            {'View Account >>'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel value={morValue} index={3}>
          {mLoading ? (
            <DialogLoading />
          ) : (
            <TableContainer>
              <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                <TableHead classes={{ root: classes.tableHead }}>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>File Number</TableCell>
                    <TableCell align='center'>Start Date</TableCell>
                    <TableCell align='center'>Mature Date</TableCell>
                    <TableCell align='center'>Amount</TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mReminders.legal &&
                    mReminders.legal.map((m, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell align='center'>{m.mortgageName}</TableCell>
                        <TableCell align='center'>{m.fileNo}</TableCell>
                        <TableCell align='center'>{m.startDate}</TableCell>
                        <TableCell align='center'>{m.matureDate}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(m.mortgageAmount)}
                        </TableCell>
                        <TableCell align='center'>
                          <Link href={`/mortgages/${m.id}/view`}>
                            {'View Account >>'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel value={morValue} index={4}>
          {mLoading ? (
            <DialogLoading />
          ) : (
            <TableContainer>
              <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                <TableHead classes={{ root: classes.tableHead }}>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>File Number</TableCell>
                    <TableCell align='center'>Start Date</TableCell>
                    <TableCell align='center'>Mature Date</TableCell>
                    <TableCell align='center'>Amount</TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mReminders.lender &&
                    mReminders.lender.map((m, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell align='center'>{m.mortgageName}</TableCell>
                        <TableCell align='center'>{m.fileNo}</TableCell>
                        <TableCell align='center'>{m.startDate}</TableCell>
                        <TableCell align='center'>{m.matureDate}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(m.mortgageAmount)}
                        </TableCell>
                        <TableCell align='center'>
                          <Link href={`/mortgages/${m.id}/view`}>
                            {'View Account >>'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel value={morValue} index={1}>
          {mLoading ? (
            <DialogLoading />
          ) : (
            <TableContainer>
              <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                <TableHead classes={{ root: classes.tableHead }}>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>File Number</TableCell>
                    <TableCell align='center'>Start Date</TableCell>
                    <TableCell align='center'>Mature Date</TableCell>
                    <TableCell align='center'>Amount</TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mReminders.maturity &&
                    mReminders.maturity.map((m, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell align='center'>{m.mortgageName}</TableCell>
                        <TableCell align='center'>{m.fileNo}</TableCell>
                        <TableCell align='center'>{m.startDate}</TableCell>
                        <TableCell align='center'>{m.matureDate}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(m.mortgageAmount)}
                        </TableCell>
                        <TableCell align='center'>
                          <Link href={`/mortgages/${m.id}/view`}>
                            {'View Account >>'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel value={morValue} index={0}>
          {mLoading ? (
            <DialogLoading />
          ) : (
            <TableContainer>
              <Table stickyHeader style={{ maxHeight: '100%' }} size='small'>
                <TableHead classes={{ root: classes.tableHead }}>
                  <TableRow>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>File Number</TableCell>
                    <TableCell align='center'>Start Date</TableCell>
                    <TableCell align='center'>Mature Date</TableCell>
                    <TableCell align='center'>Amount</TableCell>
                    <TableCell align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mReminders.interest &&
                    mReminders.interest.map((m, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell align='center'>{m.mortgageName}</TableCell>
                        <TableCell align='center'>{m.fileNo}</TableCell>
                        <TableCell align='center'>{m.startDate}</TableCell>
                        <TableCell align='center'>{m.matureDate}</TableCell>
                        <TableCell align='center'>
                          {formatter.format(m.mortgageAmount)}
                        </TableCell>
                        <TableCell align='center'>
                          <Link href={`/mortgages/${m.id}/view`}>
                            {'View Account >>'}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
      </Paper>
    </div>
  )
}

export default ReminderPage
