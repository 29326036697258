import {
  Theme,
  createStyles,
  makeStyles,
  styled
} from '@material-ui/core/styles'
import { LIGHT_GRAY, NAVY, WHITE } from '../../styles/color'
import { Button, DialogActions, DialogContent } from '@material-ui/core'
import Toolbar from '../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%'
    },
    toolbarBg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      ...theme.mixins.toolbar
    },
    table: {
      minWidth: '100%'
    },
    boxShadow: {
      boxShadow: 'unset'
    },
    background: {
      backgroundColor: LIGHT_GRAY
    },
    button: {
      boxShadow: 'unset',
      borderRadius: 0,
      backgroundColor: NAVY,
      color: WHITE,
      width: '13%',

      '&:hover': {
        backgroundColor: NAVY
      }
    },
    dialog: {
      height: '60%'
    },
    dialogAction: {
      justifyContent: 'space-around'
    },
    dialogSubmitButton: {
      backgroundColor: NAVY,
      width: '20%',
      borderRadius: 14,
      color: WHITE,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    dialogCancelButton: {
      backgroundColor: LIGHT_GRAY,
      width: '20%',
      borderRadius: 14,
      color: NAVY,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    }
  })
)

export const UserDialogContent = styled(DialogContent)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

export const UserDialogActions = styled(DialogActions)({
  justifyContent: 'space-around'
})

export const UserDialogCancelButton = styled(Button)({
  backgroundColor: LIGHT_GRAY,
  width: '20%',
  borderRadius: 14,
  color: NAVY,
  boxShadow: 'unset',
  '&:hover': {
    backgroundColor: LIGHT_GRAY
  }
})

export const UserDialogSubmitButton = styled(Button)({
  backgroundColor: NAVY,
  width: '20%',
  borderRadius: 14,
  color: WHITE,
  boxShadow: 'unset',
  '&:hover': {
    backgroundColor: NAVY
  }
})
