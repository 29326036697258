import * as React from 'react'
import { IFormErrors, FormContext, IFormValues } from './user.form'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'

type Editor = 'textfield' | 'dropdown'

export interface IValidation {
  rule: (values: IFormValues, fieldName: string, args: any) => string
  args?: any
}

export interface IFieldProps {
  id: string
  label?: string
  editor?: Editor
  options?: string[]
  value?: any
  type?: string
  validation?: IValidation
}

export const Field: React.SFC<IFieldProps> = ({
  id,
  label,
  editor,
  options,
  value,
  type
}) => {
  const context = React.useContext(FormContext)
  const [selected, $selected] = React.useState('')
  const getError = (errors: IFormErrors): string => (errors ? errors[id] : '')

  const checkError = (id: string): boolean => {
    if (context?.errors !== undefined) {
      return context?.errors.hasOwnProperty(id) && context?.errors[id] !== ''
    } else {
      return false
    }
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    $selected(event.target.value as string)
    context?.setValues({ [id]: selected })
  }

  return (
    <>
      {editor!.toLowerCase() === 'textfield' && (
        <TextField
          id={id}
          type={type}
          value={value}
          label={label}
          margin='dense'
          variant='outlined'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            context?.setValues({ [id]: e.currentTarget.value })
          }
          onBlur={() => context?.validate(id)}
          error={checkError(id)}
          helperText={
            context?.errors !== undefined ? getError(context?.errors) : ''
          }
        />
      )}

      {editor!.toLowerCase() === 'dropdown' && (
        <FormControl
          margin='dense'
          style={{ minWidth: 195 }}
          error={checkError(id)}
        >
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            labelId={id}
            name={id}
            onChange={handleChange}
            onBlur={() => context?.validate(id)}
            value={selected}
          >
            {options &&
              options.map(option => (
                <MenuItem
                  key={option}
                  value={option}
                  disabled={option === 'None'}
                >
                  {option}
                </MenuItem>
              ))}
          </Select>
          {context?.errors !== undefined ? (
            <FormHelperText>{getError(context?.errors)}</FormHelperText>
          ) : (
            ''
          )}
        </FormControl>
      )}
    </>
  )
}

Field.defaultProps = {
  editor: 'textfield'
}
