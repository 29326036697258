import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { LIGHT_GRAY, NAVY, WHITE, SKY_BLUE, BLACK } from '../../styles/color'
import Toolbar from '../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%'
    },
    toolbarBg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      ...theme.mixins.toolbar
    },
    filterList: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...theme.mixins.toolbar
    },
    filterbar: {
      ...theme.mixins.toolbar
    },
    table: {
      minWidth: '100%'
    },
    boxShadow: {
      boxShadow: 'unset',
      minHeight: '77%'
    },
    background: {
      backgroundColor: LIGHT_GRAY
    },
    filterMenu: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    smallButton: {
      boxShadow: 'unset',
      borderRadius: 0,
      backgroundColor: SKY_BLUE,
      color: BLACK,

      '&:hover': {
        backgroundColor: SKY_BLUE
      }
    },
    button: {
      boxShadow: 'unset',
      borderRadius: 0,
      backgroundColor: NAVY,
      color: WHITE,
      fontSize: 'large',
      fontWeight: 600,
      width: '13%',
      height: 55,
      marginLeft: 440,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        backgroundColor: NAVY
      }
    },
    addButton: {
      boxShadow: 'unset',
      borderRadius: 0,
      backgroundColor: NAVY,
      color: WHITE,
      height: 36,
      width: '25%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        backgroundColor: NAVY
      }
    },
    marginLeft: {
      marginLeft: 24
    },
    dialog: {
      height: '60%'
    },
    dialogAction: {
      justifyContent: 'space-around'
    },
    dialogSubmitButton: {
      backgroundColor: NAVY,
      width: '20%',
      borderRadius: 14,
      color: WHITE,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    dialogCancelButton: {
      backgroundColor: LIGHT_GRAY,
      width: '20%',
      borderRadius: 14,
      color: NAVY,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    typography: {
      color: NAVY,
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      marginLeft: 24,
      marginRight: 8,
      color: BLACK
    }
  })
)
