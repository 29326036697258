import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Box, Grid, Button } from '@material-ui/core'
import { useStyles } from './button.fields.style'
import axios from 'axios'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { PAYOUT } from '../helper/mortgage.constants'
import { ITransaction } from '../helper/mortgage.interface'
import { PayoutContext } from '../../../providers/payout/payout.provider'
import { AuthContext } from '../../../providers/auth/auth.provider'

export const ButtonFields = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <>
      <Box component='div' className={classes.buttonField}>
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='flex-end'
          spacing={2}
        >
          <Grid item xs={9} />
          <Grid item xs={1} container justify='center'>
            <Button
              fullWidth
              classes={{ root: classes.cancel }}
              variant='outlined'
              onClick={() => history.push('/mortgages')}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={1} container justify='center'>
            <Button
              type='submit'
              fullWidth
              classes={{ root: classes.submit }}
              variant='outlined'
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </>
  )
}

export const ViewButtonFields = ({
  mortgageLoaded,
  openDialog,
  payoutDetail,
  setLoading,
}: {
  mortgageLoaded: any
  openDialog: (type: string, transaction: ITransaction | undefined) => void
  payoutDetail: () => void
  setLoading: (bool: boolean) => void
}) => {
  const history = useHistory()
  const classes = useStyles()
  const { mortgageId } = useParams()
  const { setPayout, setLoadedMortgage } = React.useContext(PayoutContext)
  const { token } = React.useContext(AuthContext)

  const renew = () => {
    const body = {
      accountId: mortgageId,
    }
    setLoading(true)
    axios
      .post(`${PROD_OPS}/mortgage/renew`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      .then((res) => {
        setLoadedMortgage(mortgageLoaded)
        setLoading(false)
        history.push(`/mortgages/new`)
      })
  }

  const redirect = () => {
    setLoadedMortgage(mortgageLoaded)
    history.push(`/mortgages/${mortgageId}/renew`)
  }

  return (
    <>
      <Box
        component='div'
        style={{ marginTop: 24 }}
        className={classes.buttonField}
      >
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='flex-end'
          spacing={2}
        >
          <Grid item xs={9} />
          <Grid item xs={1} container justify='center'>
            <Button
              fullWidth
              classes={{ root: classes.viewCancel }}
              variant='outlined'
              onClick={() => history.push('/mortgages')}
            >
              Cancel
            </Button>
          </Grid>
          {mortgageLoaded.status === 'Closed' ? (
            <>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  onClick={payoutDetail}
                  classes={{ root: classes.submit }}
                  variant='outlined'
                >
                  Payout Details
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={1} container justify='center'>
                <Button
                  fullWidth
                  onClick={redirect}
                  classes={{ root: classes.submit }}
                  variant='outlined'
                >
                  Renew
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  onClick={() => openDialog(PAYOUT, undefined)}
                  classes={{ root: classes.submit }}
                  variant='outlined'
                >
                  Payout
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  )
}
