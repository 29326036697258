import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import moment, { Moment } from 'moment'
import {
  Link,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ListSubheader,
} from '@material-ui/core'
import { FindInPageOutlined, Add } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useStyles } from './mortgage.style'
import { LIME, RED } from '../../styles/color'

import { DateRangePicker } from 'react-dates'

import { FilterContext } from '../../providers/filter/filter.provider'
import { AuthContext } from '../../providers/auth/auth.provider'

import { TableLoading } from '../../components/loading/loading.component'
import { ErrorPage } from '../../components/error/error.component'

import { formatter } from '../../utils/format'
import { PROD_OPS, OPS_KEY } from '../../utils/constants'
import {
  IMortgageTable,
  IPrimeRate,
  IPrimeTable,
} from './helper/mortgage.interface'
import { createData, primeTable } from './helper/mortgage.table'
import { DEFAULT_PRIME_RATE } from './helper/mortgage.constants'
import {
  PrimeRateDialog,
  RateHistoryDialog,
} from './components/dialog.component'
import { IValues } from '../../utils/types'
import { renderMonthElement } from '../investor/components/month.element'

const MortgagePage = () => {
  const classes = useStyles()
  const [initialLoading, $initialLoading] = useState<boolean>(false)
  const [axiosError, $axiosError] = useState<boolean>(false)

  const [rows, $rows] = useState<IMortgageTable[]>([])
  const [original, $original] = useState<IMortgageTable[]>([])

  const [focused, $focused] = useState<any>(null)

  const [primeRate, $primeRate] = useState<IPrimeRate>(DEFAULT_PRIME_RATE)
  const [rateRow, $rateRow] = useState<IPrimeTable[]>([])
  const [colType, $colType] = useState<string>('')
  const [historyDialog, $historyDialog] = useState<boolean>(false)
  const {
    mortgage,
    mortgageUrl,
    handleMortgageFilterSelect,
    handleMortgageFilterDates,
    dates,
  } = useContext(FilterContext)
  const { token } = useContext(AuthContext)

  useEffect(() => {
    const loadData = async () => {
      try {
        $initialLoading(true)
        let list: IMortgageTable[] = []
        let rateList: IPrimeTable[] = []
        const res = await axios.get(`${PROD_OPS}/mortgage/list${mortgageUrl}`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })

        const mortgages: IMortgageTable[] = res.data.mortgages
        mortgages &&
          mortgages.map((mortgage) => {
            const created = createData(mortgage)
            list.push(created)
          })
        const primeRateRes = await axios.get(`${PROD_OPS}/mortgage/prime`, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        const rates: IPrimeTable[] = primeRateRes.data.primeRates
        rates &&
          rates.map((r) => {
            const rateRow = primeTable(r)
            rateList.push(rateRow)
          })
        $rows(list)
        $rateRow(rateList)
        $original(list)
        $initialLoading(false)
      } catch {
        $axiosError(true)
        $initialLoading(false)
      }
    }
    loadData()
  }, [mortgageUrl, primeRate.finished])

  const handleFilterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.target.id
    const value = e.target.value
    if (value === '') {
      $rows(original)
    } else {
      if (name === 'city') {
        let list = rows.filter((r) =>
          r.collaterals.every((c) =>
            c.city.toLowerCase().includes(value.toLowerCase())
          )
        )
        $rows(list)
      } else if (name === 'coBrokerName') {
        let list = rows.filter((r) =>
          r.coBrokerName
            ? r.coBrokerName.toLowerCase().includes(value.toLowerCase())
            : null
        )
        $rows(list)
      } else {
        let list = rows.filter((r) =>
          r.mortgageName
            ? r.mortgageName.toLowerCase().includes(value.toLowerCase())
            : null
        )
        $rows(list)
      }
    }
  }

  const openHistory = () => {
    $historyDialog(true)
  }

  const closeHistory = () => {
    $historyDialog(false)
  }

  const handleFilterSelect = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = e.target.name as string
    const value = e.target.value as string

    $colType(value)

    if (value === '') {
      $rows(original)
    } else {
      let list = original.filter((r) =>
        r.collaterals.every((c) => c.type.toLowerCase() === value.toLowerCase())
      )
      $rows(list)
    }
  }

  const redirectView = (id: string) => (
    <Link underline='none' href={`/mortgages/${id}/view`}>
      <FindInPageOutlined />
    </Link>
  )

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const name = e.currentTarget.id as string

    let dialogValues = primeRate
    dialogValues = {
      ...dialogValues,
      value: { ...dialogValues.value, [name]: e.currentTarget.value },
    }
    $primeRate(dialogValues)
  }

  const handleSelectField = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = e.target.name as string
    const value = e.target.value as string

    let dialogValues = primeRate
    dialogValues = {
      ...dialogValues,
      value: { ...dialogValues.value, [name]: value },
    }
    $primeRate(dialogValues)
  }

  const handleDate = (dateObj: Moment | null, name: string) => {
    let dialogValues = primeRate
    dialogValues = {
      ...dialogValues,
      value: { ...dialogValues.value, [name]: dateObj?.format('YYYY-MM-DD') },
    }
    $primeRate(dialogValues)
  }

  const openDialog = () => {
    let dialogValues = primeRate
    dialogValues = { ...dialogValues, dialog: true }
    $primeRate(dialogValues)
  }

  const cancelDialog = () => {
    let dialogValues = primeRate
    dialogValues = DEFAULT_PRIME_RATE
    $primeRate(dialogValues)
  }

  const dialogSubmit = () => {
    if (!validate()) {
      let dialogValues = primeRate
      dialogValues = { ...dialogValues, loading: true }
      $primeRate(dialogValues)

      axios
        .put(`${PROD_OPS}/mortgage/prime`, primeRate.value, {
          headers: { 'x-api-key': OPS_KEY, Authorization: token },
        })
        .then((res) => {
          if (res.status === 200) {
            dialogValues = {
              ...DEFAULT_PRIME_RATE,
              dialog: true,
              finished: true,
            }
            $primeRate(dialogValues)
          } else {
            dialogValues = {
              ...dialogValues,
              dialog: true,
              finished: true,
              loading: false,
              text: 'Something went wrong, please try it again',
            }
            $primeRate(dialogValues)
          }
        })
    }
  }

  const validate = () => {
    let newErrors: IValues = {}
    let haveError: boolean = false

    Object.keys(primeRate.value).map((fieldName: string) => {
      if (
        primeRate.value[fieldName] === null ||
        primeRate.value[fieldName] === '' ||
        primeRate.value[fieldName] === 0
      ) {
        newErrors[fieldName] = 'Required'
      } else {
        newErrors[fieldName] = ''
      }
    })

    let newValues = primeRate
    newValues = { ...newValues, errors: newErrors }
    $primeRate(newValues)

    Object.keys(newErrors).map((key) => {
      if (newErrors[key] !== '') {
        haveError = true
      }
    })
    return haveError
  }

  return axiosError ? (
    <ErrorPage />
  ) : (
    <Box component='div' className={classes.root}>
      <Box component='div' className={classes.toolbarBg}>
        Mortgage List
      </Box>
      <ListSubheader style={{ top: 64 }}>
        <Box component='div' className={classes.filterList}>
          <Box
            component='div'
            className={classes.filterMenu}
            style={{ width: '10%', marginLeft: 24 }}
          >
            <TextField
              size='small'
              margin='dense'
              helperText='Search Mortgagor Name'
              onChange={handleFilterText}
            />
          </Box>
          <Box
            component='div'
            className={classes.filterMenu}
            style={{ width: '20%' }}
          >
            <DateRangePicker
              renderMonthElement={renderMonthElement}
              startDate={dates.startDate}
              startDateId='start-date-id'
              endDate={dates.endDate}
              endDateId='end-date-id'
              onDatesChange={({ startDate, endDate }) =>
                handleMortgageFilterDates({ startDate, endDate })
              }
              focusedInput={focused}
              onFocusChange={(focusedInput) => $focused(focusedInput)}
              numberOfMonths={1}
              showClearDates={true}
              small
              displayFormat='YYYY-MM-DD'
              showDefaultInputIcon
              isOutsideRange={() => false}
              minimumNights={0}
            />
          </Box>
          <Box
            component='div'
            className={classes.filterMenu}
            style={{ width: '10%' }}
          >
            <FormControl margin='dense' style={{ minWidth: 100 }}>
              <Select
                name='status'
                required
                value={mortgage.status}
                onChange={handleMortgageFilterSelect}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Active'>Active</MenuItem>
                <MenuItem value='Closed'>Non-Active</MenuItem>
              </Select>
              <FormHelperText>Status</FormHelperText>
            </FormControl>
          </Box>
          <Box
            component='div'
            className={classes.filterMenu}
            style={{ width: '10%' }}
          >
            <Button
              classes={{ root: classes.smallButton }}
              onClick={openDialog}
              variant='contained'
            >
              Prime Rate
            </Button>
          </Box>
          <PrimeRateDialog
            primeRate={primeRate}
            handleTextField={handleTextField}
            handleSelectField={handleSelectField}
            handleDate={handleDate}
            cancelDialog={cancelDialog}
            dialogSubmit={dialogSubmit}
          />
          <Box
            component='div'
            className={classes.filterMenu}
            style={{ width: '15%' }}
          >
            <Button
              classes={{ root: classes.smallButton }}
              onClick={openHistory}
              variant='contained'
            >
              Prime Rate History
            </Button>
          </Box>
          <RateHistoryDialog
            rows={rateRow}
            historyDialog={historyDialog}
            closeHistory={closeHistory}
          />
          <Box
            component='div'
            className={classes.filterMenu}
            style={{ width: '35%' }}
          >
            <Link className={classes.addButton} href='/mortgages/new'>
              <Add /> New Mortgages{' '}
            </Link>
          </Box>
        </Box>
        <Box component='div' className={classes.filterbar}>
          <FormControl margin='dense' style={{ minWidth: 150, marginLeft: 24 }}>
            <Select
              name='type'
              required
              value={mortgage.type}
              onChange={handleMortgageFilterSelect}
            >
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='1st'>{'1st'}</MenuItem>
              <MenuItem value='2nd'>{'2nd'}</MenuItem>
              <MenuItem value='3rd'>{'3rd'}</MenuItem>
              <MenuItem value='4th'>{'4th'}</MenuItem>
            </Select>
            <FormHelperText>Mortgage Type</FormHelperText>
          </FormControl>
          <FormControl margin='dense' style={{ minWidth: 150, marginLeft: 24 }}>
            <Select
              name='amount'
              required
              value={mortgage.amount}
              onChange={handleMortgageFilterSelect}
            >
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='amount1'>{`< ${formatter.format(
                1000000
              )}`}</MenuItem>
              <MenuItem value='amount2'>{`>= ${formatter.format(
                1000000
              )}`}</MenuItem>
              <MenuItem value='amount3'>{`< ${formatter.format(
                5000000
              )}`}</MenuItem>
            </Select>
            <FormHelperText>Mortgage Amount</FormHelperText>
          </FormControl>
          <FormControl margin='dense' style={{ minWidth: 150, marginLeft: 24 }}>
            <Select
              name='rate'
              required
              value={mortgage.rate}
              onChange={handleMortgageFilterSelect}
            >
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='rate1'>{'< 10%'}</MenuItem>
              <MenuItem value='rate2'>{'>= 10%, < 11%'}</MenuItem>
              <MenuItem value='rate3'>{'>= 11%'}</MenuItem>
            </Select>
            <FormHelperText>Mortgage Rate</FormHelperText>
          </FormControl>
          <TextField
            style={{ minWidth: 150, marginLeft: 24 }}
            id='coBrokerName'
            size='small'
            margin='dense'
            helperText='Search Co-Broker Name'
            onChange={handleFilterText}
          />
          <TextField
            style={{ minWidth: 150, marginLeft: 24 }}
            id='city'
            size='small'
            margin='dense'
            helperText='Search Collateral City'
            onChange={handleFilterText}
          />
          <FormControl margin='dense' style={{ minWidth: 150, marginLeft: 24 }}>
            <Select
              name='type'
              required
              value={colType}
              onChange={handleFilterSelect}
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='land'>Land</MenuItem>
              <MenuItem value='condo'>Condo</MenuItem>
              <MenuItem value='townhouse'>Townhouse</MenuItem>
              <MenuItem value='SFD'>SFD</MenuItem>
              <MenuItem value='commercial'>Commercial</MenuItem>
              <MenuItem value='others'>Others</MenuItem>
            </Select>
            <FormHelperText>Collateral Type</FormHelperText>
          </FormControl>
        </Box>
      </ListSubheader>
      {initialLoading ? (
        <TableLoading />
      ) : (
        <>
          <TableContainer
            component={Paper}
            classes={{ root: classes.boxShadow }}
          >
            <Table className={classes.table} aria-label='mortgage-table'>
              <TableHead classes={{ root: classes.background }}>
                <TableRow>
                  <TableCell align='center'>File No.</TableCell>
                  <TableCell align='center'>Name</TableCell>
                  <TableCell align='center'>Date</TableCell>
                  <TableCell align='center'>Amount ($)</TableCell>
                  <TableCell align='center'>LTV (%)</TableCell>
                  <TableCell align='center'>Rate (%)</TableCell>
                  <TableCell align='center'>Mortgage Type</TableCell>
                  <TableCell align='center'>Total Broker Fee ($)</TableCell>
                  <TableCell align='center'>Total Broker Fee (%)</TableCell>
                  <TableCell align='center'>Total Collaterals</TableCell>
                  <TableCell align='center'>Total Collaterals ($)</TableCell>
                  <TableCell align='center'>Status</TableCell>
                  <TableCell align='center'>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' align='center' scope='row'>
                        {row.fileNo}
                      </TableCell>
                      <TableCell align='center'>{row.mortgageName}</TableCell>
                      <TableCell align='center'>{row.startDate}</TableCell>
                      <TableCell align='center'>
                        {formatter.format(row.mortgageAmount)}
                      </TableCell>
                      <TableCell align='center'>{row.ltv} %</TableCell>
                      <TableCell align='center'>
                        {row.mortgageInterest} %
                      </TableCell>
                      <TableCell align='center'>{row.mortgageType}</TableCell>
                      <TableCell align='center'>
                        {formatter.format(row.brokerFee)}
                      </TableCell>
                      <TableCell align='center'>
                        {row.brokerFeePercentage} %
                      </TableCell>
                      <TableCell align='center'>
                        {row.totalCollateralNumber}
                      </TableCell>
                      <TableCell align='center'>
                        {formatter.format(row.totalCollateralValue)}
                      </TableCell>
                      <TableCell align='center'>
                        {row.status === 'Active' ? (
                          <span style={{ color: LIME, fontWeight: 700 }}>
                            Active
                          </span>
                        ) : (
                          <span style={{ color: RED, fontWeight: 700 }}>
                            Non-Active
                          </span>
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {redirectView(row.id)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}
export default MortgagePage
