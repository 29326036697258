import styled from 'styled-components'

export const FormOuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 20%;
`

export const FormInnerContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
`
