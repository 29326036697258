import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, WHITE, LIGHT_GRAY } from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      height: '60%'
    },
    prime: {
      height: '40%'
    },
    payout: {
      height: '30%'
    },
    dialogCancelButton: {
      height: theme.spacing(6),
      backgroundColor: LIGHT_GRAY,
      width: '25%',
      borderRadius: 14,
      color: NAVY,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    dialogSubmitButton: {
      height: theme.spacing(6),
      backgroundColor: NAVY,
      width: '25%',
      borderRadius: 14,
      color: WHITE,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: NAVY
      }
    }
  })
)
