import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, WHITE } from '../../styles/color'
import Background from '../../assets/background.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${Background})`,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loginBox: {
      width: '25%',
      height: '50%',
      background: 'white',
      padding: 40
    },
    errors: {
      textAlign: 'center',
      color: 'red',
      fontSize: '1.2rem'
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      '&:hover': {
        backgroundColor: NAVY
      }
    }
  })
)
