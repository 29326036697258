import MortgagePage from './mortgage'
import MortgageForm from './forms/mortgage.form'
import MortgageView from './views/mortgage.view'
import MortgagePayoutForm from './payout/payout.form'
import MortgagePayoutView from './payout/payout.view'

export default [
  {
    Component: MortgagePayoutView,
    title: 'Mortgage Form',
    path: '/payout/:mortgageId/view',
  },
  {
    Component: MortgagePayoutForm,
    title: 'Mortgage Form',
    path: '/mortgages/:mortgageId/payout',
  },
  {
    Component: MortgageForm,
    title: 'Mortgage Form',
    path: '/mortgages/new',
  },
  {
    Component: MortgageForm,
    title: 'Mortgage Renew Form',
    path: '/mortgages/:mortgageId/renew',
  },
  {
    Component: MortgageView,
    title: 'Mortgage View',
    path: '/mortgages/:mortgageId/view',
  },
  {
    Component: MortgagePage,
    title: 'Mortgages',
    path: '/mortgages',
    exact: true,
  },
]
