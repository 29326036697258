import React from 'react'
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Moment } from 'moment'
import { IAccountDateFields } from '../helper/investor.helper'
import { DatePicker } from '@material-ui/pickers'

const AccountDetail = ({
  handleTextField,
  account,
  aClass,
  handleClassSelect,
  distribution,
  handleDistributionSelect,
  dates,
  handleDate,
}: {
  handleTextField: (event: React.ChangeEvent<HTMLInputElement>) => void
  account: any
  aClass: string
  handleClassSelect: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void
  distribution: string
  handleDistributionSelect: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void
  dates: IAccountDateFields
  handleDate: (dateObj: Moment | null, name: string) => void
}) => {
  return (
    <>
      {/* Detail Field */}
      <Grid container spacing={2} direction='column'>
        <Grid item xs={12} container direction='row'>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Bank Draft Receive Date:</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                clearable
                format='YYYY-MM-DD'
                value={dates.depositDate}
                onChange={(date) => handleDate(date, 'depositDate')}
                inputVariant='outlined'
                margin='dense'
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>CCO Sign off Date:</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                clearable
                format='YYYY-MM-DD'
                value={dates.ccoDate}
                onChange={(date) => handleDate(date, 'ccoDate')}
                inputVariant='outlined'
                margin='dense'
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Investment Amount:</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                id='investmentAmount'
                defaultValue={account.investmentAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Class:</Typography>
            </Grid>
            <Grid item xs>
              <FormControl
                variant='outlined'
                margin='dense'
                style={{ minWidth: 195 }}
              >
                <Select
                  name='class'
                  id='class-select-outlined'
                  value={aClass}
                  onChange={handleClassSelect}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='A'>A</MenuItem>
                  <MenuItem value='B'>B</MenuItem>
                  <MenuItem value='C'>C</MenuItem>
                  <MenuItem value='D'>D</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container direction='row'>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Interest Distribution:</Typography>
            </Grid>
            <Grid item xs>
              <FormControl
                variant='outlined'
                margin='dense'
                style={{ minWidth: 195 }}
              >
                <Select
                  name='interestDistribution'
                  id='bank-select-outlined'
                  value={distribution}
                  onChange={handleDistributionSelect}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='Quarterly'>Quarterly</MenuItem>
                  <MenuItem value='Annually'>Annually</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Rate of Return %</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                id='interestRate'
                defaultValue={account.interestRate}
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Management Fee:</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                id='managementRate'
                defaultValue={account.managementRate}
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container direction='column'>
            <Grid item xs>
              <Typography variant='body2'>Certification No.</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                margin='dense'
                variant='outlined'
                onChange={handleTextField}
                id='certificateNo'
                defaultValue={account.certificateNo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default AccountDetail
