import React, { useState, useContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useStyles } from './payout.form.style'
import { ErrorPage } from '../../../components/error/error.component'
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Grid,
  Button,
} from '@material-ui/core'
import { PayoutTableRow, PayoutTextField } from '../components/table.component'
import { formatter } from '../../../utils/format'
import moment from 'moment'
import { IValues } from '../../../utils/types'
import { PayoutContext } from '../../../providers/payout/payout.provider'
import { AuthContext } from '../../../providers/auth/auth.provider'
import { PROD_OPS, OPS_KEY } from '../../../utils/constants'
import { FixedHeightLoading } from '../../../components/loading/loading.component'

const MortgagePayoutForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const { payout, resetPayout } = useContext(PayoutContext)
  const { token } = useContext(AuthContext)

  const [notes, $notes] = useState<IValues>({
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
  })

  const [amount, $amount] = useState<IValues>({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: payout.accruedInterest,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
  })

  const [total, $total] = useState<number>(0)
  const [values, $values] = useState<any>([])
  const [loading, $loading] = useState<boolean>(false)

  const handleTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const id = e.target.id as string
    const type = id.split('#')[0]
    const idx = Number(id.split('#')[1])
    const value = e.target.value

    switch (type) {
      case 'total':
        $total(Number(value))
        return
      case 'amount':
        let newAmount = amount
        newAmount = { ...newAmount, [idx]: value }
        $amount(newAmount)
        return
      case 'notes':
        let newNote = notes
        newNote = { ...newNote, [idx]: value }
        $notes(newNote)
        return
      default:
        return
    }
  }

  React.useEffect(() => {
    let newTotal = payout.amount
    for (const key in amount) {
      newTotal += Number(amount[key])
    }
    $total(newTotal)
  }, [amount])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    $loading(true)
    let newValues: any = []
    Object.keys(amount).map((key) => {
      newValues.push(notes[key], amount[key])
    })

    const body = {
      accountId: payout.accountId,
      date: payout.date,
      summary: newValues,
      total,
    }
    axios
      .put(`${PROD_OPS}/mortgage/payout`, body, {
        headers: { 'x-api-key': OPS_KEY, Authorization: token },
      })
      .then((res) => {
        if (res.status === 200) {
          resetPayout()
          $loading(false)
          history.push(`/mortgages/${payout.accountId}/view`)
        }
      })
  }

  return false ? (
    <ErrorPage />
  ) : (
    <Box component='div' className={classes.root}>
      <Box component='div' className={classes.toolbarBg}>
        Mortgage Payout Statement
      </Box>
      <Box component='div' className={classes.toolbar}>
        <Typography variant='body1'>File No: {payout.fileNo}</Typography>
      </Box>
      <form
        className={classes.tableContainer}
        onSubmit={handleSubmit}
        noValidate={true}
      >
        {false ? (
          <FixedHeightLoading />
        ) : (
          <>
            <TableContainer
              component={Paper}
              classes={{ root: classes.boxShadow }}
            >
              <Table
                className={classes.table}
                size='small'
                aria-label='payout-table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>
                      Principal Balance as of
                    </TableCell>
                    <TableCell align='center'>
                      {payout.date !== null
                        ? moment(payout.date, 'YYYY-MM-DD').format(
                            'MMMM Do, YYYY'
                          )
                        : ''}
                    </TableCell>
                    <TableCell align='center'>
                      {formatter.format(total)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <PayoutTableRow style={{ height: 60 }}>
                    <TableCell align='center'>Items</TableCell>
                    <TableCell align='center'>Notes</TableCell>
                    <TableCell align='center'>Amount</TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>
                      3 Month Interest Penalty (if applicable)
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#1'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#1'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>Uncashed Payments to</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#2'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#2'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>Per Diem</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#3'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#3'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>NSF Fees</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#4'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#4'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>
                      Interests Accrued Based on Prime Rate Increase
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#5'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        defaultValue={payout.accruedInterest}
                        type='number'
                        id='amount#5'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>Discharge Fee</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#6'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#6'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>Misc. Items</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#7'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#7'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>Lender Solicitor Costs</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#8'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <Typography variant='body2'>TBD</Typography>
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow>
                    <TableCell align='center'>Others</TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        id='notes#9'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='amount#9'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                  <PayoutTableRow style={{ height: 60 }}>
                    <TableCell align='center'>
                      Total Payout Balance as of
                    </TableCell>
                    <TableCell align='center'>
                      {payout.date !== null
                        ? moment(payout.date, 'YYYY-MM-DD').format(
                            'MMMM Do, YYYY'
                          )
                        : ''}
                    </TableCell>
                    <TableCell align='center'>
                      <PayoutTextField
                        onChange={handleTextField}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          ),
                        }}
                        type='number'
                        id='total'
                        size='small'
                        margin='dense'
                      />
                    </TableCell>
                  </PayoutTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              component='div'
              style={{ marginTop: 24 }}
              className={classes.buttonField}
            >
              <Grid
                container
                direction='row'
                justify='space-evenly'
                alignItems='flex-end'
                spacing={2}
              >
                <Grid item xs={9} />
                <Grid item xs={1} container justify='center'>
                  <Button
                    fullWidth
                    classes={{ root: classes.viewCancel }}
                    variant='outlined'
                    onClick={() => history.push('/mortgages')}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    type='submit'
                    classes={{ root: classes.submit }}
                    variant='outlined'
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </form>
    </Box>
  )
}

export default MortgagePayoutForm
