import { createMuiTheme } from '@material-ui/core/styles'

export const WHITE = '#FFFFFF'
export const LIGHT_GRAY = '#F0F0F0'
export const GRAY_200 = '#F9F9F9'
export const GRAY_300 = '#E4E4E4'
export const GRAY_400 = '#DEDEDE'
export const GRAY_500 = '#817F7F'
export const GRAY_600 = '#707070'
export const GRAY_700 = '#646363'
export const BORDER_GRAY = '#E1E1E1'
export const APPBAR_GRAY = '#E3E0E0'
export const BLACK = '#000000'
export const NAVY = '#154B88'
export const SKY_BLUE = '#AFD5FF'
export const YELLOW = '#FEAE25'
export const RED = '#FF0000'
export const LIME = '#00CC29'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: SKY_BLUE,
      main: NAVY,
      contrastText: WHITE
    },
    secondary: {
      main: WHITE,
      contrastText: BLACK
    },
    error: {
      main: RED
    },
    warning: {
      main: YELLOW
    },
    success: {
      main: LIME
    }
  }
})
