import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, WHITE } from '../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '93%',
      background: `linear-gradient(180deg, ${NAVY} 40%, ${WHITE} 40%)`
    },
    container: {
      padding: theme.spacing(0, 7, 0, 7),
      height: '100%'
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      ...theme.mixins.toolbar
    }
  })
)
