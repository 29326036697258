import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { NAVY, WHITE, LIGHT_GRAY } from '../../../styles/color'
import Toolbar from '../../../assets/toolbar.png'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 'calc(100% - 136px)',
      padding: theme.spacing(4),
      position: 'relative'
    },
    toolbarBg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      ...theme.mixins.toolbar
    },
    investorButton: {
      boxShadow: 'unset',
      backgroundColor: 'transparent',
      color: NAVY,

      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    bankIcon: {
      marginRight: 8,
      backgroundColor: NAVY,
      color: WHITE,
      borderRadius: '50%'
    },
    dropContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    dropZone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundolor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      width: theme.spacing(17),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      width: theme.spacing(17),
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    bankGrid: {
      padding: theme.spacing(0, 4, 4, 0)
    },
    buttonField: {
      position: 'absolute',
      height: theme.spacing(8),
      width: 'calc(50% - 64px)',
      marginLeft: 'calc(50% - 64px)',
      bottom: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    }
  })
)
