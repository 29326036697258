import * as React from 'react'
import {
  DEFAULT_INVESTOR_FILTER,
  DEFAULT_DATE,
  DEFAULT_MORTGAGE_FILTER
} from './helper/constants'
import {
  IFilterContext,
  IInvestorFilter,
  IDate,
  IMortgageFilter
} from './helper/types'
import { Moment } from 'moment'
import { useHistory } from 'react-router-dom'

export const FilterContext = React.createContext<IFilterContext>({
  investor: DEFAULT_INVESTOR_FILTER,
  mortgage: DEFAULT_MORTGAGE_FILTER,
  url: `?dateRange=All&type=All&status=All&investClass=All&distribution=All&amount=All&fee=All`,
  mortgageUrl: `?dateRange=All&type=All&status=All&amount=All&rate=All`,
  handleFilterSelect: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {},
  handleMortgageFilterSelect: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {},
  datesM: DEFAULT_DATE,
  dates: DEFAULT_DATE,
  handleFilterDates: ({
    startDate,
    endDate
  }: {
    startDate: Moment | null
    endDate: Moment | null
  }) => {},
  handleMortgageFilterDates: ({
    startDate,
    endDate
  }: {
    startDate: Moment | null
    endDate: Moment | null
  }) => {},
  activeInvestor: () => {},
  closedInvestor: () => {},
  activeMortgage: () => {},
  closedMortgage: () => {}
})

const { Provider } = FilterContext

const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const history = useHistory()
  const [investor, $investor] = React.useState<IInvestorFilter>(
    DEFAULT_INVESTOR_FILTER
  )

  const [mortgage, $mortgage] = React.useState<IMortgageFilter>(
    DEFAULT_MORTGAGE_FILTER
  )

  const [url, $url] = React.useState<string>(
    `?dateRange=${investor.dateRange}&type=${investor.type}&status=${investor.status}&investClass=${investor.investClass}&distribution=${investor.distribution}&amount=${investor.amount}&fee=${investor.fee}`
  )

  const [mortgageUrl, $mortgageUrl] = React.useState<string>(
    `?dateRange=${mortgage.dateRange}&type=${mortgage.type}&status=${mortgage.status}&amount=${mortgage.amount}&rate=${mortgage.rate}`
  )

  const [datesM, $datesM] = React.useState<IDate>(DEFAULT_DATE)
  const [dates, $dates] = React.useState<IDate>(DEFAULT_DATE)

  const handleFilterSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    const value = event.target.value as string
    let filter = investor
    filter = { ...filter, [name]: value }
    $investor(filter)
    $url(
      `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&investClass=${filter.investClass}&distribution=${filter.distribution}&amount=${filter.amount}&fee=${filter.fee}`
    )
  }

  const handleMortgageFilterSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    const value = event.target.value as string
    let filter = mortgage
    filter = { ...filter, [name]: value }
    $mortgage(filter)
    $mortgageUrl(
      `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&amount=${filter.amount}&rate=${filter.rate}`
    )
  }

  const handleFilterDates = ({
    startDate,
    endDate
  }: {
    startDate: Moment | null
    endDate: Moment | null
  }) => {
    if (startDate !== null && endDate !== null) {
      $datesM({ startDate, endDate })
      const date = `${startDate.format('YYYY-MM-DD')}*${endDate.format(
        'YYYY-MM-DD'
      )}`
      let filter = investor
      filter = { ...filter, dateRange: date }
      $investor(filter)
      $url(
        `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&investClass=${filter.investClass}&distribution=${filter.distribution}&amount=${filter.amount}&fee=${filter.fee}`
      )
    } else {
      $datesM({ startDate, endDate })
      let filter = investor
      filter = { ...filter, dateRange: 'All' }
      $investor(filter)
      $url(
        `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&investClass=${filter.investClass}&distribution=${filter.distribution}&amount=${filter.amount}&fee=${filter.fee}`
      )
    }
  }

  const handleMortgageFilterDates = ({
    startDate,
    endDate
  }: {
    startDate: Moment | null
    endDate: Moment | null
  }) => {
    if (startDate !== null && endDate !== null) {
      $dates({ startDate, endDate })
      const date = `${startDate.format('YYYY-MM-DD')}*${endDate.format(
        'YYYY-MM-DD'
      )}`
      let filter = mortgage
      filter = { ...filter, dateRange: date }
      $mortgage(filter)
      $mortgageUrl(
        `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&amount=${filter.amount}&rate=${filter.rate}`
      )
    } else {
      $dates({ startDate, endDate })
      let filter = mortgage
      filter = { ...filter, dateRange: 'All' }
      $mortgage(filter)
      $mortgageUrl(
        `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&amount=${filter.amount}&rate=${filter.rate}`
      )
    }
  }

  const activeInvestor = () => {
    let filter = investor
    filter = { ...filter, status: 'Active' }
    $investor(filter)
    $url(
      `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&investClass=${filter.investClass}&distribution=${filter.distribution}&amount=${filter.amount}&fee=${filter.fee}`
    )
    history.push('/investors')
  }

  const closedInvestor = () => {
    let filter = investor
    filter = { ...filter, status: 'Closed' }
    $investor(filter)
    $url(
      `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&investClass=${filter.investClass}&distribution=${filter.distribution}&amount=${filter.amount}&fee=${filter.fee}`
    )
    history.push('/investors')
  }

  const activeMortgage = () => {
    let filter = mortgage
    filter = { ...filter, status: 'Active' }
    $mortgage(filter)
    $mortgageUrl(
      `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&amount=${filter.amount}&rate=${filter.rate}`
    )
    history.push('/mortgages')
  }

  const closedMortgage = () => {
    let filter = mortgage
    filter = { ...filter, status: 'Closed' }
    $mortgage(filter)
    $mortgageUrl(
      `?dateRange=${filter.dateRange}&type=${filter.type}&status=${filter.status}&amount=${filter.amount}&rate=${filter.rate}`
    )
    history.push('/mortgages')
  }

  return (
    <Provider
      value={{
        investor,
        mortgage,
        url,
        mortgageUrl,
        handleFilterSelect,
        handleMortgageFilterSelect,
        datesM,
        dates,
        handleFilterDates,
        handleMortgageFilterDates,
        activeInvestor,
        activeMortgage,
        closedInvestor,
        closedMortgage
      }}
    >
      {children}
    </Provider>
  )
}

export default FilterProvider
