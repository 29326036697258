export interface IDashboard {
  investorTotal: number
  investorActive: number
  investorClosed: number
  mortgageTotal: number
  mortgageActive: number
  mortgageClosed: number
  totalInvestment: number
  totalMortgage: number
  difference: number
  bankBalance: number
  investorReturn: number
  mortgageRate: number
  managementFee: number
  paidInterest: number
  unpaidInterest: number
  receivedInterest: number
  unreceivedInterest: number
  months: string[]
  paidInterests: number[]
  receivedInterests: number[]
  differences: number[]
}

export const DEFAULT_DASHBOARD = {
  investorTotal: 0,
  investorActive: 0,
  investorClosed: 0,
  mortgageTotal: 0,
  mortgageActive: 0,
  mortgageClosed: 0,
  totalInvestment: 0,
  totalMortgage: 0,
  difference: 0,
  bankBalance: 0,
  investorReturn: 0,
  mortgageRate: 0,
  managementFee: 0,
  paidInterest: 0,
  unpaidInterest: 0,
  receivedInterest: 0,
  unreceivedInterest: 0,
  months: [],
  paidInterests: [],
  receivedInterests: [],
  differences: []
}
