import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '../../../assets/toolbar.png'
import {
  GRAY_500,
  GRAY_600,
  RED,
  NAVY,
  WHITE,
  LIGHT_GRAY,
  BORDER_GRAY,
  APPBAR_GRAY,
  BLACK
} from '../../../styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '93%',
      background: WHITE
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      backgroundImage: `url(${Toolbar})`,
      backgroundSize: 'contain',
      ...theme.mixins.toolbar
    },
    form: {
      width: '100%',
      minHeight: 'calc(100% - 72px)',
      position: 'relative',
      padding: 32
    },
    sectionRoot: {
      width: '100%',
      minHeight: 'calc(100% - 72px)',
      padding: theme.spacing(4)
    },
    sectionFields: {
      minHeight: '60%',
      backgroundColor: LIGHT_GRAY,
      padding: theme.spacing(3)
    },
    editIcon: {
      display: 'flex',
      alignItems: 'center',
      color: NAVY,
      cursor: 'pointer'
    },
    fieldValue: {
      color: NAVY,
      fontSize: 'medium',
      marginLeft: 8
    },
    spaceAround: {
      justifyContent: 'space-around'
    },
    indicator: {
      backgroundColor: NAVY
    },
    paper: {
      background: '#FFFFFF 0% 0% no-repeat',
      border: `1px solid ${BORDER_GRAY}`,
      marginBottom: 16,
      minHeight: theme.spacing(38),
      width: '100%',
      margin: 'auto'
    },
    appbar: {
      backgroundColor: APPBAR_GRAY,
      color: WHITE,
      boxShadow: 'unset'
    },
    selected: {
      color: BLACK
    },
    capitalize: {
      textTransform: 'capitalize'
    },
    boxShadow: {
      boxShadow: 'unset',
      borderRadius: 'unset'
    },
    background: {
      backgroundColor: GRAY_600
    },
    navy: {
      color: NAVY
    },
    gray: {
      color: GRAY_500
    },
    red: {
      color: RED
    },
    white: {
      color: WHITE
    },
    addButton: {
      boxShadow: 'unset',
      backgroundColor: 'transparent',
      color: NAVY,

      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    addIcon: {
      marginRight: 8,
      backgroundColor: NAVY,
      color: WHITE,
      borderRadius: '50%'
    },
    buttonField: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.mixins.toolbar
    },
    cancel: {
      backgroundColor: LIGHT_GRAY,
      color: NAVY,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: LIGHT_GRAY
      }
    },
    submit: {
      backgroundColor: NAVY,
      color: WHITE,
      boxShadow: 'unset',
      borderRadius: theme.spacing(1.75),
      height: theme.spacing(7),
      '&:hover': {
        backgroundColor: NAVY
      }
    },
    marginTop24: {
      marginTop: theme.spacing(3)
    },
    marginBottom24: {
      marginBottom: theme.spacing(3)
    },
    marginTop40: {
      marginTop: theme.spacing(5)
    },
    margin24: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  })
)
