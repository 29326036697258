import * as React from 'react'
import { Form, IFields, required, match } from './user.form'
import { Field } from './user.fields'
import { FormOuterContainer, FormInnerContainer } from './form.styles'
import { PROD_AUTH } from '../../../utils/constants'

interface IAddUserFormProps {
  cancelDialog: (type: string) => void
  closeDialog: (type: string) => void
}

export const AddUserForm: React.SFC<IAddUserFormProps> = ({
  cancelDialog,
  closeDialog
}: IAddUserFormProps) => {
  const fields: IFields = {
    name: {
      id: 'name',
      label: 'Name',
      type: 'text',
      validation: { rule: required }
    },
    role: {
      id: 'role',
      label: 'Permission',
      editor: 'dropdown',
      options: ['None', 'Admin-General', 'Admin-View'],
      validation: { rule: required }
    },
    department: {
      id: 'department',
      label: 'Department',
      type: 'text',
      validation: { rule: required }
    },
    email: {
      id: 'email',
      label: 'Email',
      type: 'email',
      validation: { rule: required }
    },
    password: {
      id: 'password',
      label: 'Password',
      type: 'password',
      validation: { rule: required }
    },
    repassword: {
      id: 'repassword',
      label: 'Confirm Password',
      type: 'password',
      validation: { rule: match }
    }
  }
  return (
    <Form
      type='create'
      action={`${PROD_AUTH}/user/info`}
      fields={fields}
      cancelDialog={cancelDialog}
      closeDialog={closeDialog}
      render={() => (
        <>
          <FormOuterContainer style={{ marginTop: 16 }}>
            <FormInnerContainer>
              <Field {...fields.name} />
            </FormInnerContainer>
            <FormInnerContainer>
              <Field {...fields.role} />
            </FormInnerContainer>
          </FormOuterContainer>
          <FormOuterContainer>
            <FormInnerContainer>
              <Field {...fields.department} />
            </FormInnerContainer>
            <FormInnerContainer>
              <Field {...fields.email} />
            </FormInnerContainer>
          </FormOuterContainer>
          <FormOuterContainer>
            <FormInnerContainer>
              <Field {...fields.password} />
            </FormInnerContainer>
            <FormInnerContainer>
              <Field {...fields.repassword} />
            </FormInnerContainer>
          </FormOuterContainer>
        </>
      )}
    />
  )
}
