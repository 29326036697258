import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './view.fields.style'
import { formatter } from '../../../utils/format'
import { IValues } from '../../../utils/types'
import { LIME, RED } from '../../../styles/color'

const MortgageViewFields = ({
  mortgageLoaded,
}: {
  mortgageLoaded: IValues
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12} container direction='row' spacing={2}>
        <Grid
          item
          xs={12}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Status:{' '}
            <span
              style={
                mortgageLoaded.status === 'Active'
                  ? { color: LIME, fontWeight: 600 }
                  : { color: RED, fontWeight: 600 }
              }
            >
              {mortgageLoaded.status}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            File No:{' '}
            <span className={classes.fieldValue}>{mortgageLoaded.fileNo}</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Start Date:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.startDate}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Mature Date:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.matureDate}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Payout Date:{' '}
            <span className={classes.fieldValue}>{mortgageLoaded.endDate}</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Mortgage Amount:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.mortgageAmount)}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            LTV:{' '}
            <span className={classes.fieldValue}>{mortgageLoaded.ltv} %</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Lender Fee:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.lenderFee)}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Admin Fee:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.adminFee)}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Mortgage Type:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.mortgageType}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Interest Adjustment:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.interestAdjustment)}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Title Certificate:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.title ? 'Y' : 'N'}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Legal Docs:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.legalDocs ? 'Y' : 'N'}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Lender Packages:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.lenderPackage ? 'Y' : 'N'}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Total Brokerage Fee:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.brokerFee)}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Total Brokerage %:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.brokerFeePercentage} %
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Co-Broker Name:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.coBrokerName}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Co-Broker Fee:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.coBrokerFee)}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Vanmortgage Capital Management Corp. fee:{' '}
            <span className={classes.fieldValue}>
              {formatter.format(mortgageLoaded.managementFee)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid
          item
          xs={6}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Notes:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.brokerNotes}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid
          item
          xs={3}
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Typography variant='body1'>
            Interest Type:{' '}
            <span className={classes.fieldValue}>
              {mortgageLoaded.interestType}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={9} />
      </Grid>
    </>
  )
}

export default MortgageViewFields
